import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import FloTraceLogo from "./FloTraceLogo";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useState, useContext } from "react";
import { LoginContext } from "./store/LoginContext";
import axios from "axios";
import background from "../background.png";
import { useDispatch } from "react-redux";
import { updateUserID } from "./store/UserReducer";
import EmailSentModal from "./EmailSentModal";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

function Copyright(props) {
  return (
    <Typography variant="body2" color="black" align="center" {...props}>
      {"Copyright © "}
      <Link color="inherit" href="https://www.lspssolutions.com/">
        LSPS
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
const getTokenFromURL = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token");
  return token;
};

const defaultTheme = createTheme();

export default function ChangePassword() {
  const dispatch = useDispatch();
  const [ChangePAsswordSuccessful, setChangePasswordSuccessful] =
    useState(false);
  const [ChangePAssword, setChangePassword] = useState({
    new_password: "",
    confirm_password: "",
  });
  const [isOpenModal, setOpenModal] = useState(false);
  const [error, setError] = useState(null);
  const baseURL = process.env.REACT_APP_API_BASE_URL;

  const handlechangepassword = (e) => {
    e.preventDefault();

    const token = getTokenFromURL();

    if (!ChangePAssword.new_password || !ChangePAssword.confirm_password) {
      setChangePasswordSuccessful(false);
      setError("Please fill in both password fields.");
      setOpenModal(true);
      return;
    }

    if (ChangePAssword.new_password !== ChangePAssword.confirm_password) {
      setChangePasswordSuccessful(false);
      setError("Passwords do not match.");
      setOpenModal(true);
      return;
    }

    axios({
      method: "POST",
      url: `${baseURL}/change-password`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        new_password: ChangePAssword.new_password,
        confirm_password: ChangePAssword.confirm_password,
      },
    })
      .then((response) => {
        setChangePasswordSuccessful(true);
        setError("");
        setOpenModal(true);

        setChangePassword({
          new_password: "",
          confirm_password: "",
        });
      })
      .catch((error) => {
        if (error.response) {
          setChangePasswordSuccessful(false);
          setOpenModal(true);
        } else {
          console.log("An error occurred:", error.message);
        }
      });
  };

  const handleChange = (event) => {
    const { value, name } = event.target;
    setChangePassword((prevNote) => ({
      ...prevNote,
      [name]: value,
    }));
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container
        component="main"
        maxWidth="sm"
        sx={{ paddingTop: 8, paddingBottom: 8 }}
        // sx={{
        //   backgroundImage:`url(${background})`,
        //       // backgroundRepeat: "no-repeat",
        //       // backgroundSize: "cover",
        //       // height: "385px",}}>
        // }}>
      >
        <CssBaseline />
        {/* <Box sx={{backgroundImage:`url(${background})`}}> */}
        <Box
          sx={{
            borderRadius: 1,
            bgcolor: "white",
            marginTop: 8,
            marginBottom: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <FloTraceLogo />
          <Typography component="h1" variant="h5">
            Change Password
          </Typography>
          <Box
            component="form"
            onSubmit={handlechangepassword}
            noValidate
            sx={{ mt: 1 }}
            style={{ width: "500px" }}
          >
            <TextField
              onChange={handleChange}
              margin="normal"
              required
              fullWidth
              id="password"
              label="New password"
              name="new_password"
              type="password"
              autoComplete="password"
              autoFocus
              text={ChangePAssword.new_password}
              value={ChangePAssword.new_password}
            />

            <TextField
              onChange={handleChange}
              margin="normal"
              required
              fullWidth
              id="new_password"
              label="Confirm new password"
              name="confirm_password"
              autoComplete="new_password"
              type="password"
              autoFocus
              text={ChangePAssword.confirm_password}
              value={ChangePAssword.confirm_password}
            />
            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Submit
            </Button>

            <EmailSentModal
              color={ChangePAsswordSuccessful ? "" : "error"}
              openStatus={isOpenModal}
              handleModalClose={() => setOpenModal(false)}
              alertContentText={
                ChangePAsswordSuccessful
                  ? "Password changed successfuly"
                  : error
              }
              hanndleChangePAsswordSuccessful={ChangePAsswordSuccessful}
            />
          </Box>
          <Copyright sx={{ mt: 4, mb: 4 }} />
        </Box>
      </Container>
    </ThemeProvider>
  );
}
