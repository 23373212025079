import { useEffect, useState } from 'react';

export default function useAuthState() {
  // const [authState, setAuthState] = useState({
  //   token: getUserItem("token"),
  //   email: getUserItem("email"),
  //   clientId: getUserItem("clientid"),
  //   clientName: getUserItem("clientname"),
  // })

  const [token, setToken] = useState(getUserItem("token"))
  const [email, setEmail] = useState(getUserItem("email"))
  const [clientId, setClientId] = useState(getUserItem("clientid"))
  const [clientName, setClientName] = useState(getUserItem("clientname"))
  const [clientNameHeader, setClientNameHeader] = useState(getUserItem("clientnameheader"))
  const [userRoleName, setUserRoleName] = useState(getUserItem("userRoleName"))

  function getUserItem(item) {
    const userItem = localStorage.getItem(item);
    return userItem && userItem
  }


  function removeItems() {
    localStorage.clear();
    setToken(null);
    setEmail(null);
    setClientName(null);
    setClientId(null);
  }

  function saveToken(userToken) {
    localStorage.setItem('token', userToken);
    setToken(userToken);
  };

  function saveEmail(userEmail) {
    localStorage.setItem('email', userEmail);
    setEmail(userEmail);
  };

  function saveClientId(userClientId) {
    localStorage.setItem('clientid', userClientId);
    setClientId(userClientId);
  };

  function saveClientName(userClientName) {
    localStorage.setItem('clientname', userClientName);
    setClientName(userClientName);
  };
  function saveClientNameHeader(userClientName) {
    localStorage.setItem('clientnameheader', userClientName);
    setClientNameHeader(userClientName);
  };
  function saveClientRoleName(userRoleName) {
    localStorage.setItem('userRoleName', userRoleName);
    setUserRoleName(userRoleName);
  };

  return {
    token,
    email,
    clientId,
    clientName,
    clientNameHeader,
    userRoleName,
    getUserItem,
    removeItems,
    saveClientId,
    saveClientName,
    saveEmail,
    saveToken,
    saveClientNameHeader,
    saveClientRoleName
  }

}  


// function saveItem(itemName, userItem) {
  //   localStorage.setItem(itemName, userItem);
  //   setAuthState({...authState, [itemName]:userItem});
  // };