import React, { useState, useEffect } from "react";
import { TextField, Button } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import axios from "axios";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import AddTestModal from "../AddTestModal";
import InputMask from "react-input-mask";
import { states } from "../States.js";
import LoaderImage from "../../assets/images/spinner.gif";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

const EditContact = ({
  clientID,
  data,
  handleClose,
  updateSatus,
  updateCurrentState,
}) => {
  const clientId = clientID;
  console.log({ data });
  const handleOpenSubmitModal = () => setOpenSubmitModal(true);
  const handleCloseSubmitModal = () => setOpenSubmitModal(false);

  const [device, setDevice] = useState("");
  const [password, setPassword] = useState("");
  const [open, setOpen] = React.useState(true);
  const [companiesData, setCompaniesData] = useState([]);
  const [deviceTypesData, setDeviceTypesData] = useState([]);
  const [sizesData, setSizesData] = useState([]);
  const [manufacturersData, setManufacturersData] = useState([]);
  const [companyQueryName, setCompanyQueryName] = useState("");
  const [openSubmitModal, setOpenSubmitModal] = useState(false);
  const [alertContent, setAlertContent] = useState("");
  const [alert, setAlert] = useState(false);

  const [contactFormData, setContactFormData] = React.useState({
    // companyName: data?.LastName,
    lastName: data.LastName,
    firstName: data.FirstName,
    emailAddress: data.EmailAddress,
    jobTitle: data.JobTitle,
    businessPhone: data.BusinessPhone,
    homePhone: data.HomePhone,
    mobilePhone: data.MobilePhone,
    faxNumber: data.FaxNumber,
    streetAddress: data.StreetAddress,
    city: data.City,
    state: data.State,
    postalCode: data.PostalCode,
    country: data.Country,
    notes: data.Notes,
  });

  const [companyNameIsValid, setCompanyNameIsValid] = useState(false);
  const [firstNameIsValid, setFirstNameIsValid] = useState(false);
  const [lastNameIsValid, setLastNameIsValid] = useState(false);
  const [jobTitleIsValid, setJobTitleIsValid] = useState(false);
  const [emailIsValid, setEmailIsValid] = useState(false);
  const [streetAddressIsValid, setStreetAddressIsValid] = useState(false);
  const [deviceTypeIsValid, setDeviceTypeIsValid] = useState(false);
  const [manufacturerIsValid, setManufacturerIsValid] = useState(false);
  const [modelNumberIsValid, setModelNumberIsValid] = useState(false);
  const [serialNumberIsValid, setSerialNumberIsValid] = useState(false);
  const [sizeIsValid, setSizeIsValid] = useState(false);
  const [conditionIsValid, setConditionIsValid] = useState(false);
  const [installedDateIsValid, setInstalledDateIsValid] = useState(false);
  const [locationNotesIsValid, setLocationNotesIsValid] = useState(false);
  const [cityIsValid, setCityIsValid] = useState(false);
  const [stateIsValid, setStateIsValid] = useState(false);
  const [postalCodeIsValid, setPostalCodeIsValid] = useState(false);
  const [businessPhoneIsValid, setBusinessPhoneIsValid] = useState(false);
  const [homePhoneIsValid, setHomePhoneIsValid] = useState(false);
  const [mobilePhoneIsValid, setMobilePhoneIsValid] = useState(false);
  const [faxIsValid, setFaxIsValid] = useState(false);
  const [zipIsValid, setZipIsValid] = useState(false);
  const [formIsValid, setFormIsValid] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [confimModal, setConfrimModal] = useState(false);

  //Sort function
  function dynamicSort(property) {
    return function (a, b) {
      return a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    };
  }

  //FETCH COMPANY CONTACT INFO
  // useEffect(() => {
  //   fetch(
  //     `${baseURL}/${clientId}/${contactFormData.companyName.replace(/ /g,"%20")}/getcompanycontactinfo`
  //   ).then((response) => response.json());
  // }, [contactFormData.companyName]);

  //FETCH COMPANY NAMES BY CLIENT ID
  useEffect(() => {
    fetch(
      `https://flo-trace-api-prod-ugyxg.ondigitalocean.app/${clientId}/getcompanynames`
    )
      .then((response) => response.json())
      .then((data) => {
        setCompaniesData(data.data.sort(dynamicSort("CompanyName")));
      });
  }, []);

  const baseURL = process.env.REACT_APP_API_BASE_URL;

  const updateContactFunct = (e) => {
    setShowLoader(true);
    const token = localStorage.getItem("token");
    const contactID = data?.ContactID;
    const form_Data = JSON.stringify(contactFormData);
    axios({
      method: "PUT",
      url: `${baseURL}/1/editcontact/${contactID}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: contactFormData,
    })
      .then((response) => {
        if (response.data.success) {
          setShowLoader(false);
          updateSatus(!updateCurrentState);
          handleClose();
          confirmAlert(UpdatedMessageOptions);
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      });

    e.preventDefault();
  };

  useEffect(() => {
    contactFormData.lastName && setLastNameIsValid(true);
    contactFormData.companyName && setCompanyNameIsValid(true);
    contactFormData.firstName && setFirstNameIsValid(true);
    contactFormData.emailAddress && setEmailIsValid(true);
    contactFormData.jobTitle && setJobTitleIsValid(true);
    contactFormData.businessPhone && setBusinessPhoneIsValid(true);
    contactFormData.homePhone && setHomePhoneIsValid(true);
    contactFormData.mobilePhone && setMobilePhoneIsValid(true);
    contactFormData.faxNumber && setFaxIsValid(true);
    contactFormData.streetAddress && setStreetAddressIsValid(true);
    contactFormData.city && setCityIsValid(true);
    contactFormData.state && setStateIsValid(true);
    contactFormData.postalCode && setZipIsValid(true);
  }, [data]);

  useEffect(() => {
   
      businessPhoneIsValid &&
      // homePhoneIsValid &&
      // mobilePhoneIsValid &&
      // && faxIsValid
      zipIsValid &&
      // companyNameIsValid &&
      firstNameIsValid &&
      lastNameIsValid &&
      jobTitleIsValid &&
      emailIsValid &&
      streetAddressIsValid &&
      stateIsValid
     ? setFormIsValid(true): setFormIsValid(false)

    console.log(
      {businessPhoneIsValid},
      {homePhoneIsValid},
      {mobilePhoneIsValid},
      {zipIsValid},
      // {companyNameIsValid},
      {firstNameIsValid},
      {lastNameIsValid},
      {jobTitleIsValid},
      {emailIsValid},
      {streetAddressIsValid},
      {stateIsValid}
    )
  }, [
    businessPhoneIsValid,
    // homePhoneIsValid,
    // mobilePhoneIsValid,
    // faxIsValid,
    zipIsValid,
    // companyNameIsValid,
    firstNameIsValid,
    lastNameIsValid,
    jobTitleIsValid,
    emailIsValid,
    streetAddressIsValid,
    stateIsValid,
  ]);

  const UpdatedMessageOptions = {
    title: "This contact record has been updated.",
    overlayClassName: "overlay-custom-class-name successMessage",
    buttons: [
      {
        label: "Close",
        className: "cancelBtn",
        onClick: () => {
          setConfrimModal(false);
          return false;
        },
      },
    ],
  };

  const options = {
    // title: "Title",
    message: `Are you sure you want to cancel? \n Any changes will not be saved.`,
    buttons: [
      {
        label: "NO",
        className: "confimBtn",
        onClick: () => {
          setConfrimModal(false);
          return false;
        },
      },
      {
        label: "YES",
        className: "cancelBtn",
        onClick: () => {
          setConfrimModal(false);
          handleClose();
        },
      },
    ],
    closeOnEscape: true,
    closeOnClickOutside: false,
    keyCodeForClose: [8, 32],
    willUnmount: () => {},
    afterClose: () => {},
    onClickOutside: () => {},
    onKeypress: () => {},
    onKeypressEscape: () => {},
    overlayClassName: "overlay-custom-class-name",
  };

  const handleConfirmationBox = () => {
    setConfrimModal(true);
    if (!confimModal) {
      confirmAlert(options);
    }
  };

  return (
    <>
      <Box>
        {showLoader && (
          <Grid item className="loaderMain">
            <img src={LoaderImage} alt="loaderImg" />
          </Grid>
        )}
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          sx={{ textAlign: "right" }}
          className="beforetable"
        ></Grid>
        <Grid item xs={12} style={{ paddingTop: 0 }}>
          {/* <Typography>* Denotes a required field</Typography> */}
          <Box
            align="left"
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1, width: "30ch" },
            }}
            autoComplete="off"
          >
            <h3>Contact Info</h3>
            <Grid>
              <TextField
                error={contactFormData.firstName == null || contactFormData.firstName == ""} 
                id="outlined-select-currency"
                //label="First Name"
                required
                helperText="Contact First Name"
                value={contactFormData.firstName}
                //onChange={e => setContactFormData({...contactFormData, firstName:e.target.value})}
                onChange={(e) => {
                  setContactFormData({
                    ...contactFormData,
                    firstName: e.target.value,
                  });

                  //let digitLength = e.target.value.toString().length
                  if (contactFormData.firstName == "" || contactFormData.firstName == null) {
                    setFirstNameIsValid(false);
                  } else if (e.target.value == "") {
                    setFirstNameIsValid(false);
                  } else {
                    setFirstNameIsValid(true);
                  }
                }}
              ></TextField>
              <TextField
                error={contactFormData.lastName == "" || contactFormData.lastName == null}
                id="outlined-select-currency"
                //label="Last Name"
                required
                helperText="Contact Last name"
                value={contactFormData.lastName}
                //onChange={e => setContactFormData({...contactFormData, lastName:e.target.value})}
                onChange={(e) => {
                  setContactFormData({
                    ...contactFormData,
                    lastName: e.target.value,
                  });

                  // let digitLength = e.target.value.toString().length;
                  if (e.target.value == "" ) {
                    setLastNameIsValid(false);
                    console.log(lastNameIsValid);
                  } else {
                    setLastNameIsValid(true);
                    console.log(lastNameIsValid);
                  }
                }}
              ></TextField>
              <TextField
                error={contactFormData.jobTitle == "" || contactFormData.jobTitle == null}
                id="outlined-select-currency"
                //label="Job Title"
                required
                // style={{ width: 550 }}
                helperText="Contact job title"
                value={contactFormData.jobTitle}
                //onChange={e => setContactFormData({...contactFormData, jobTitle:e.target.value})}
                onChange={(e) => {
                  setContactFormData({
                    ...contactFormData,
                    jobTitle: e.target.value,
                  });

                  //let digitLength = e.target.value.toString().length
                  if (e.target.value == "") {
                    setJobTitleIsValid(false);
                    console.log(jobTitleIsValid);
                  } else {
                    setJobTitleIsValid(true);
                    console.log(jobTitleIsValid);
                  }
                }}
              ></TextField>
              <TextField
                email
                error={
                  contactFormData.emailAddress == "" || contactFormData.emailAddress == null || 
                  !(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(contactFormData.emailAddress))
                }
                id="outlined-select-currency"
                //label="Email Address"
                required
                helperText={
                  !(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(contactFormData.emailAddress))
                    ? "Fill a valid email address"
                    : "Contact email address"
                }
                value={contactFormData.emailAddress}
                //onChange={e => setContactFormData({...contactFormData, emailAddress:e.target.value})}
                onChange={(e) => {
                  setContactFormData({
                    ...contactFormData,
                    emailAddress: e.target.value,
                  });
                  if (e.target.value == "" || !(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(e.target.value))) {
                    setEmailIsValid(false);
                  } else {
                    setEmailIsValid(true);
                  }
                }}
              ></TextField>
            </Grid>
            <Grid>
              <InputMask
                mask="(999) 999-9999"
                value={contactFormData.businessPhone}
                disabled={false}
                maskChar=" "
                //onChange= {e =>
                //{setContactFormData({...contactFormData, businessPhone:e.target.value})}}
                onChange={(e) => {
                  setContactFormData({
                    ...contactFormData,
                    businessPhone: e.target.value,
                  });

                  let digitLength = e.target.value
                    .toString()
                    .replace(/[^0-9]/g, "").length;
                  if (digitLength < 10) {
                    setBusinessPhoneIsValid(false);
                  } else {
                    setBusinessPhoneIsValid(true);
                  }
                }}
              >
                {() => (
                  <TextField
                    required
                    error={
                      contactFormData.businessPhone == "" || contactFormData.businessPhone == null ||
                      contactFormData.businessPhone &&
                      contactFormData.businessPhone.toString().replace(/[^0-9]/g, "").length < 10
                    }
                    helperText={
                      contactFormData.businessPhone &&
                      contactFormData.businessPhone
                        .toString()
                        .replace(/[^0-9]/g, "").length < 10
                        ? "Business phone (must be 10 digits)"
                        : "Business phone"
                    }
                    id="outlined-select-currency"
                    autoComplete="off"
                    inputProps={{
                      autoComplete: "off",
                    }}
                    //label="Business Phone"
                    value={contactFormData.businessPhone}
                  ></TextField>
                )}
              </InputMask>

              <InputMask
                mask="(999) 999-9999"
                value={contactFormData.homePhone}
                disabled={false}
                maskChar=" "
                onChange={(e) => {
                  setContactFormData({
                    ...contactFormData,
                    homePhone: e.target.value,
                  });
                  // console.log(
                  //   "Home phone",
                  //   contactFormData.homePhone.toString().replace(/[^0-9]/g, "")
                  //     .length
                  // );

                  let digitLength = e.target.value
                    .toString()
                    .replace(/[^0-9]/g, "").length;
                  if (digitLength < 10) {
                    setHomePhoneIsValid(false);
                  } else {
                    setHomePhoneIsValid(true);
                  }
                }}
              >
                {() => (
                  <TextField
                    //required
                    //error={contactFormData.homePhone.toString().replace(/[^0-9]/g,"").length <10}
                    helperText={
                      contactFormData.homePhone &&
                      contactFormData.homePhone
                        .toString()
                        .replace(/[^0-9]/g, "").length < 10
                        ? "Home phone (must be 10 digits)"
                        : "Home phone"
                    }
                    id="outlined-select-currency"
                    //label="Home Phone"
                    value={contactFormData.homePhone}
                  ></TextField>
                )}
              </InputMask>

              <InputMask
                mask="(999) 999-9999"
                //required
                value={contactFormData.mobilePhone}
                disabled={false}
                maskChar=" "
                onChange={(e) => {
                  setContactFormData({
                    ...contactFormData,
                    mobilePhone: e.target.value,
                  });

                  let digitLength = e.target.value
                    .toString()
                    .replace(/[^0-9]/g, "").length;
                  if (digitLength < 10) {
                    setMobilePhoneIsValid(false);
                  } else {
                    setMobilePhoneIsValid(true);
                  }
                }}
              >
                {() => (
                  <TextField
                    error={
                      contactFormData.mobilePhone &&
                      contactFormData.mobilePhone
                        .toString()
                        .replace(/[^0-9]/g, "").length < 10
                    }
                    helperText={
                      contactFormData.mobilePhone &&
                      contactFormData.mobilePhone
                        .toString()
                        .replace(/[^0-9]/g, "").length < 10
                        ? "Mobile phone (must be 10 digits)"
                        : "Mobile phone"
                    }
                    id="outlined-select-currency"
                    //label="Mobile Phone"
                    value={contactFormData.mobilePhone}
                  ></TextField>
                )}
              </InputMask>

              <InputMask
                mask="(999) 999-9999"
                value={contactFormData.faxNumber}
                disabled={false}
                maskChar=" "
                //onChange={setContactFormData({...contactFormData, faxNumber:e.target.value})}
                onChange={(e) => {
                  setContactFormData({
                    ...contactFormData,
                    faxNumber: e.target.value,
                  });

                  let digitLength = e.target.value
                    .toString()
                    .replace(/[^0-9]/g, "").length;
                  if (digitLength < 10) {
                    setFaxIsValid(false);
                    //console.log("setting fax valid to false")
                  } else {
                    setFaxIsValid(true);
                    //console.log('Setting fax valid to tru')
                  }
                }}
              >
                {() => (
                  <TextField
                    //error={faxIsValid}
                    //error={contactFormData.faxNumber.toString().replace(/[^0-9]/g,"").length <10}
                    helperText={
                      contactFormData.faxNumber &&
                      contactFormData.faxNumber
                        .toString()
                        .replace(/[^0-9]/g, "").length < 10
                        ? "Fax number (must be 10 digits)"
                        : "Fax number"
                    }
                    id="outlined-select-currency"
                    //label="Fax Number"
                    value={contactFormData.faxNumber}
                  ></TextField>
                )}
              </InputMask>

              {/* <TextField
            id="outlined-select-currency"
            //label="Fax Number"
            helperText="Type fax number"
            value={contactFormData.faxNumber}
            onChange={e => setContactFormData({...contactFormData, faxNumber:e.target.value})}
            >
          </TextField> */}
            </Grid>

            <h3>Contact Address</h3>
            <Grid>
              <TextField
                error={contactFormData.streetAddress == "" || contactFormData.streetAddress == null}
                id="outlined-select-currency"
                //label="Street Address"
                required
                helperText="Street address (eg. 102 S Main St)"
                value={contactFormData.streetAddress}
                //onChange={e => setContactFormData({...contactFormData, streetAddress:e.target.value})}
                onChange={(e) => {
                  setContactFormData({
                    ...contactFormData,
                    streetAddress: e.target.value,
                  });
                  if (e.target.value == "") {
                    setStreetAddressIsValid(false);
                  } else {
                    setStreetAddressIsValid(true);
                  }
                }}
              ></TextField>
              <TextField
                error={contactFormData.city == "" || contactFormData.city == null}
                id="outlined-select-currency"
                // //label="City"
                required
                helperText="City (eg. Houston)"
                value={contactFormData.city}
                //onChange={e => setContactFormData({...contactFormData, city:e.target.value})}
                onChange={(e) => {
                  setContactFormData({
                    ...contactFormData,
                    city: e.target.value,
                  });
                  if (e.target.value == "") {
                    setCityIsValid(false);
                  } else {
                    setCityIsValid(true);
                  }
                }}
              ></TextField>

              <TextField
                error={contactFormData.state == "" || contactFormData.state == null}
                id="outlined-select-currency"
                select
                required
                //style = {{width: 260}}
                //label="State"
                helperText="Select associated State"
                value={contactFormData.state}
                //onChange={e => setContactFormData({...contactFormData, state: e.target.value})}
                onChange={(e) => {
                  setContactFormData({
                    ...contactFormData,
                    state: e.target.value,
                  });
                  if (e.target.value == "") {
                    setStateIsValid(false);
                  } else {
                    setStateIsValid(true);
                  }
                }}
              >
                {states.map((option) => (
                  <MenuItem key={option.id} value={option.state}>
                    {option.state}
                  </MenuItem>
                ))}
              </TextField>

              <InputMask
                mask="99999-9999"
                value={contactFormData.postalCode}
                disabled={false}
                maskChar=" "
                //onChange={e => setContactFormData({...contactFormData, postalCode:e.target.value})}
                onChange={(e) => {
                  setContactFormData({
                    ...contactFormData,
                    postalCode: e.target.value,
                  });

                  let digitLength = e.target.value
                    .toString()
                    .replace(/[^0-9]/g, "").length;
                  if (digitLength < 5) {
                    setZipIsValid(false);
                  } else if (digitLength > 5 && digitLength < 9) {
                    setZipIsValid(false);
                  } else {
                    setZipIsValid(true);
                    //console.log('Setting fax valid to tru')
                  }
                }}
              >
                {() => (
                  <TextField
                    error={
                      (contactFormData.postalCode &&
                        contactFormData.postalCode
                          .toString()
                          .replace(/[^0-9]/g, "").length < 5) ||
                      (contactFormData.postalCode &&
                        contactFormData.postalCode
                          .toString()
                          .replace(/[^0-9]/g, "").length > 5 &&
                        contactFormData.postalCode
                          .toString()
                          .replace(/[^0-9]/g, "").length < 9)
                    }
                    helperText={
                      (contactFormData.postalCode &&
                        contactFormData.postalCode
                          .toString()
                          .replace(/[^0-9]/g, "").length < 5) ||
                      (contactFormData.postalCode &&
                        contactFormData.postalCode
                          .toString()
                          .replace(/[^0-9]/g, "").length > 5 &&
                        contactFormData.postalCode &&
                        contactFormData.postalCode
                          .toString()
                          .replace(/[^0-9]/g, "").length < 9)
                        ? "Zipcode (must be either 5 or 9 digits)"
                        : "Zipcode (must be either 5 or 9 digits)"
                    }
                    id="outlined-select-currency"
                    //style = {{width: 275}}
                    required
                    //label="Postal Code"
                    value={contactFormData.postalCode}
                  ></TextField>
                )}
              </InputMask>
            </Grid>

            <Box sx={{ mt: 6 }}>
              {/* <SubmitAddDeviceModal /> */}

              {/* <AddTestModal
                submitTestFileAndData={handleSubmit}
                openStatus={openSubmitModal}
                handleModalClose={handleCloseSubmitModal}
                alertContentText={alertContent}
              /> */}
              <Button
                disabled={formIsValid ? false : true}
                size="large"
                variant="contained"
                color="info"
                onClick={updateContactFunct}
                sx={{
                  ml: 1,
                  mr: 6,
                  mt: 2,
                  mb: 2,
                  borderRadius: 8,
                  width: 225,
                }}
              >
                Save Changes
              </Button>
              <Button
                size="large"
                variant="contained"
                sx={{
                  ml: 1,
                  mr: 6,
                  mt: 2,
                  mb: 2,
                  borderRadius: 8,
                  width: 225,
                }}
                color="inherit"
                onClick={handleConfirmationBox}
              >
                Cancel
              </Button>
              {/* <AddTestModal submitTestFileAndData={handleSubmit} openStatus={openSubmitModal} handleModalClose={handleCloseSubmitModal} alertContentText={alertContent}/>
            <Button type="submit" size="large" variant="contained" color="success" sx = {{ mr: 6, mt: 4, mb:4}}>
              Submit
            </Button> 
            <Button size="large" href="/" variant="contained" sx = {{ mt: 4, mb:4}}color="error">
              Cancel
            </Button> */}
            </Box>
          </Box>
        </Grid>
      </Box>
    </>
  );
};

export default EditContact;
