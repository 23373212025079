import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  FormControl,
  IconButton,
  OutlinedInput,
  InputAdornment,
  InputLabel,
  MenuItem,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import MuiAlert from "@mui/material/Alert";
import AddTestModal from "../AddTestModal";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputMask from "react-input-mask";
// =========
import Container from "@mui/material/Container";
import PhoneMenu from "../Common/PhoneMenu";
import SidebarMenu from "../Common/SidebarMenu";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import { useSelector } from "react-redux";
import {
  isTokenExpired,
  handleExpiredToken,
  handleTokenExpiryWarning,
} from "../Common/CheckJwtExpires";

const AddUser = ({ clientName, handleLogout, clientNameHeader }) => {
  const clientId = useSelector((state) => state.userID.value);

  const [open, setOpen] = useState(true);
  const [formData, setFormData] = useState({
    ClientID: clientId,
    UserFirstName: "",
    UserLastName: "",
    UserEMail: "",
    Password: "",
    MobilePhone: "",
    Role: "",
    isPhoneEnabled: false,
    isEmailEnabled: false,
  });
  const [isValidForm, setIsValidFrom] = useState(false);
  const [deviceID, setDeviceID] = useState([]);
  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState("");
  const [openSubmitModal, setOpenSubmitModal] = useState(false);
  const [isSuccessUpdated, setisSuccessUpdated] = useState(false);
  const [, setDeviceScheduledTestDate] = useState("");

  const [password, setPassword] = useState();
  const [confirmPass, setConfrimPass] = useState(false);

  const [companyNameDupeExists, setCompanyNameDupeExists] = useState(false);

  const handleOpenSubmitModal = () => setOpenSubmitModal(true);
  const handleCloseSubmitModal = () => setOpenSubmitModal(false);
  const [toggleMenus, setToggleMenus] = useState(false);

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const [showPassword, setShowPassword] = React.useState(false);
  const [showPasswordConfrm, setShowPasswordConfrm] = React.useState(false);

  const handleClickShowPassword = (type = "pass") => {
    type === "confirm"
      ? setShowPasswordConfrm((show) => !show)
      : setShowPassword((show) => !show);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleMouseUpPassword = (event) => {
    event.preventDefault();
  };
  const token = localStorage.getItem("token");

  useEffect(() => {
    const checkTokenExpiry = () => {
      if (isTokenExpired(token)) {
        handleExpiredToken();
      } else {
        handleTokenExpiryWarning(token);
      }
    };

    checkTokenExpiry();
    const interval = setInterval(checkTokenExpiry, 60000);

    return () => clearInterval(interval);
  }, [token]);

  useEffect(() => {
    console.log(formData);

    const isUserEmailValid =
      formData.UserEMail !== "" || !formData.isEmailEnabled;
    const isMobilePhoneValid =
      formData.MobilePhone.length === 10 || !formData.isPhoneEnabled;

    if (
      formData.UserFirstName !== "" &&
      formData.UserLastName !== "" &&
      isUserEmailValid &&
      formData.Password !== "" &&
      formData.Role !== "" &&
      formData.UserEMail != "" &&
      confirmPass &&
      isMobilePhoneValid
    ) {
      setIsValidFrom(true);
    } else {
      setIsValidFrom(false);
    }
  }, [formData, confirmPass]);

  useEffect(() => {
    setFormData({
      ...formData,
      ClientID: clientId,
    });
  }, [clientId]);

  // useEffect(() => {
  //   fetch(
  //     `https://flo-trace-api-prod-ugyxg.ondigitalocean.app/${clientId}/${formData.companyName.replace(
  //       / /g,
  //       "%20"
  //     )}/checkcompanyduplicates`
  //   )
  //     .then((response) => response.json())
  //     .then((data) => {
  //       if (data.response === "Company with this name already exists.") {
  //         setCompanyNameDupeExists(true);
  //         console.log("setting state", companyNameDupeExists);
  //       } else {
  //         setCompanyNameDupeExists(false);
  //         console.log("Not a dupe");
  //       }
  //     });
  // }, [formData.companyName]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const baseURL = process.env.REACT_APP_API_BASE_URL;
    const token = localStorage.getItem("token");

    fetch(`${baseURL}/users`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        // Check the status code
        if (response.ok) {
          return response.json(); // response.ok is true for status codes 200-299
        } else {
          // Handle non-200 status codes
          return response.json().then((errorData) => {
            throw new Error(
              `Error ${response.status}: ${
                errorData.message || "Something went wrong"
              }`
            );
          });
        }
      })
      .then(() => {
        // Handle success (status 200)
        setisSuccessUpdated(true);

        setAlertContent("User created successfully!");
        setAlert(true);
        handleOpenSubmitModal();
      })
      .catch((error) => {
        // Handle error case
        console.log("Error:", error.message, "hello");
        setisSuccessUpdated(false);
        setAlert(true);
        setAlertContent(`Failed to create user. ${error.message}`);
        handleOpenSubmitModal();
      });
  };

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <>
      <Box>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            {/* Header Section */}
            <Header
              clientId={clientId}
              clientName={clientName}
              handleLogout={handleLogout}
              istoggleMenu={toggleMenus}
              clientNameHeader={clientNameHeader}
            />
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Grid container spacing={0}>
          <Grid item xs={12} md={2}>
            {/* Footer Section */}
            <PhoneMenu slug="/technicians" />
            <SidebarMenu toggleMenu={setToggleMenus} />
          </Grid>
          <Grid
            item
            xs={12}
            md={10}
            className={
              toggleMenus === true ? "tooggleActive mainContent" : "mainContent"
            }
          >
            <Container maxWidth="xlg" sx={{ mt: 8, mb: 4 }}>
              <Grid container spacing={10} className="topDueSect">
                <Grid item xs={12} sm={12} md={6} className="beforetable">
                  <Typography
                    className="SelDevice"
                    align="left"
                    variant="h4"
                    gutterBottom
                  >
                    ADD NEW USER
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  sx={{ textAlign: "right" }}
                  className="beforetable"
                ></Grid>

                <Grid item xs={12} className="tabSect">
                  <Box
                    align="left"
                    sx={{
                      mt: 1,
                      "& .MuiTextField-root": { m: 1, width: "30ch" },
                    }}
                    autoComplete="off"
                  >
                    <h3>User Details</h3>
                    <Box component="form" onSubmit={handleSubmit}>
                      <Grid container xs={12} spacing={2}>
                        <Grid item xs={6} container spacing={2}>
                          <Grid item xs={6}>
                            <TextField
                              //error={formData.companyName === ''}
                              error={formData?.companyName === ""}
                              id="firstName"
                              style={{ width: "100%" }}
                              //label="Company Name"
                              required
                              helperText="First Name"
                              value={formData?.UserFirstName}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  UserFirstName: e.target.value,
                                })
                              }
                            ></TextField>
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              id="lastName"
                              style={{ width: "100%" }}
                              //label="Website"
                              helperText="Last Name"
                              value={formData?.UserLastName}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  UserLastName: e.target.value,
                                })
                              }
                              required
                            ></TextField>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={5}
                          style={{ paddingTop: 0, marginLeft: "1rem" }}
                        >
                          <p style={{ paddingBottom: 10 }}>
                            Notification Method
                          </p>
                          <FormControlLabel
                            style={{ width: 100 }}
                            control={
                              <Checkbox
                                name="isEmailEnabled"
                                checked={formData.isEmailEnabled}
                              />
                            }
                            onChange={(e) => {
                              setFormData((prevData) => ({
                                ...prevData,
                                isEmailEnabled: !formData.isEmailEnabled,
                              }));
                            }}
                            label="Email"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="isPhoneEnabled"
                                checked={formData.isPhoneEnabled}
                                disabled={formData.MobilePhone.length !== 10}
                              />
                            }
                            label="Mobile"
                            onChange={(e) => {
                              setFormData((prevData) => ({
                                ...prevData,
                                isPhoneEnabled: !formData.isPhoneEnabled,
                              }));
                            }}
                          />
                        </Grid>
                      </Grid>

                      <Grid container spacing={2}>
                        <Grid item xs={3}>
                          <TextField
                            id="email"
                            style={{ width: "100%" }}
                            //label="Owner"
                            helperText="Email"
                            value={formData?.UserEMail}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                UserEMail: e.target.value,
                              })
                            }
                            required
                          ></TextField>
                        </Grid>

                        <Grid item xs={3}>
                          <InputMask
                            mask="(999) 999-9999"
                            value={formData?.MobilePhone}
                            disabled={false}
                            maskChar=" "
                            onChange={(e) => {
                              const phoneValue = e.target.value;
                              const digitLength = phoneValue.replace(
                                /[^0-9]/g,
                                ""
                              ).length;
                              const numericValue = e.target.value.replace(
                                /[^0-9]/g,
                                ""
                              );

                              setFormData({
                                ...formData,
                                MobilePhone: numericValue,
                              });
                            }}
                          >
                            {() => (
                              <TextField
                                id="phone"
                                style={{ width: "100%" }}
                                error={
                                  !formData?.MobilePhone ||
                                  formData?.MobilePhone.replace(/[^0-9]/g, "")
                                    .length < 10
                                }
                                helperText={
                                  formData?.MobilePhone &&
                                  formData?.MobilePhone.replace(/[^0-9]/g, "")
                                    .length < 10
                                    ? "Mobile must be at least 10 digits"
                                    : "Mobile"
                                }
                                autoComplete="off"
                                inputProps={{
                                  autoComplete: "off",
                                }}
                                value={formData?.MobilePhone}
                              />
                            )}
                          </InputMask>
                        </Grid>
                      </Grid>

                      <Grid container spacing={2}>
                        <Grid item xs={3}>
                          <FormControl
                            sx={{ m: 1, width: "100%" }}
                            variant="outlined"
                          >
                            <InputLabel htmlFor="outlined-adornment-password">
                              Password
                            </InputLabel>
                            <OutlinedInput
                              id="outlined-adornment-passwordConfrim"
                              type={showPassword ? "text" : "password"}
                              onChange={(e) => {
                                setPassword(e.target.value);
                                formData.Password !== e.target.value
                                  ? setConfrimPass(false)
                                  : setConfrimPass(true);
                              }}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    onMouseUp={handleMouseUpPassword}
                                    edge="end"
                                  >
                                    {showPassword ? (
                                      <VisibilityOff />
                                    ) : (
                                      <Visibility />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              }
                              label="Password"
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                          <FormControl
                            sx={{ m: 1, width: "100%" }}
                            variant="outlined"
                          >
                            <InputLabel htmlFor="outlined-adornment-password">
                              Confrim Password
                            </InputLabel>
                            <OutlinedInput
                              id="outlined-adornment-password"
                              type={showPasswordConfrm ? "text" : "password"}
                              error={password !== formData?.Password}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  Password: e.target.value,
                                });
                                password !== e.target.value
                                  ? setConfrimPass(false)
                                  : setConfrimPass(true);
                              }}
                              required
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={(e) =>
                                      handleClickShowPassword("confirm")
                                    }
                                    onMouseDown={handleMouseDownPassword}
                                    onMouseUp={handleMouseUpPassword}
                                    edge="end"
                                  >
                                    {showPasswordConfrm ? (
                                      <VisibilityOff />
                                    ) : (
                                      <Visibility />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              }
                              label="Confrim Password"
                            />
                          </FormControl>
                        </Grid>
                      </Grid>

                      <Grid container spacing={2}>
                        <Grid item xs={3}>
                          <TextField
                            select
                            //error={formData.companyName === ''}
                            error={formData.companyName === ""}
                            id="role"
                            style={{ width: "100%" }}
                            //label="Company Name"
                            required
                            helperText="Role"
                            value={formData.Role}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                Role: e.target.value,
                              })
                            }
                          >
                            <MenuItem key={"add_1"} value="superadmin">
                              Super Admin (max 2)
                            </MenuItem>
                            <MenuItem key={"add_1"} value="admin">
                              Admin
                            </MenuItem>
                            <MenuItem key={"add_1"} value="user">
                              User
                            </MenuItem>
                            {/* <MenuItem key={2} value="masteradmin">Master Admin</MenuItem> */}
                          </TextField>
                        </Grid>
                      </Grid>
                      <Grid>
                        <Box sx={{ display: "inline-flex" }}>
                          <AddTestModal
                            color={isSuccessUpdated ? "success" : "error"}
                            submitTestFileAndData={handleSubmit}
                            openStatus={openSubmitModal}
                            handleModalClose={handleCloseSubmitModal}
                            alertContentText={alertContent}
                          />
                          <Button
                            type="submit"
                            variant="contained"
                            disabled={isValidForm ? false : true}
                            size="large"
                            color="info"
                            sx={{
                              ml: 1,
                              mr: 6,
                              mt: 4,
                              borderRadius: 8,
                              width: 225,
                            }}
                          >
                            Submit
                          </Button>
                          <Button
                            size="large"
                            href="/users"
                            variant="contained"
                            sx={{
                              mr: 6,
                              mt: 4,
                              borderRadius: 8,
                              borderRadius: 8,
                              width: 225,
                            }}
                            color="inherit"
                          >
                            Cancel
                          </Button>
                        </Box>
                      </Grid>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </>
  );
};

export default AddUser;
