import React, { useState, useEffect, useCallback } from "react";
import Title from "./Title";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import moment from "moment";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CustomToolbar from "./CustomToolbar";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import UploadDeviceFile from "./UploadDeviceFile";
import { Button, Tooltip } from "@mui/material";
import NoFileSelectedDetailPanelContentModal from "./NoFileSelectedDetailPanelContentModal";
import NoDeviceSelectedModal from "./NoDeviceSelectedModal";
import AddFileModal from "./AddFileModal";
import RetireDeviceModal from "./RetireModals/RetireDeviceModal";
import AddTestModal from "./AddTestModal";
import axios from "axios";
import Box from "@mui/material/Box";
import DeleteAttachmentModal from "./RetireModals/DeleteAttachmentModal";
import Alert from "@mui/material/Alert";
import ExpoertIcon from "./Common/assets/imgs/export.svg";
import WordIcon from "./Common/assets/imgs/microsoft-word-icon.svg";
import PDFIcon from "./Common/assets/imgs/PDF.svg";
import ImgIcon from "./Common/assets/imgs/image.svg";
import EXLIcon from "./Common/assets/imgs/xlsl.png";
import DownloadIcon from "./Common/assets/imgs/download.svg";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import ModalPopUp from "./Common/Modal/ModalPopUp";
import EditDeviceTable from "./EditFroms/EditDeviceTable";

export default function DeviceTable({
  onSaveNumDevices,
  onSelectRows,
  currentFilterModel,
  setFilterModelFunction,
  clientId,
  clientName,
}) {
  moment.updateLocale("language_code", {
    invalidDate: "",
  });

  const userRole = localStorage.getItem("userRoleName");
  const [deviceData, setDeviceData] = useState([]);
  const [timelineData, setTimelineData] = useState([]);
  const [uploadedDeviceFileName, setUploadedDeviceFileName] = useState("");
  const [testFiles, setTestFiles] = useState([]);
  const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = useState(
    []
  );
  const [filenameForDownload, setFilenameForDownload] = useState("");
  const [
    eventListenerFilenameForDownload,
    setEventListenerFilenameForDownload,
  ] = useState("");
  const [
    eventListenerClientFilenameForDownload,
    setEventListenerClientFilenameForDownload,
  ] = useState("");
  const [alertContent, setAlertContent] = useState("");
  const [openSubmitModal, setOpenSubmitModal] = useState(false);
  const [dummyState, setDummyState] = useState();
  const [detailRowSelectionModel, setDetailRowSelectionModel] = useState([]);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [previousRowSelectionModel, setPreviousRowSelectionModel] = useState(
    []
  );

  const [openNoFileSelectedModal, setNoFileOpenSelectedModal] = useState(false);
  const handleNoFileSelectedModalOpen = () => setNoFileOpenSelectedModal(true);
  const handleNoFileSelectedModalClose = () =>
    setNoFileOpenSelectedModal(false);

  const handleOpenSubmitModal = () => setOpenSubmitModal(true);
  const handleCloseSubmitModal = () => setOpenSubmitModal(false);
  const [isUpdate, setIsUpdate] = useState(false);

  const [selectedIDs, setSelectedIDs] = useState(1);
  const [detailSelectedIDs, setDetailSelectedIDs] = useState(1);
  const [detailPanelSelectedIDs, setDetailPanelSelectedIDs] = useState(1);
  const [detailPanelIDs, setDetailPanelIDs] = useState();

  // useEffect(() => {
  //   console.log("ROW SELECTION MODEL", rowSelectionModel[0]);
  // }, [rowSelectionModel])

  // useEffect(() => {
  //   console.log("PREVIOUS ROW SELECTION MODEL", previousRowSelectionModel[0]);
  // }, [previousRowSelectionModel])
  const [editModal, setEditModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const handleEditOpenModal = (data) => {
    setEditModal(true);
    setSelectedRow(data);
  };
  const handleEditCloseModal = () => setEditModal(false);

  const editModalData = () => {
    return (
      <EditDeviceTable
        clientID={clientId}
        data={selectedRow}
        handleClose={handleEditCloseModal}
        updateSatus={setIsUpdate}
        updateCurrentState={isUpdate}
      />
    );
  };

  const [retireDeviceModalStatus, setRetireDeviceModalStatus] = useState(false);
  const [alert, setAlert] = useState(false);
  const handleOpenModal = () => setRetireDeviceModalStatus(true);
  const handleCloseModal = () => setRetireDeviceModalStatus(false);

  const [deleteAttachmentModal, setDeleteAttachmentModal] = useState(false);
  const handleDeleteAttachmentModalOpen = () => setDeleteAttachmentModal(true);
  const handleDeleteAttachmentModalClose = () =>
    setDeleteAttachmentModal(false);

  const [deleteDeviceModal, setDeleteDeviceModal] = useState(false);
  const handleDeleteDeviceModalOpen = () => setDeleteDeviceModal(true);
  const handleDeleteDeviceModalClose = () => setDeleteDeviceModal(false);

  const [message, setMessage] = React.useState("");

  const handleRowClick = (params) => {
    setMessage(`Selected file: "${params.row.ClientFileName}"`);
    setEventListenerFilenameForDownload(params.row.Filename);
    setEventListenerClientFilenameForDownload(params.row.ClientFileName);
  };
  const baseURL = process.env.REACT_APP_API_BASE_URL;

  const handleRetireDevice = (e) => {
    e.preventDefault();
    if (rowSelectionModel[0] == undefined) {
      fetch(
        `${baseURL}/${clientId}/${previousRowSelectionModel[0]}/retiredevice`
      )
        .then((response) => response.json())
        .then((response) => {
          setAlertContent("Device retired successfully.");
          setAlert(true);
          setOpenSubmitModal(true);
        })
        .then((result) => {
          console.log(result);
        });
    } else {
      fetch(`${baseURL}/${clientId}/${rowSelectionModel[0]}/retiredevice`)
        .then((response) => response.json())
        .then((response) => {
          setAlertContent("Device retired successfully.");
          setAlert(true);
          setOpenSubmitModal(true);
        })
        .then((result) => {
          console.log(result);
        });
    }
  };

  const handleDeleteAttachment = () => {
    const setIds = new Set(detailRowSelectionModel);
    const filteredData = timelineData.filter((row) =>
      setIds.has(row.AttachmentID)
    );

    if (filteredData.length !== 0) {
      const filename = filteredData[0].Filename;
      const attachmentID = filteredData[0].AttachmentID;

      fetch(
        `${baseURL}/${clientId}/${clientName}/${filename.replace(
          / /g,
          "%20"
        )}/${attachmentID}/deleteattachment`
      )
        .then((response) => {
          setAlertContent("File deleted successfully.");
          setAlert(true);
          setOpenSubmitModal(true);
        })
        .then((result) => {
          console.log(result);
        });
    } else {
      handleNoFileSelectedModalOpen();
    }
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const uploadFormData = new FormData();
    uploadFormData.append("file", file);

    const filename = file.name;
    setUploadedDeviceFileName(filename);
    setTestFiles(uploadFormData);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const action = "File uploaded";
    axios
      .post(
        `${baseURL}/${clientId}/${clientName}/${detailPanelExpandedRowIds}/${uploadedDeviceFileName.replace(
          / /g,
          "%20"
        )}/${action.replace(/ /g, "%20")}/uploadfile`,
        testFiles,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        if (response.data.success === "success") {
          setAlertContent("File uploaded successfully!");
          handleOpenSubmitModal();
        } else {
          setAlertContent("Error uploading file");
          handleOpenSubmitModal();
        }
      })
      .catch((error) => {
        //alert(error)
      });

    // fetch(`http://127.0.0.1:5000/${lgnContext.clientId}/posttest`, {
    //   method: "POST",
    //   headers: {
    //       'Content-type': 'application/json'
    //   },
    //   body: JSON.stringify(formData)
    //   })
    //   .then((response) => response.json())
    //   .then((result) => {
    //   console.log(result)
    // })
  };

  const rowSelectionModelChangeHandler = (newDetailRowSelectionModel) => {
    setDetailRowSelectionModel(newDetailRowSelectionModel);
  };

  function download(blob, filename) {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");

    a.style.display = "none";
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }

  const handleDownload = () => {
    fetch(
      `${baseURL}/${clientId}/${clientName}/${eventListenerFilenameForDownload.replace(
        / /g,
        "%20"
      )}/downloadfile`
    )
      .then((response) => response.blob())
      .then((blob) => download(blob, `${eventListenerFilenameForDownload}`))
      .then(
        console.log(
          "Filename actually executing in download function",
          eventListenerFilenameForDownload
        )
      );
  };

  const downloadSingleFile = (filename) => {
    console.log(
      `${baseURL}/${clientId}/${clientName}/${filename.replace(
        / /g,
        "%20"
      )}/downloadfile`
    );
    fetch(
      `${baseURL}/${clientId}/${clientName}/${filename.replace(
        / /g,
        "%20"
      )}/downloadfile`
    )
      .then((response) => response.blob())
      .then((blob) => download(blob, `${eventListenerFilenameForDownload}`))
      .then(
        console.log(
          "Filename actually executing in download function",
          eventListenerFilenameForDownload
        )
      );
  };

  // const handleDownload = () => {
  //     console.log('Detail row selection model', detailRowSelectionModel)
  //     console.log('onRowClick', dummyState)

  //     let filteredData = timelineData.filter((row) => row.AttachmentID === detailRowSelectionModel);

  //     if (filteredData.length !==0) {
  //       console.log("filtered data found to be greater than 0");
  //       const filename = filteredData[0].Filename;
  //       fetch(`https://flo-trace-api-bmjve.ondigitalocean.app/${clientId}/${clientName}/${filename.replace(/ /g, '%20')}/downloadfile`)
  //       .then(response => response.blob())
  //       .then(blob => download(blob, `${filename}`))
  //       .then(console.log('Filename actually executing in download function', filename))
  //        }
  //     else {
  //        handleNoFileSelectedModalOpen()

  //     }
  // }

  // console.log('Filename actually executing in download function', filename)
  //const dummyStateFilename = timelineData[dummyState].Filename
  // const detailPanelSelectedRows = timelineData.filter((row) =>
  //   detailPanelSelectedIDs.has(row.AttachmentID)
  // );

  //const dummyStateFilename = timelineData.filter((row) =>
  //  row.AttachmentID = dummyState)

  //console.log("filtered", dummyStateFilename)
  //console.log("Filename for download", dummyStateFilename)

  //fetch(`https://flo-trace-api-bmjve.ondigitalocean.app/${clientId}/${clientName}/${filenameForDownload.replace(/ /g, '%20')}/downloadfile`)

  const attachmentTableColumns = [
    {
      field: "AttachmentID",
      headerName: "Attachment ID",
      editable: true,
      width: 350,
    },
    {
      field: "ClientFileName",
      cellClassName: "fileNameVal",
      headerName: "Filename",
      editable: true,
      width: 370,
    },
    {
      field: "CreateDateStamp",
      headerName: "Creation Date",
      editable: true,
      headerAlign: "center",
      width: 140,
      renderCell: (params) => {
        return moment(params.formattedValue).format("MM/DD/YY, h:mm A");
        // return formatDate(params.formattedValue,  "MM/DD/YYYY");
      },
    },
    { field: "Action", headerName: "Source", editable: true, width: 130 },
    {
      field: "Filename",
      headerName: "Format",
      headerAlign: "center",
      editable: true,
      align: "center",
      with: 60,
      renderCell: (params) => {
        var $fileExt = params["value"].split(".").pop();
        // var $fileExt = "doc";
        switch ($fileExt) {
          case "pdf":
            return <img src={PDFIcon} height="20" width="20" alt={PDFIcon} />;

          case "doc":
            return <img src={WordIcon} height="20" width="20" alt={WordIcon} />;

          case "docx":
            return <img src={WordIcon} height="20" width="20" alt={WordIcon} />;

          case "png":
            return <img src={ImgIcon} height="20" width="20" alt={ImgIcon} />;

          case "jpg":
            return <img src={ImgIcon} height="20" width="20" alt={ImgIcon} />;

          case "csv":
            return <img src={EXLIcon} height="20" width="20" alt={EXLIcon} />;

          case "xlsx":
            return <img src={EXLIcon} height="20" width="20" alt={EXLIcon} />;

          default:
            break;
        }
        // return ExtIcon;
      },
    },
    {
      field: "Download",
      width: 80,
      headerName: "Download",
      align: "center",
      renderCell: (params) => {
        return (
          <img
            className="downloadIconbtn"
            src={DownloadIcon}
            width={20}
            onClick={() => downloadSingleFile(params.row.Filename)}
          />
        );
      },
    },
    {
      field: "Delete",
      headerAlign: "center",
      width: 80,
      headerName: "Delete",
      align: "center",
      renderCell: (params) => (
        <RemoveCircleIcon
          onClick={handleDeleteAttachmentModalOpen}
          color="error"
        />
      ),
    },
  ];

  const handleDetailPanelExpandedRowIdsChange = (newIds) => {
    const setIds = new Set(rowSelectionModel);

    const filteredData = timelineData.filter((row) =>
      setIds.has(row.AttachmentID)
    );

    if (filteredData.length !== 0) {
      console.log("FILTERED DATA DOT LENGTH GREATER 0", filteredData);

      //if (newIds.length > 0) {
      setDetailPanelExpandedRowIds(newIds);
    } else {
      console.log("FILTERED DATA DOT LENGTH EQUALS 0", filteredData);
      setDetailPanelExpandedRowIds(newIds);
    }
  };

  useEffect(() => {
    async function fetchDetailPanelExpandedRowIDs() {
      const response = await fetch(
        `${baseURL}/${clientId}/${detailPanelExpandedRowIds}/getuploadedfilenames`
      );
      const result = await response.json();
      setTimelineData(result.data);
      setDetailRowSelectionModel(
        result.data.length > 0 ? result.data[0].AttachmentID : 1
      );
      setFilenameForDownload(
        result.data.length > 0 ? result.data[0].Filename : ""
      );
      setDummyState(result.data.length > 0 ? result.data[0].AttachmentID : 1);
    }
    fetchDetailPanelExpandedRowIDs();
  }, [detailPanelExpandedRowIds]);

  function DetailPanelContent({ row: rowProp }) {
    // fetch(`https://flo-trace-api-bmjve.ondigitalocean.app/${clientId}/${rowProp.DeviceID}/getuploadedfilenames`)
    //     .then((response) => response.json())
    //     .then((result) => {
    //       setTimelineData(result.data);
    //       setDetailRowSelectionModel(result.data[0].AttachmentID)
    //       setFilenameForDownload(result.data[0].Filename)
    //       setDummyState(result.data[0].AttachmentID)})

    //.then(setDetailRowSelectionModel)
    //.then(console.log('detailRowSel', filenameForDownload))
    //   if () {
    // setFilenameForDownload(timelineData[0].Filename))
    //   }
    //.then((nextResult) => console.log('nextresult', nextResult))
    //.then(setFilenameForDownload(timelineData[0].Filename))
    //{setFilenameForDownload(result[0].Filename)}
    //.then((result) => console.log('result',result))

    return (
      <React.Fragment>
        {/* <code>
          detailPanelExpandedRowIds: {JSON.stringify(detailPanelExpandedRowIds)}
        </code> */}
        <Grid
          container
          spacing={0}
          textAlign={"left"}
          xs={{ pl: 2.5 }}
          sx={{ pl: 10 }}
          mt={3}
          className="topTable"
        >
          {/* Due Within 90 Days */}
          <Grid item xs={12} md={4} lg={4}>
            <Typography variant="body1">
              <span className="lbl">{`Serial Number:`}</span>
              <span className="desc">{rowProp.SerialNumber}</span>
            </Typography>
            <Typography variant="body1">
              <span className="lbl">{`Company:`}</span>
              <span className="desc">{rowProp.CompanyName}</span>
            </Typography>
            <Typography variant="body1">
              <span className="lbl">{`Location:`}</span>
              <span className="desc">{rowProp.Location}</span>
            </Typography>
            <Typography variant="body1">
              <span className="lbl">{`Manufacturer:`}</span>
              <span className="desc">{rowProp.Manufacturer}</span>
            </Typography>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Typography variant="body1">
              <span className="lbl">{`Model:`}</span>
              <span className="desc">{rowProp.Model}</span>
            </Typography>
            <Typography variant="body1">
              <span className="lbl">{`Size:`}</span>
              <span className="desc">{rowProp.Size}</span>
            </Typography>
            <Typography variant="body1">
              <span className="lbl">{`Scheduled Test Date:`}</span>
              <span className="desc">
                {moment(rowProp.NewSchTestDate).format("MM/DD/YY, h:mm A")}
              </span>
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={8} sx={{ pl: 10 }}>
          {/* sx={{ml:10, mt:2}} */}
          <Grid item xs={8}>
            {message && <Alert severity="info">{message}</Alert>}
            <DataGridPremium
              className="subTbl"
              style={{ height: "350px" }}
              //checkboxSelection
              // disableRowSelectionOnClick
              pagination
              pageSizeOptions={[5, 10, 25, 100]}
              components={{ Toolbar: CustomToolbar }}
              density="compact"
              getRowId={(row) => row.AttachmentID}
              rows={timelineData}
              //onRowClick={(rows)=>{setDummyState(rows.id)}}
              // onRowClick={handleRowClick}
              columns={attachmentTableColumns}
              initialState={{
                ...timelineData.initialState,
                pagination: { paginationModel: { pageSize: 5 } },
                columns: {
                  columnVisibilityModel: {
                    AttachmentID: false,
                  },
                },
              }}
              //rowSelectionModel={detailPanelInitialSelectionModel}
              // onRowSelectionModelChange={(newDetailRowSelectionModel) => {
              //   setDetailRowSelectionModel(newDetailRowSelectionModel);
              // }}

              onRowSelectionModelChange={rowSelectionModelChangeHandler}
              rowSelectionModel={detailRowSelectionModel}

              // onRowSelectionModelChange={(ids) => {
              //   setDetailSelectedIDs(ids[0]);
              //   console.log("IDS", ids)
              //   console.log("Detail selected ids state", detailSelectedIDs)
              //const selectedIDs = new Set(ids);
              //const selectedRows = deviceData.filter((row) =>
              //  selectedIDs.has(row.DeviceID)
              //);
              //onSelectRows(selectedRows);
              //}}

              //onRowSelectionModelChange={(ids) => {
              //  console.log('onRowSelectionModelChange', ids)
              //const filteredRow = timelineData.filter((row) =>
              //  row.AttachmentID = ids[0])
              //console.log('filtered row', filteredRow)

              //const detailPanelSelectedIDs = new Set(ids);
              //setDetailPanelExpandedRowIds(ids);
              // const filteredFilename = timelineData.filter((row) =>
              //    detailPanelSelectedIDs.has(row.AttachmentID)
              //  );

              //setDetailPanelIDs(detailPanelSelectedIDs)
              //setFilenameForDownload(ids[0].Filename)
              // console.log('Filename for download state', filenameForDownload)

              //}}
            />

            {/* <Button
              variant="contained"
              size="large"
              onClick={handleDownload}
              disabled={eventListenerFilenameForDownload === "" ? true : false}
              sx={{ mt: 1, mb: 0 }}
            >
              {eventListenerFilenameForDownload === ""
                ? "SELECT A ROW TO DOWNLOAD"
                : `Download ${eventListenerClientFilenameForDownload}`}
            </Button> */}
          </Grid>
          <Grid item sx={{ mt: 4, textAlign: "left" }} xs={3}>
            <Box
              component="form"
              onSubmit={handleSubmit}
              className="uploadFrom"
            >
              {/* <Box> */}

              {/* <Typography
                sx={{ ml: 5, mt: -2, textAlign: "left" }}
                variant="h6"
              > */}
              {/* Upload Attachment
              </Typography> */}
              <Typography
                className="fileFormat"
                sx={{ ml: 0.5, mt: 1, textAlign: "center" }}
                variant="body1"
              >
                Acceptable Formats: <br />
                PDF, JPEG, PNG, DOCX, XLSX, CSV
              </Typography>
              <UploadDeviceFile onUploadDeviceFile={handleFileUpload} />
              <p>{uploadedDeviceFileName}</p>
              <AddFileModal
                submitTestFileAndData={handleSubmit}
                openStatus={openSubmitModal}
                handleModalClose={handleCloseSubmitModal}
                alertContentText={alertContent}
              />
              <Button
                type="submit"
                variant="contained"
                size="large"
                color="success"
                sx={{ ml: 1, mr: 6, mt: 4, borderRadius: 8, width: 225 }}
              >
                Submit
              </Button>
            </Box>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }

  const getDetailPanelContent = ({ row }) => <DetailPanelContent row={row} />;
  const getDetailPanelHeight = useCallback(() => "auto", []);

  const deviceColumns = [
    //COLUMNS THAT ARE PRESENT BUT NOT VISIBLE
    {
      field: "DeviceID",
      headerName: "Device ID",
      editable: true,
      type: "number",
      headerAlign: "center",
      width: 75,
      renderHeader: (params) => (
        <strong>
          {"Device "}
          <br></br>
          {"ID"}
        </strong>
      ),
    },
    {
      field: "ClientID",
      headerName: "Client ID",
      editable: true,
      type: "number",
      width: 75,
    },
    {
      field: "ContactID",
      headerName: "Contact ID",
      editable: true,
      type: "number",
      width: 100,
    },
    {
      field: "SlateID",
      headerName: "Slate ID",
      editable: true,
      type: "number",
      width: 75,
    },

    //COLUMNS THAT ARE PRESENT AND VISIBLE WITHOUT SCROLLING
    {
      field: "CompanyName",
      headerName: "Company Name",
      editable: true,
      width: 250,
    },
    {
      field: "DeviceType",
      headerName: "Device Type",
      editable: true,
      width: 100,
      headerAlign: "center",
      align: "center",
      renderHeader: (params) => (
        <strong>
          {"Device "}
          <br></br>
          {"Type"}
        </strong>
      ),
    },
    { field: "Location", headerName: "Location", editable: true, width: 175 },
    // { field: 'LatestTestDate', headerName: 'Last Test Date', editable: true, width: 150, type: 'date', valueFormatter: params => new Date(params?.value)},
    // { field: 'ActualTestDate', headerName: 'Actual Test Date', editable: true, width: 150, type: 'date', valueFormatter: params => new Date(params?.value)},

    {
      field: "LatestTestDate",
      headerName: "Last Test Date",
      editable: true,
      textAlign: "center",
      width: 110,
      type: "date",
      valueGetter: ({ value }) => value && new Date(value),
      valueFormatter: (params) => moment(params?.value).format("MM/DD/YYYY"),
      renderHeader: (params) => (
        <strong>
          {"Last Test"}
          <br />
          {"Date"}
        </strong>
      ),
    },
    {
      field: "ActualTestDate",
      headerName: "Actual Test Date",
      editable: true,
      width: 110,
      type: "date",
      valueGetter: ({ value }) => value && new Date(value),
      valueFormatter: (params) => moment(params?.value).format("MM/DD/YYYY"),
      textAlign: "center",
      textAlign: "center",
      renderHeader: (params) => (
        <strong>
          {"Last Test"}
          <br />
          {"Date"}
        </strong>
      ),
    },
    {
      field: "NewSchTestDate",
      headerName: "Scheduled Test Date",
      editable: true,
      width: 110,
      type: "date",
      textAlign: "center",
      renderHeader: (params) => (
        <strong>
          {"Scheduled "}
          <br />
          {"Test Date"}
        </strong>
      ),
      valueGetter: ({ value }) => value && new Date(value),
      valueFormatter: (params) => moment(params?.value).format("MM/DD/YYYY"),
    },
    {
      field: "DaysUntilDue",
      width: 125,
      headerName: "Days Until Due",
      headerAlign: "center",
      align: "center",
      textAlign: "center",
      renderHeader: (params) => (
        <strong>
          {"Days Until"}
          <br />
          {"Due"}
        </strong>
      ),
    },
    { field: "Manufacturer", headerName: "Manufacturer", editable: true },
    { field: "Model", headerName: "Model", editable: true, width: 75 },

    //COLUMNS THAT ARE PRESENT BUT REQUIRE SCROLLING HORIZONTALLY
    { field: "Condition", headerName: "Condition", editable: true },
    {
      field: "SerialNumber",
      headerName: "Serial Number",
      editable: true,
      width: 125,
      headerAlign: "center",
      align: "center",
      renderHeader: (params) => (
        <strong>
          {"Serial"}
          <br />
          {"Number"}
        </strong>
      ),
    },
    { field: "Status", headerName: "Status", editable: true, width: 100 },
    { field: "Comments", headerName: "Comments", editable: true, width: 250 },
    {
      field: "InstalledDate",
      headerName: "Installed Date",
      editable: true,
      width: 150,
      type: "date",
      valueGetter: ({ value }) => value && new Date(value),
      valueFormatter: (params) => moment(params?.value).format("MM/DD/YYYY"),
      type: "date",
    },
    {
      field: "RetiredDate",
      headerName: "Retired Date",
      editable: true,
      width: 150,
      type: "date",
      valueGetter: ({ value }) => value && new Date(value),
      valueFormatter: (params) => moment(params?.value).format("MM/DD/YYYY"),
      type: "date",
    },
    { field: "PWS", headerName: "PWS", editable: true },
    {
      field: "Size",
      headerName: "Size",
      editable: true,
      width: 60,
      align: "center",
    },
    {
      field: "IsMandatory",
      headerName: "Mandatory",
      editable: true,
      headerAlign: "center",
      align: "center",
      width: 150,
    },
    {
      field: "StreetAddress",
      headerName: "Address",
      editable: true,
      width: 130,
    },
    { field: "City", headerName: "City", editable: true },
    { field: "State", headerName: "State", editable: true },
    { field: "CompanyID", headerName: "Company ID", editable: true },
    {
      field: "ReplacesDeviceID",
      headerName: "Replaces Device ID",
      editable: true,
      width: 150,
    },
    {
      field: "CompletedTestID",
      headerName: "Completed Test ID",
      editable: true,
      type: "integer",
      width: 150,
    },
    {
      field: "CompletedTestDeviceID",
      headerName: "Completed Test Device ID",
      editable: true,
      width: 185,
    },
    {
      field: "CompletedTestTechID",
      headerName: "Completed Test Tech ID",
      editable: true,
      hide: true,
      width: 175,
    },
    {
      field: "GaugeCalibrationDate",
      headerName: "Gauge Calibration Date",
      editable: true,
      width: 175,
      type: "date",
      valueGetter: ({ value }) => value && new Date(value),
      valueFormatter: (params) => moment(params?.value).format("MM/DD/YYYY"),
    },
    { field: "CompletedTestReport", headerName: "Test Report", editable: true },
    {
      field: "TechID",
      headerName: "Technician ID",
      editable: true,
      hide: true,
    },
    {
      field: "CertTestNumber",
      headerName: "Certification Test Number",
      editable: true,
      width: 175,
    },
    {
      field: "TechName",
      headerName: "Technician Name",
      editable: true,
      width: 170,
    },
    {
      field: "ScheduledTestID",
      headerName: "Scheduled Test ID",
      editable: true,
      width: 150,
    },
    {
      field: "SchTestDeviceID",
      headerName: "Scheduled Test Device ID",
      editable: true,
      width: 175,
    },
    {
      field: "RetireDevice",
      width: 80,
      headerName: "Retire Device",
      renderCell: (params) => (
        <RemoveCircleIcon onClick={handleOpenModal} color="error" />
      ),
    },
    {
      field: "",
      width: 80,
      headerName: "",
      renderCell: (params) =>
        userRole !== "user" && (
          <Tooltip
            title={
              !params.row.EmailEnabled ? (
                <div
                  style={{
                    textAlign: "center",
                    fontSize: "15px",
                    fontWeight: "600",
                    padding: "15px",
                    lineHeight: "1rem",
                  }}
                >
                  <span>Edit Device</span>
                </div>
              ) : (
                ""
              )
            }
            PopperProps={{
              modifiers: [
                {
                  name: "maxWidth",
                  enabled: true,
                  phase: "beforeWrite",
                  fn({ state }) {
                    state.styles.popper.maxWidth = "150px";
                  },
                },
              ],
            }}
            placement="right"
            arrow
          >
            <BorderColorIcon
              onClick={() => handleEditOpenModal(params.row)}
              color="info"
              className="editIconCustom"
            />
          </Tooltip>
        ),
    },
  ];

  const addDaysUntilDue = (row) => {
    const today = new Date();
    const millisecondsUntilDue =
      new Date(row.NewSchTestDate).getTime() - today.getTime();
    const cutoffVal = -9000;
    const numDays = Math.floor(millisecondsUntilDue / (1000 * 3600 * 24));

    const rowVal = {
      ...row,
      DaysUntilDue: numDays > cutoffVal ? numDays : "N/A",
    };
    return rowVal;
  };

  useEffect(() => {
    async function fetchDevices() {
      const response = await fetch(`${baseURL}/${clientId}/devices`);
      const data = await response.json();
      onSaveNumDevices(data.data.map(addDaysUntilDue));
      setDeviceData(data.data.map(addDaysUntilDue));
    }
    fetchDevices();
  }, [isUpdate, clientId]);

  useEffect(() => {
    onSaveNumDevices(deviceData);
    //console.log('Dvice data filtered overdue', deviceData.filter(record => record.NewSchTestDate!==null && record.IsMandatory === "Yes" && new Date(record.NewSchTestDate) < new Date()).length)
  }, [deviceData]);

  // useEffect(() => {
  //   fetch(`https://flo-trace-api-bmjve.ondigitalocean.app/${clientId}/devices`)
  //   .then(response => response.json())
  //   .then(data => {setDeviceData(data.data.map(addDaysUntilDue))})
  //   .then(onSaveNumDevices(deviceData))
  // }, []);

  useEffect(() => {
    onSaveNumDevices(deviceData);
  }, [deviceData]);

  return (
    <React.Fragment>
      <NoFileSelectedDetailPanelContentModal
        open={openNoFileSelectedModal}
        handleClose={handleNoFileSelectedModalClose}
      />
      {/* <Title>{'Devices ' + props.updateTableTitle}</Title> */}
      <DeleteAttachmentModal
        retireDevice={handleRetireDevice}
        deleteAttachment={handleDeleteAttachment}
        openStatus={deleteAttachmentModal}
        handleClose={handleDeleteAttachmentModalClose}
      />
      <RetireDeviceModal
        retireDevice={handleRetireDevice}
        clientId={clientId}
        rowSelectionModel={rowSelectionModel}
        openStatus={retireDeviceModalStatus}
        handleCloseModal={handleCloseModal}
      />
      <AddTestModal
        openStatus={openSubmitModal}
        alertContentText={alertContent}
      />
      {/* <Title>Devices</Title> */}
      <DataGridPremium
        className="dashboardTable"
        exportIcon={ExpoertIcon}
        pinnedRows={deviceData[0]}
        enableStickyHeader={true}
        getRowId={(row) => row.DeviceID}
        pagination
        checkboxSelection={true}
        disableMultipleRowSelection={true}
        onRowSelectionModelChange={(newRowSelectionModel) => {
          //setSelectedIDs(newRowSelectionModel);
          setPreviousRowSelectionModel(rowSelectionModel);
          setRowSelectionModel(newRowSelectionModel);
          const ids = new Set(newRowSelectionModel);
          const selectedRows = deviceData.filter((row) =>
            ids.has(row.DeviceID)
          );
          onSelectRows(selectedRows);
        }}
        rowSelectionModel={rowSelectionModel}
        // onRowSelectionModelChange={(ids) => {
        //   setSelectedIDs(ids[0]);
        //   const selectedIDs = new Set(ids);
        //   const selectedRows = deviceData.filter((row) =>
        //     selectedIDs.has(row.DeviceID)
        //   );
        //   onSelectRows(selectedRows);
        // }}
        getDetailPanelHeight={getDetailPanelHeight}
        getDetailPanelContent={getDetailPanelContent}
        detailPanelExpandedRowIds={detailPanelExpandedRowIds}
        //onDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}
        onDetailPanelExpandedRowIdsChange={(ids) =>
          setDetailPanelExpandedRowIds(ids)
        }
        initialState={{
          ...deviceData.initialState,
          //pinnedColumns: { left: ['CompanyName'], right: ['RetireDevice'] },
          //pinnedRows: {left: ['CompanyName'], right: ['RetireDevice'] },
          pagination: { paginationModel: { pageSize: 10 } },
          columns: {
            columnVisibilityModel: {
              ClientID: false,
              DeviceID: false,
              TechID: false,
              ContactID: false,
              SlateID: false,
              ScheduledTestID: false,
              Manufacturer: false,
              Model: false,
              Condition: false,
              // SerialNumber :false,
              Status: false,
              Comments: false,
              InstalledDate: false,
              RetiredDate: false,
              PWS: false,
              RetireDevice: false,
              SchTestDeviceID: false,
              ScheduledTestID: false,
              CertTestNumber: false,
              TechID: false,
              CompletedTestReport: false,
              GaugeCalibrationDate: false,
              CompletedTestTechID: false,
              CompletedTestID: false,
              CompletedTestDeviceID: false,
              ReplacesDeviceID: false,
              CompanyID: false,
              City: false,
              State: false,
              TechName: false,
              LatestTestDate: false,
            },
          },
        }}
        pageSizeOptions={[5, 10, 25, 100]}
        editMode="row"
        filterModel={currentFilterModel}
        rows={deviceData}
        loading={deviceData.length === 0}
        columns={deviceColumns}
        onFilterModelChange={(newFilterModel) =>
          setFilterModelFunction(newFilterModel)
        }
        components={{ Toolbar: CustomToolbar }}
        lenDeviceData={deviceData.length}
      />
      {editModal && (
        <ModalPopUp
          open={true}
          handleClose={handleEditCloseModal}
          title="Edit Device Details"
          content=""
          formData={editModalData()}
        />
      )}
    </React.Fragment>
  );
}
