import { configureStore } from "@reduxjs/toolkit";
import UserReducer from "./UserReducer";
import ClientsReducer from "./ClientsReducer";

export const store = configureStore({
    reducer:{
        userID: UserReducer,
        clientList: ClientsReducer
    }
});

// export const rootState = store.getState;
export const appDispatch = store.dispatch;