import React, { useState, useEffect } from "react";
import Title from "./Title";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import moment from "moment";
import Box from "@mui/material/Box";
import axios from "axios";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CustomToolbar from "./CustomToolbar";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import Container from "@mui/material/Container";
import { Tooltip } from "@mui/material";
import PhoneMenu from "./Common/PhoneMenu";
import SidebarMenu from "./Common/SidebarMenu";
import Header from "./Common/Header";
import Footer from "./Common/Footer";
import AddTechnicianIcon from "./Common/assets/imgs/add-technician.svg";
import MapBlueIcon from "./Common/assets/imgs/map.svg";
import ModalPopUp from "./Common/Modal/ModalPopUp";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import EditTechnician from "./EditFroms/EditTechnicians";
import { useSelector } from "react-redux";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import {
  isTokenExpired,
  handleExpiredToken,
  handleTokenExpiryWarning,
} from "./Common/CheckJwtExpires";

export default function TechniciansTable({
  handleLogout,
  onSelectRows,
  currentFilterModel,
  // clientId,
  clientName,
  clientNameHeader,
}) {
  console.log(currentFilterModel, "FILTER");
  const clientId = useSelector((state) => state.userID.value);
  const token = localStorage.getItem("token");
  const userRole = localStorage.getItem("userRoleName");
  const [editModal, setEditModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const [techniciansData, setTechniciansData] = useState([]);
  const [open, setOpen] = useState(true);
  const [selectedIDs, setSelectedIDs] = useState(1);
  const [confimModal, setConfrimModal] = useState(false);
  const [retireTechnicianModalStatus, setRetireTechnicianModalStatus] =
    useState(false);
  const [openSubmitModal, setOpenSubmitModal] = useState(false);
  const [alertContent, setAlertContent] = useState("");
  const [alert, setAlert] = useState(false);

  const [TechCols, setTechCol] = useState(null);
  const [filterModel, setFilterModel] = useState({ items: [] });
  const [gettingFilters, setGettingFilters] = useState(false);
  const authToken = localStorage.getItem("token");

  const handleOpenModal = () => setRetireTechnicianModalStatus(true);
  const handleCloseModal = () => setRetireTechnicianModalStatus(false);
  const [toggleMenus, setToggleMenus] = useState(false);

  useEffect(() => {
    const checkTokenExpiry = () => {
      if (isTokenExpired(token)) {
        handleExpiredToken();
      } else {
        handleTokenExpiryWarning(token);
      }
    };

    checkTokenExpiry();
    const interval = setInterval(checkTokenExpiry, 60000);

    return () => clearInterval(interval);
  }, [token]);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleEditOpenModal = (data) => {
    setEditModal(true);
    setSelectedRow(data);
  };

  const fetchFilters = () => {
    axios
      .get(`${baseURL}/filters`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((response) => {
        console.log(response);
        if (response.data) {
          setTechCol(response.data.filters.TechColumn || null);

          if (response.data.filters.TechFilters) {
            setFilterModel(response.data.filters.TechFilters || []);
          } else {
            setFilterModel({ items: [] });
          }
          setGettingFilters(true);
        } else {
          setGettingFilters(true);
          setFilterModel({ items: [] });
          console.log("No data found");
        }
      })
      .catch((error) => {
        setGettingFilters(true);
        setFilterModel({ items: [] });
        console.error("Error fetching filters:", error);
      });
  };
  useEffect(() => {
    console.log(filterModel, "OKOKK");
    fetchFilters();
  }, []);

  const handleSaveColumns = (value) => {
    console.log(JSON.stringify(value));
    axios
      .patch(
        `${baseURL}/update/filters`,
        { TechColumn: value },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      .then((response) => {
        if (response.data.success) {
          console.log("Filters updated successfully");
        } else {
          console.log("Error updating filters");
        }
      })
      .catch((error) => {
        console.error("Error updating filters:", error);
      });
  };

  const handleSaveFilters = (value) => {
    console.log(filterModel);
    setFilterModel(value);
    console.log(JSON.stringify(value));
    axios
      .patch(
        `${baseURL}/update/filters`,
        { TechFilters: value },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      .then((response) => {
        if (response.data.success) {
          console.log("Filters updated successfully");
        } else {
          console.log("Error updating filters");
        }
      })
      .catch((error) => {
        console.error("Error updating filters:", error);
      });
  };

  const handleEditCloseModal = (event, reason) => {
    if (reason !== "backdropClick") {
      setEditModal(false);
    }
  };
  const [isUpdate, setIsUpdate] = useState(false);

  const UpdatedMessageOptions = {
    title: "This technician has been retired and removed.",
    overlayClassName: "overlay-custom-class-name successMessage",
    buttons: [
      {
        label: "Close",
        className: "cancelBtn",
        onClick: () => {
          setConfrimModal(false);
          return false;
        },
      },
    ],
  };
  const options = {
    // title: "Title",
    message: "Are you sure you want to retire technician?",
    buttons: [
      {
        label: "Yes",
        className: "confimBtn",
        onClick: () => {
          setConfrimModal(false);
          retiredTechnicianByID();
        },
      },
      {
        label: "No",
        className: "cancelBtn",
        onClick: () => {
          setConfrimModal(false);
          handleEditCloseModal();
        },
      },
    ],
    closeOnEscape: true,
    closeOnClickOutside: false,
    keyCodeForClose: [8, 32],
    willUnmount: () => {},
    afterClose: () => {},
    onClickOutside: () => {},
    onKeypress: () => {},
    onKeypressEscape: () => {},
    overlayClassName: "overlay-custom-class-name",
  };
  const handleRetireTechnician = (params) => {
    setConfrimModal(true);
    if (!confimModal) {
      confirmAlert(options);
    }
  };

  const retiredTechnicianByID = () => {
    fetch(`${baseURL}/${clientId}/${selectedIDs}/retiretechnician`)
      .then((response) => response.json())
      .then((response) => {
        confirmAlert(UpdatedMessageOptions);
        setIsUpdate(!isUpdate);
      })
      .then((result) => {
        console.log(result);
      });
  };

  //   // title: "Title",
  //   message: "Are you sure you want to retire technician?",
  //   buttons: [
  //     {
  //       label: "Yes",
  //       className: "confimBtn",
  //       onClick: () => {
  //         setConfrimModal(false);
  //         retiredTechnicianByID();
  //       },
  //     },
  //     {
  //       label: "No",
  //       className: "cancelBtn",
  //       onClick: () => {
  //         setConfrimModal(false);
  //         handleEditCloseModal();
  //       },
  //     },
  //   ],
  //   closeOnEscape: true,
  //   closeOnClickOutside: false,
  //   keyCodeForClose: [8, 32],
  //   willUnmount: () => {},
  //   afterClose: () => {},
  //   onClickOutside: () => {},
  //   onKeypress: () => {},
  //   onKeypressEscape: () => {},
  //   overlayClassName: "overlay-custom-class-name",
  // };

  const techniciansColumns = [
    { field: "TechID", headerName: "Technician ID", editable: true, width: 75 },
    { field: "TechName", headerName: "Tech Name", editable: true, width: 150 },
    { field: "FirmName", headerName: "Firm Name", editable: true, width: 300 },
    {
      field: "CertTestNumber",
      headerName: "Certification Test Number",
      editable: true,
      type: "number",
      width: 150,
      headerAlign: "center",
      align: "center",
      textAlign: "center",
      renderHeader: (params) => (
        <strong>
          {"Certification"}
          <br />
          {"Test Number"}
        </strong>
      ),
    },
    {
      field: "ExpiredDate",
      headerName: "Expired Date",
      editable: true,
      width: 120,
      valueFormatter: (params) => moment(params?.value).format("MM/DD/YYYY"),
      type: "date",
    },
    {
      field: "MobilePhone",
      headerName: "Mobile Phone",
      editable: true,
      width: 150,
    },
    {
      field: "WorkPhone1",
      headerName: "Work Phone 1",
      editable: true,
      width: 165,
    },
    {
      field: "WorkPhone2",
      headerName: "Work Phone 2",
      editable: true,
      width: 125,
    },
    { field: "City", headerName: "City", editable: true, width: 125 },
    { field: "State", headerName: "State", editable: true, width: 80 },
    {
      field: "Postal Code",
      headerName: "Zipcode",
      editable: true,
      width: 80,
    },
    {
      field: "",
      width: 80,
      align: "center",
      headerName: "",
      hideable: false,
      renderCell: (params) =>
        userRole !== "user" && (
          <Tooltip
            title={
              !params.row.EmailEnabled ? (
                <div
                  style={{
                    textAlign: "center",
                    fontSize: "15px",
                    fontWeight: "600",
                    padding: "15px",
                    lineHeight: "1rem",
                  }}
                >
                  <span>Edit Technician</span>
                </div>
              ) : (
                ""
              )
            }
            PopperProps={{
              modifiers: [
                {
                  name: "maxWidth",
                  enabled: true,
                  phase: "beforeWrite",
                  fn({ state }) {
                    state.styles.popper.maxWidth = "170px";
                  },
                },
              ],
            }}
            placement="right"
            arrow
          >
            <BorderColorIcon
              onClick={() => handleEditOpenModal(params.row)}
              color="info"
              className="editIconCustom"
            />
          </Tooltip>
        ),
    },
  ];

  // Conditionally add the "Retire Technician" column if userRole is not "user"
  if (userRole !== "user") {
    techniciansColumns.splice(techniciansColumns.length - 1, 0, {
      field: "Retire Technician",
      width: 120,
      headerAlign: "center",
      align: "center",
      hideable: false,
      headerName: "Retire Technician",
      renderCell: (params) => (
        <Tooltip
          title={
            <div
              style={{
                textAlign: "center",
                fontSize: "15px",
                fontWeight: "600",
                padding: "15px",
                lineHeight: "1rem",
              }}
            >
              <span>
                Retire this technician
                <br />
                <span>[set to non-active]</span>
              </span>
            </div>
          }
          placement="left"
          arrow
        >
          <RemoveCircleIcon
            onClick={(e) => handleRetireTechnician(params)}
            color="error"
          />
        </Tooltip>
      ),
      renderHeader: (params) => (
        <strong>
          {"Retire"}
          <br />
          {"Technician"}
        </strong>
      ),
    });
  }

  const baseURL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    fetch(`${baseURL}/${clientId}/technicians`)
      .then((response) => response.json())
      .then((data) => {
        setTechniciansData(data.data);
      });
  }, [isUpdate, clientId]);

  useEffect(() => {
    // console.log("WILL BE DELETED", selectedIDs);
  }, [selectedIDs]);

  const editModalData = () => {
    console.log({ selectedRow });
    return (
      <EditTechnician
        clientID={clientId}
        data={selectedRow}
        handleClose={handleEditCloseModal}
        updateSatus={setIsUpdate}
        updateCurrentState={isUpdate}
      />
    );
  };

  return (
    <>
      <Box>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            {/* Header Section */}
            <Header
              clientId={clientId}
              clientName={clientName}
              handleLogout={handleLogout}
              istoggleMenu={toggleMenus}
              clientNameHeader={clientNameHeader}
            />
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Grid container spacing={0}>
          <Grid item xs={12} md={2}>
            {/* Footer Section */}
            <PhoneMenu slug="/technicians" />
            <SidebarMenu toggleMenu={setToggleMenus} />
          </Grid>
          <Grid
            item
            xs={12}
            md={10}
            className={
              toggleMenus === true ? "tooggleActive mainContent" : "mainContent"
            }
          >
            <Container maxWidth="xlg" sx={{ mt: 8, mb: 4 }}>
              <Grid container spacing={10} className="topDueSect">
                <Grid item xs={12} sm={12} md={6} className="beforetable">
                  <Typography
                    className="SelDevice"
                    align="left"
                    variant="h4"
                    gutterBottom
                  >
                    TECHNICIANS
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  sx={{ textAlign: "right" }}
                  className="beforetable"
                >
                  <Link to="/map" title="View Map">
                    <img
                      src={MapBlueIcon}
                      width={80}
                      className="Icon_blue"
                      alt="deviceBlue_ICON"
                    />
                  </Link>
                  {userRole !== "admin" && userRole !== "user" && (
                    <Link to="/addtechnician" title="Add Technicians">
                      <img
                        src={AddTechnicianIcon}
                        width={75}
                        className="Icon_black"
                        alt="AddCompanyIcon"
                      />
                    </Link>
                  )}
                </Grid>

                <Grid item xs={12} className="tabSect">
                  {gettingFilters ? (
                    <DataGridPremium
                      className="dashboardTable"
                      getRowId={(row) => row.TechID}
                      pagination
                      checkboxSelection={false}
                      disableMultipleRowSelection={true}
                      onRowSelectionModelChange={(ids) => {
                        setSelectedIDs(ids[0]);
                        console.log(ids[0]);
                      }}
                      onStateChange={(e) => console.log(e)}
                      onColumnVisibilityModelChange={(e) =>
                        handleSaveColumns(e)
                      }
                      initialState={{
                        ...techniciansData.initialState,
                        pagination: { paginationModel: { pageSize: 10 } },
                        columns: {
                          columnVisibilityModel: TechCols,
                        },
                      }}
                      pageSizeOptions={[5, 10, 25, 100]}
                      editMode="row"
                      filterModel={filterModel}
                      rows={techniciansData}
                      loading={techniciansData.length === 0}
                      columns={techniciansColumns}
                      components={{ Toolbar: CustomToolbar }}
                      onFilterModelChange={(newFilterModel) =>
                        handleSaveFilters(newFilterModel)
                      }
                    />
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>
            </Container>
          </Grid>
        </Grid>
      </Box>
      {editModal && (
        <ModalPopUp
          open={true}
          disableEscapeKeyDown={true}
          handleClose={handleEditCloseModal}
          title="Edit Technician Details"
          content=""
          formData={editModalData()}
        />
      )}
      <Footer />
    </>
  );
}
