import React, { useState, useEffect } from "react";
import { TextField, Button } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import MuiAlert from "@mui/material/Alert";
import AddTestModal from "../AddTestModal";
import MenuItem from "@mui/material/MenuItem";
import { Link } from "react-router-dom";
import Container from "@mui/material/Container";
import PhoneMenu from "../Common/PhoneMenu";
import { states } from "../States";
import SidebarMenu from "../Common/SidebarMenu";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import { useSelector } from "react-redux";
import InputMask from "react-input-mask";
import {
  isTokenExpired,
  handleExpiredToken,
  handleTokenExpiryWarning,
} from "../Common/CheckJwtExpires";

const AddCompany = ({ clientName, handleLogout, clientNameHeader }) => {
  const clientId = useSelector((state) => state.userID.value);

  const [open, setOpen] = useState(true);
  const [companiesData, setCompaniesData] = useState([]);
  const [companyDevicesData, setCompanyDevicesData] = useState([]);
  const [testFiles, setTestFiles] = useState([]);
  const [uploadedFileName, setUploadedFileName] = useState("");
  const [formData, setFormData] = useState({
    companyName: "",
    website: "",
    owner: "",
    address: "",
    state: "",
    zip: "",
    city: "",
  });
  const [zipIsValid, setZipIsValid] = useState(false);
  const [deviceSerialNumbers, setDeviceSerialNumbers] = useState([]);
  const [deviceID, setDeviceID] = useState([]);
  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState("");
  const [openSubmitModal, setOpenSubmitModal] = useState(false);
  const [deviceScheduledTestDate, setDeviceScheduledTestDate] = useState("");
  const [selectedDeviceID, setSelectedDeviceID] = useState("");

  const [companyNameDupeExists, setCompanyNameDupeExists] = useState(false);

  const handleOpenSubmitModal = () => setOpenSubmitModal(true);
  const [formIsValid, setFormIsValid] = useState(false);
  const handleCloseSubmitModal = () => setOpenSubmitModal(false);
  const [toggleMenus, setToggleMenus] = useState(false);
  const token = localStorage.getItem("token");
  const baseURL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const checkTokenExpiry = () => {
      if (isTokenExpired(token)) {
        handleExpiredToken();
      } else {
        handleTokenExpiryWarning(token);
      }
    };

    checkTokenExpiry();
    const interval = setInterval(checkTokenExpiry, 60000);

    return () => clearInterval(interval);
  }, [token]);

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  useEffect(() => {
    if (
      formData.companyName &&
      formData.website &&
      formData.owner &&
      formData.city &&
      formData.address &&
      formData.state &&
      zipIsValid
    ) {
      console.log(formData.zip.length);
      setFormIsValid(true);
    } else {
      setFormIsValid(false);
    }
  }, [
    formData.companyName,
    formData.website,
    formData.owner,
    formData.city,
    formData.address,
    formData.state,
    formData.zip,
  ]);

  useEffect(() => {
    fetch(
      `${baseURL}/${clientId}/${formData.companyName.replace(
        / /g,
        "%20"
      )}/checkcompanyduplicates`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.response === "Company with this name already exists.") {
          setCompanyNameDupeExists(true);
          console.log("setting state", companyNameDupeExists);
        } else {
          setCompanyNameDupeExists(false);
          console.log("Not a dupe");
        }
      });
  }, [formData.companyName, clientId]);

  const handleSubmit = (e) => {
    e.preventDefault();

    fetch(`${baseURL}/${clientId}/postcompany`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then(() => {
        setAlertContent("Company created successfully!");
        setAlert(true);
        handleOpenSubmitModal();
      })
      .then((result) => {
        console.log(result);
      });
  };

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <>
      <Box>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            {/* Header Section */}
            <Header
              clientId={clientId}
              clientName={clientName}
              handleLogout={handleLogout}
              istoggleMenu={toggleMenus}
              clientNameHeader={clientNameHeader}
            />
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Grid container spacing={0}>
          <Grid item xs={12} md={2}>
            {/* Footer Section */}
            <PhoneMenu slug="/technicians" />
            <SidebarMenu toggleMenu={setToggleMenus} />
          </Grid>
          <Grid
            item
            xs={12}
            md={10}
            className={
              toggleMenus === true ? "tooggleActive mainContent" : "mainContent"
            }
          >
            <Container maxWidth="xlg" sx={{ mt: 8, mb: 4 }}>
              <Grid container spacing={10} className="topDueSect">
                <Grid item xs={12} sm={12} md={6} className="beforetable">
                  <Typography
                    className="SelDevice"
                    align="left"
                    variant="h4"
                    gutterBottom
                  >
                    ADD NEW COMPANY
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  sx={{ textAlign: "right" }}
                  className="beforetable"
                ></Grid>

                <Grid item xs={12} className="tabSect">
                  <Box
                    align="left"
                    sx={{
                      mt: 1,
                      "& .MuiTextField-root": { m: 1, width: "30ch" },
                    }}
                    autoComplete="off"
                  >
                    <h3>Company Details</h3>
                    <Box component="form" onSubmit={handleSubmit}>
                      <Grid sx={{ mt: 2 }}>
                        <Grid>
                          <TextField
                            //error={formData.companyName === ''}
                            error={
                              formData.companyName === "" ||
                              companyNameDupeExists
                            }
                            id="outlined-select-currency"
                            style={{ width: 400 }}
                            //label="Company Name"
                            required
                            helperText={
                              companyNameDupeExists
                                ? "Warning- company with this name already exists"
                                : "Company name"
                            }
                            value={formData.companyName}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                companyName: e.target.value,
                              })
                            }
                          ></TextField>
                          <TextField
                            id="outlined-select-currency"
                            style={{ width: 400 }}
                            //label="Website"
                            helperText="Company website"
                            value={formData.website}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                website: e.target.value,
                              })
                            }
                          ></TextField>
                          <TextField
                            id="outlined-select-currency"
                            style={{ width: 310 }}
                            //label="Website"
                            helperText="Company owner"
                            value={formData.owner}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                owner: e.target.value,
                              })
                            }
                          ></TextField>
                        </Grid>
                      </Grid>
                      <h3>Company Address</h3>
                      <Grid>
                        <TextField
                          id="outlined-select-currency"
                          style={{ width: 300 }}
                          //label="Owner"
                          helperText="Company address"
                          value={formData.address}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              address: e.target.value,
                            })
                          }
                        ></TextField>
                        <TextField
                          id="outlined-select-currency"
                          required
                          helperText="Company city"
                          value={formData.city}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              city: e.target.value,
                            });
                          }}
                        ></TextField>
                        <TextField
                          id="outlined-select-currency"
                          select
                          required
                          //style = {{width: 260}}
                          //label="State"
                          helperText="Select associated State"
                          value={formData.state}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              state: e.target.value,
                            });
                          }}
                        >
                          {states.map((option) => (
                            <MenuItem key={option.id} value={option.state}>
                              {option.state}
                            </MenuItem>
                          ))}
                        </TextField>
                        <InputMask
                          mask="99999-9999"
                          value={formData.zip}
                          disabled={false}
                          maskChar=" "
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              zip: e.target.value,
                            });
                            let digitLength = e.target.value
                              .toString()
                              .replace(/[^0-9]/g, "").length;
                            if (digitLength < 5) {
                              setZipIsValid(false);
                            } else if (digitLength > 5 && digitLength < 9) {
                              setZipIsValid(false);
                            } else {
                              setZipIsValid(true);
                              //console.log('Setting fax valid to tru')
                            }
                          }}
                        >
                          {() => (
                            <TextField
                              error={
                                formData.zip.toString().replace(/[^0-9]/g, "")
                                  .length < 5 ||
                                (formData.zip.toString().replace(/[^0-9]/g, "")
                                  .length > 5 &&
                                  formData.zip.toString().replace(/[^0-9]/g, "")
                                    .length < 9)
                              }
                              helperText={
                                formData.zip.toString().replace(/[^0-9]/g, "")
                                  .length < 5 ||
                                (formData.zip.toString().replace(/[^0-9]/g, "")
                                  .length > 5 &&
                                  formData.zip.toString().replace(/[^0-9]/g, "")
                                    .length < 9)
                                  ? "Zipcode (must be either 5 or 9 digits)"
                                  : " "
                              }
                              id="outlined-select-currency"
                              //style = {{width: 275}}
                              required
                              value={formData.zip}
                            ></TextField>
                          )}
                        </InputMask>
                      </Grid>

                      <Grid>
                        <Box sx={{ display: "inline-flex" }}>
                          <AddTestModal
                            submitTestFileAndData={handleSubmit}
                            openStatus={openSubmitModal}
                            handleModalClose={handleCloseSubmitModal}
                            alertContentText={alertContent}
                          />
                          <Button
                            disabled={formIsValid ? false : true}
                            type="submit"
                            variant="contained"
                            size="large"
                            color="success"
                            sx={{
                              ml: 1,
                              mr: 6,
                              mt: 4,
                              borderRadius: 8,
                              width: 225,
                            }}
                          >
                            Submit
                          </Button>
                          <Button
                            size="large"
                            href="/"
                            variant="contained"
                            sx={{
                              mr: 6,
                              mt: 4,
                              borderRadius: 8,
                              borderRadius: 8,
                              width: 225,
                            }}
                            color="error"
                          >
                            Cancel
                          </Button>
                        </Box>
                      </Grid>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </>
  );
};

export default AddCompany;
