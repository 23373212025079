import React, { useState, useEffect, useRef } from "react";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { Download, Loader, Trash, Paperclip, FileText, X } from "lucide-react";
import word from "../components/Common/assets/imgs/word.jpg";
import Box from "@mui/material/Box";
import {
  TextField,
  FormHelperText,
  Select,
  Button,
  OutlinedInput,
  InputAdornment,
  InputLabel,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Snackbar,
  Alert,
  Typography,
} from "@mui/material";
import fileFormatImage from "../assets/images/fileformat_r2.png";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputMask from "react-input-mask";

import FormControl from "@mui/material/FormControl";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Copyright from "./Copyright";
import CategoryCard from "./CategoryCard";
import DeviceTable from "./DeviceTable";
import FloTraceLogoWhite from "./FloTraceLogoWhite";
import GenerateLettersModal from "./GenerateLettersModal";

import Header from "./Common/Header";
import Footer from "./Common/Footer";
import SidebarMenu from "./Common/SidebarMenu";
import PhoneMenu from "./Common/PhoneMenu";
import AlertImg from "./Common/assets/imgs/alert.svg";
import AddCompanyIcon from "./Common/assets/imgs/add-device.svg";
import MapBlueIcon from "./Common/assets/imgs/map.svg";
import AddTestModal from "./AddTestModal";
import LoadingModal from "./Common/LoadingModal";

import moment from "moment/moment";
import { useSelector } from "react-redux";
import {
  isTokenExpired,
  handleExpiredToken,
  handleTokenExpiryWarning,
} from "./Common/CheckJwtExpires";
import { confirmAlert } from "react-confirm-alert";
import { Tooltip, IconButton, Popover } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { TroubleshootTwoTone } from "@mui/icons-material";

const defaultTheme = createTheme();

export default function OnBoardNewClient({
  removeItems,
  handleLogout,
  clientName,
  clientNameHeader,
  isClientSelected,
  onisClientSelected,
}) {
  const clientId = useSelector((state) => state.userID.value);
  const clientList = useSelector((state) => state.clientList.value);
  const [showPassword, setShowPassword] = React.useState(false);
  const [showPasswordConfrm, setShowPasswordConfrm] = React.useState(false);

  const [password, setPassword] = useState();
  const [userFormData, setUserFormData] = useState({
    ClientID: clientId,
    UserFirstName: "",
    UserLastName: "",
    UserEMail: "",
    Password: "",
    MobilePhone: "",
    timeZone: "",
    Role: "superadmin",
    isPhoneEnabled: true,
    isEmailEnabled: true,
  });
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const userRole = localStorage.getItem("userRoleName");
  const [alertContent, setAlertContent] = useState("");
  const [formIsValid, setFormIsValid] = useState(false);
  const [userFormIsValid, setUserFormIsValid] = useState(false);
  const [colorResponseModal, setColorResponseModal] = useState("success");
  const timeZones = ["PST", "CST", "MST", "EST"];
  const [templateFilesFormIsValid, settemplateFilesFormIsValid] =
    useState(false);
  const [open, setOpen] = useState(false);
  const [createdClientID, setCreatedClientID] = useState(null);

  const [clientFormData, setclientFormData] = useState({
    clientName: "",
    ClientDisplayName: "",
  });

  const [templateFiles, setTemplateFiles] = useState([]);

  useEffect(() => {
    if (userRole !== "masteradmin") {
      navigate("/");
    }
  }, [userRole]);

  const [devices, setDevices] = useState([]);
  const [alert, setAlert] = useState(false);
  const [openSubmitModal, setOpenSubmitModal] = useState(false);
  const handleOpenSubmitModal = () => setOpenSubmitModal(true);
  const [loading, setLoading] = useState(false);
  const [confirmPass, setConfrimPass] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);
  const [currentlyFilteringBy, setCurrentlyFilteringBy] = useState("");
  const [numDevices90Days, setNumDevices90Days] = useState();
  const [numDevices30Days, setNumDevices30Days] = useState();
  const [numDevicesOverdue, setNumDevicesOverdue] = useState();
  const [numDevicesViolation, setNumDevicesViolation] = useState();
  const [selectedTableRows, setSelectedTableRows] = useState([]);
  const [selectedRowsContactData, setSelectedRowsContactData] = useState([]);
  const [filterModel, setFilterModel] = useState({ items: [] });
  const [activeItem, setActiveItem] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [toggleMenus, setToggleMenus] = useState(false);
  const [currentFilter, setCurrentFilter] = useState(null);
  const [confimModal, setConfrimModal] = useState(false);
  const handleCloseSubmitModal = () => setOpenSubmitModal(false);
  const [isSuccessUpdated, setisSuccessUpdated] = useState(false);
  const [clientCreated, setClientCreated] = useState(false);
  const [files, setFiles] = useState([]);
  const MAX_TOTAL_SIZE = 20 * 1024 * 1024;
  const [dragActive, setDragActive] = useState(false);
  const [isfileUploaded, setIsFileUploaded] = useState(false);

  const [updateNotification, setUpdateNotification] = useState(false);
  const [responseText, setResponseText] = useState(null);

  const baseURL = process.env.REACT_APP_API_BASE_URL;

  const handleOpen = (text) => {
    setResponseText(text);
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setResponseText(null);

    setOpen(false);
  };

  useEffect(() => {
    const checkTokenExpiry = () => {
      console.log("CHECKING EXPIRES", isTokenExpired(token));
      if (isTokenExpired(token)) {
        console.log("EXPIRES");
        handleExpiredToken();
      } else {
        handleTokenExpiryWarning(token);
      }
    };

    checkTokenExpiry();
    const interval = setInterval(checkTokenExpiry, 60000);

    return () => clearInterval(interval);
  }, [token]);

  useEffect(() => {
    if (clientId && clientList.length > 0) {
      const client = clientList.find((client) => client.ClientID === clientId);

      if (client) {
        localStorage.setItem("clientname", client.ClientName);
      }
    }
  }, [clientId, clientList]);

  const handleMouseUpPassword = (event) => {
    event.preventDefault();
  };
  const handleClickShowPassword = (type = "pass") => {
    type === "confirm"
      ? setShowPasswordConfrm((show) => !show)
      : setShowPassword((show) => !show);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    const removeDuplicates = (arr) => {
      return [...new Set(arr)];
    };

    const companyIDs = removeDuplicates(
      selectedTableRows.map((row) => row.CompanyID)
    ).toString();

    fetch(`${baseURL}/${clientId}/${companyIDs}/getcompanycontactinfobyid`)
      .then((response) => response.json())
      .then((data) => {
        setSelectedRowsContactData(data.data);
      });
  }, [selectedTableRows, clientId]);

  useEffect(() => {
    console.log(selectedRowsContactData, "DATA");
  }, [selectedRowsContactData]);

  useEffect(() => {
    if (
      clientFormData.clientName &&
      clientFormData.clientDisplayName &&
      createdClientID === null
    ) {
      setFormIsValid(true);
    } else {
      setFormIsValid(false);
    }
  }, [
    clientFormData.clientName,
    clientFormData.clientDisplayName,
    createdClientID,
  ]);

  useEffect(() => {
    if (clientId) {
      setUserFormData((prevFormData) => ({
        ...prevFormData,
        ClientID: createdClientID,
      }));
    }
  }, [createdClientID]);

  useEffect(() => {
    console.log(userFormData, "DATATATA");

    const isUserEmailValid =
      userFormData.UserEMail !== "" || !userFormData.isEmailEnabled;
    const isMobilePhoneValid =
      (userFormData.MobilePhone && userFormData.MobilePhone.length === 10) ||
      !userFormData.isPhoneEnabled;

    if (
      userFormData.UserFirstName !== "" &&
      userFormData.UserLastName !== "" &&
      isUserEmailValid &&
      userFormData.Password !== "" &&
      userFormData.Role !== "" &&
      userFormData.UserEMail != "" &&
      confirmPass &&
      isMobilePhoneValid &&
      userFormData.timeZone &&
      createdClientID !== null &&
      isSuccessUpdated === false
    ) {
      setUserFormIsValid(true);
    } else {
      setUserFormIsValid(false);
    }
  }, [userFormData, confirmPass, createdClientID, isSuccessUpdated]);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Submitting client data...");
    setLoading(true);
    fetch(`${baseURL}/clients`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(clientFormData),
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((errorData) => {
            throw new Error(errorData.message || "Failed to create client.");
          });
        }
        return response.json();
      })
      .then((result) => {
        console.log(result);
        setLoading(false);
        setColorResponseModal("success");
        handleOpen("Client created successfully!");
        setCreatedClientID(result.clientId);
      })
      .catch((error) => {
        console.error("Error:", error);
        setLoading(false);
        setColorResponseModal("error");
        handleOpen(error.message || "An unexpected error occurred.");
      });
  };

  const handleAddUser = (e) => {
    e.preventDefault();
    const baseURL = process.env.REACT_APP_API_BASE_URL;
    const token = localStorage.getItem("token");
    setLoading(true);
    fetch(`${baseURL}/users`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userFormData),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then((errorData) => {
            throw new Error(
              `Error ${response.status}: ${
                errorData.message || "Something went wrong"
              }`
            );
          });
        }
      })
      .then(() => {
        setisSuccessUpdated(true);
        setLoading(false);
        setColorResponseModal("success");

        handleOpen("User created successfully!");
      })
      .catch((error) => {
        console.log("Error:", error.message, "hello");
        setLoading(false);
        setColorResponseModal("error");
        setisSuccessUpdated(false);
        handleOpen(`Failed to create user. ${error.message}`);
      });
  };

  const handleFileUpload = (e) => {
    e.preventDefault();
    const baseURL = process.env.REACT_APP_API_BASE_URL;
    const formData = new FormData();

    files.forEach((file) => {
      formData.append("files", file);
    });

    setLoading(true);

    fetch(`${baseURL}/${createdClientID}/upload_files`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then((errorData) => {
            throw new Error(
              `Error ${response.status}: ${
                errorData.message || "Something went wrong"
              }`
            );
          });
        }
      })
      .then((data) => {
        setLoading(false);
        setColorResponseModal("success");
        setClientCreated(true);
        handleOpen("Templates Uploaded successfully!");
        setFiles([]);
      })
      .catch((error) => {
        console.log("Error:", error.message);
        setLoading(false);
        setColorResponseModal("error");
        setClientCreated(false);

        handleOpen("Something went wrong while uploading templates");
        setFiles([]);
      });
  };
  const validateFiles = (newFiles) => {
    const selectedFiles = Array.from(newFiles).filter((file) =>
      /\.(doc|docx)$/i.test(file.name)
    );

    const previousSize = files.reduce((sum, file) => sum + file.size, 0);

    const newFilesSize = selectedFiles.reduce(
      (sum, file) => sum + file.size,
      0
    );

    const totalSize = previousSize + newFilesSize;

    if (totalSize > MAX_TOTAL_SIZE) {
      setColorResponseModal("error");

      handleOpen("The total size of all selected files exceeds 20 MB.");
      return [];
    }

    return selectedFiles;
  };

  const handleMultipleFilesDrag = (e) => {
    if (isSuccessUpdated === true && createdClientID !== null) {
      e.preventDefault();
      e.stopPropagation();
      setDragActive(e.type === "dragenter" || e.type === "dragover");
      settemplateFilesFormIsValid(true);
    }
  };

  const handleMultipleFilesDrop = (e) => {
    if (isSuccessUpdated === true && createdClientID !== null) {
      e.preventDefault();
      e.stopPropagation();
      setDragActive(false);

      if (e.dataTransfer.files) {
        const validFiles = validateFiles(e.dataTransfer.files);
        setFiles((prevFiles) => [...prevFiles, ...validFiles]);
      }
    }
  };

  const handleMultipleFileChange = (event) => {
    const validFiles = validateFiles(event.target.files);
    const selectedFiles = Array.from(validFiles).filter((file) =>
      /\.(doc|docx)$/i.test(file.name)
    );
    console.log(selectedFiles, "FILES");
    setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
    event.target.value = "";
  };

  const handleRemoveFile = (indexToRemove) => {
    setFiles((prevFiles) =>
      prevFiles.filter((_, index) => index !== indexToRemove)
    );
  };

  useEffect(() => {
    if (files.length === 0) {
      settemplateFilesFormIsValid(false);
    } else {
      settemplateFilesFormIsValid(true);
    }
  }, [files]);

  return (
    <>
      <Box>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            {/* Header Section */}
            <Header
              clientId={clientId}
              clientName={clientName}
              handleLogout={handleLogout}
              istoggleMenu={toggleMenus}
              clientNameHeader={clientNameHeader}
              isUpdateNotification={setUpdateNotification}
              updateNotification={updateNotification}
              isClientSelected={isClientSelected}
              onisClientSelected={onisClientSelected}
            />
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Grid container spacing={0}>
          <Grid item xs={12} md={2}>
            {/* Footer Section */}
            <PhoneMenu />
            <SidebarMenu toggleMenu={setToggleMenus} />
          </Grid>
          <Grid
            item
            xs={12}
            md={10}
            className={
              toggleMenus === true ? "tooggleActive mainContent" : "mainContent"
            }
          >
            <Grid item xs={12} className="tabSect">
              <Box
                align="left"
                sx={{
                  mt: 1,
                  "& .MuiTextField-root": { m: 1, width: "30ch" },
                }}
                autoComplete="off"
              >
                <h3 style={{ margin: "40px 0px 7px 7px" }}>Client Details</h3>
                <Box component="form" onSubmit={handleSubmit}>
                  <Grid sx={{ mt: 0 }}>
                    <Grid>
                      <TextField
                        id="outlined-select-currency"
                        style={{ width: 400 }}
                        required
                        value={clientFormData.clientName}
                        disabled={createdClientID !== null}
                        error={
                          !/^[a-zA-Z0-9]*$/.test(clientFormData.clientName) &&
                          clientFormData.clientName !== ""
                        }
                        helperText={
                          !/^[a-zA-Z0-9]*$/.test(clientFormData.clientName) &&
                          clientFormData.clientName !== ""
                            ? "Client Name (cannot have spaces or special characters)"
                            : "Client Name (cannot have spaces or special characters)"
                        }
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (/^[a-zA-Z0-9]*$/.test(inputValue)) {
                            setclientFormData({
                              ...clientFormData,
                              clientName: inputValue,
                            });
                          }
                        }}
                        inputProps={{ maxLength: 47 }}
                      />

                      <TextField
                        id="outlined-select-currency"
                        style={{ width: 310 }}
                        //label="Website"
                        helperText="Client Display Name"
                        value={clientFormData.clientDisplayName}
                        disabled={createdClientID === null ? false : true}
                        onChange={(e) =>
                          setclientFormData({
                            ...clientFormData,
                            clientDisplayName: e.target.value,
                          })
                        }
                      ></TextField>
                    </Grid>
                  </Grid>
                  <Grid>
                    <Box sx={{ display: "inline-flex" }}>
                      <Button
                        disabled={formIsValid ? false : true}
                        type="submit"
                        variant="contained"
                        size="large"
                        color="success"
                        sx={{
                          ml: 1,
                          mr: 6,
                          mt: 2,
                          borderRadius: 8,
                          width: 225,
                        }}
                      >
                        Submit
                      </Button>
                    </Box>
                  </Grid>
                </Box>
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              md={10}
              className={
                toggleMenus === true
                  ? "tooggleActive mainContent"
                  : "mainContent"
              }
            >
              <Container
                style={{ padding: "0px" }}
                maxWidth="xlg"
                sx={{ mt: 8, mb: 4 }}
              >
                <Grid container spacing={10} className="topDueSect">
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    sx={{ textAlign: "right" }}
                    className="beforetable"
                  ></Grid>

                  <Grid item xs={12} className="tabSect">
                    <Box
                      align="left"
                      sx={{
                        mt: 1,
                        "& .MuiTextField-root": { m: 1, width: "30ch" },
                      }}
                      autoComplete="off"
                    >
                      <h3 style={{ margin: "18px 0px 7px 7px" }}>
                        Super Admin Details
                      </h3>
                      <Box component="form" onSubmit={handleAddUser}>
                        <Grid item xs={12} container spacing={2}>
                          <Grid item xs={3}>
                            <TextField
                              error={userFormData?.companyName === ""}
                              id="firstName"
                              style={{ width: "100%" }}
                              required
                              helperText="First Name"
                              value={userFormData?.UserFirstName}
                              disabled={
                                isSuccessUpdated === true ||
                                createdClientID === null
                                  ? true
                                  : false
                              }
                              onChange={(e) =>
                                setUserFormData({
                                  ...userFormData,
                                  UserFirstName: e.target.value,
                                })
                              }
                            ></TextField>
                          </Grid>
                          <Grid item xs={3}>
                            <TextField
                              id="lastName"
                              style={{ width: "100%" }}
                              helperText="Last Name"
                              value={userFormData?.UserLastName}
                              disabled={
                                isSuccessUpdated === true ||
                                createdClientID === null
                                  ? true
                                  : false
                              }
                              onChange={(e) =>
                                setUserFormData({
                                  ...userFormData,
                                  UserLastName: e.target.value,
                                })
                              }
                              required
                            ></TextField>
                          </Grid>
                          <Grid item xs={3}>
                            <TextField
                              id="outlined-select-timezone"
                              select
                              required
                              style={{ width: "100%", marginBottom: 20 }}
                              helperText="Timezone"
                              value={userFormData.timeZone}
                              disabled={
                                isSuccessUpdated === true ||
                                createdClientID === null
                                  ? true
                                  : false
                              }
                              onChange={(e) => {
                                setUserFormData((prevData) => ({
                                  ...prevData,
                                  timeZone: e.target.value,
                                }));
                              }}
                            >
                              <MenuItem value="">None</MenuItem>
                              {timeZones.map((value) => (
                                <MenuItem key={value} value={value}>
                                  {value}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Grid>
                          <Grid style={{ paddingLeft: "34px" }} item xs={3}>
                            <p style={{ paddingBottom: 0, margin: "6px" }}>
                              Notification Method
                            </p>
                            <FormControlLabel
                              style={{ width: 100 }}
                              control={
                                <Checkbox
                                  name="isEmailEnabled"
                                  checked={userFormData.isEmailEnabled}
                                />
                              }
                              onChange={(e) => {
                                setUserFormData((prevData) => ({
                                  ...prevData,
                                  isEmailEnabled: !userFormData.isEmailEnabled,
                                }));
                              }}
                              label="Email"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="isPhoneEnabled"
                                  checked={userFormData.isPhoneEnabled}
                                  disabled={
                                    userFormData.MobilePhone &&
                                    userFormData.MobilePhone.length !== 10
                                  }
                                />
                              }
                              label="Mobile"
                              onChange={(e) => {
                                setUserFormData((prevData) => ({
                                  ...prevData,
                                  isPhoneEnabled: !userFormData.isPhoneEnabled,
                                }));
                              }}
                            />
                          </Grid>
                        </Grid>

                        <Grid item xs={12} container spacing={2}>
                          <Grid item xs={3}>
                            <TextField
                              id="email"
                              style={{ width: "100%" }}
                              //label="Owner"
                              helperText="Email"
                              value={userFormData?.UserEMail}
                              disabled={
                                isSuccessUpdated === true ||
                                createdClientID === null
                                  ? true
                                  : false
                              }
                              onChange={(e) =>
                                setUserFormData({
                                  ...userFormData,
                                  UserEMail: e.target.value,
                                })
                              }
                              required
                            ></TextField>
                          </Grid>

                          <Grid item xs={3}>
                            <InputMask
                              mask="(999) 999-9999"
                              value={userFormData?.MobilePhone}
                              disabled={
                                isSuccessUpdated === true ||
                                createdClientID === null
                                  ? true
                                  : false
                              }
                              maskChar=" "
                              onChange={(e) => {
                                const phoneValue = e.target.value;
                                const digitLength = phoneValue.replace(
                                  /[^0-9]/g,
                                  ""
                                ).length;
                                const numericValue = e.target.value.replace(
                                  /[^0-9]/g,
                                  ""
                                );

                                setUserFormData({
                                  ...userFormData,
                                  MobilePhone: numericValue,
                                });
                              }}
                            >
                              {() => (
                                <TextField
                                  id="phone"
                                  style={{ width: "100%" }}
                                  helperText={
                                    userFormData?.MobilePhone &&
                                    userFormData?.MobilePhone.replace(
                                      /[^0-9]/g,
                                      ""
                                    ).length < 10
                                      ? "Mobile must be at least 10 digits"
                                      : "Mobile"
                                  }
                                  autoComplete="off"
                                  inputProps={{
                                    autoComplete: "off",
                                  }}
                                  value={userFormData?.MobilePhone}
                                />
                              )}
                            </InputMask>
                          </Grid>
                          <Grid item xs={3}>
                            <FormControl
                              sx={{ m: 1, width: "100%" }}
                              variant="outlined"
                            >
                              <InputLabel htmlFor="outlined-adornment-password">
                                Password
                              </InputLabel>
                              <OutlinedInput
                                id="outlined-adornment-passwordConfrim"
                                type={showPassword ? "text" : "password"}
                                disabled={
                                  isSuccessUpdated === true ||
                                  createdClientID === null
                                    ? true
                                    : false
                                }
                                onChange={(e) => {
                                  setPassword(e.target.value);
                                  userFormData.Password !== e.target.value
                                    ? setConfrimPass(false)
                                    : setConfrimPass(true);
                                }}
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowPassword}
                                      onMouseDown={handleMouseDownPassword}
                                      onMouseUp={handleMouseUpPassword}
                                      edge="end"
                                    >
                                      {showPassword ? (
                                        <VisibilityOff />
                                      ) : (
                                        <Visibility />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                }
                                label="Password"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={3}>
                            <FormControl
                              sx={{ m: 1, width: "100%" }}
                              variant="outlined"
                            >
                              <InputLabel htmlFor="outlined-adornment-password">
                                Confrim Password
                              </InputLabel>
                              <OutlinedInput
                                id="outlined-adornment-password"
                                disabled={
                                  isSuccessUpdated === true ||
                                  createdClientID === null
                                    ? true
                                    : false
                                }
                                type={showPasswordConfrm ? "text" : "password"}
                                error={password !== userFormData?.Password}
                                onChange={(e) => {
                                  setUserFormData({
                                    ...userFormData,
                                    Password: e.target.value,
                                  });
                                  password !== e.target.value
                                    ? setConfrimPass(false)
                                    : setConfrimPass(true);
                                }}
                                required
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={(e) =>
                                        handleClickShowPassword("confirm")
                                      }
                                      onMouseDown={handleMouseDownPassword}
                                      onMouseUp={handleMouseUpPassword}
                                      edge="end"
                                    >
                                      {showPasswordConfrm ? (
                                        <VisibilityOff />
                                      ) : (
                                        <Visibility />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                }
                                label="Confrim Password"
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                        <Grid>
                          <Box sx={{ display: "inline-flex" }}>
                            <Button
                              type="submit"
                              variant="contained"
                              disabled={userFormIsValid ? false : true}
                              size="large"
                              color="info"
                              sx={{
                                ml: 1,
                                mr: 6,
                                mt: 2,
                                borderRadius: 8,
                                width: 225,
                              }}
                            >
                              Submit
                            </Button>
                          </Box>
                        </Grid>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Container>
            </Grid>
            <Grid item xs={12} className="tabSect">
              <Box
                align="left"
                sx={{
                  mt: 1,
                  "& .MuiTextField-root": { m: 1, width: "30ch" },
                }}
                autoComplete="off"
              >
                <h3 style={{ margin: "52px 0px 7px 7px" }}>
                  Upload Word Doc Templates Letters
                </h3>
                <Box component="form" onSubmit={handleFileUpload}>
                  <Grid sx={{ mt: 1 }}>
                    <Box
                      component="form"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <Grid container spacing={2} sx={{ mt: 0 }}>
                        <Grid
                          style={{ display: "flex", flexDirection: "column" }}
                          container
                        >
                          <div
                            className="file-upload-container"
                            style={{ marginLeft: "1rem" }}
                          >
                            <div>
                              <div
                                className={`file-upload-area ${
                                  dragActive ? "drag-active" : ""
                                }`}
                                onDragEnter={handleMultipleFilesDrag}
                                onDragLeave={handleMultipleFilesDrop}
                                onDragOver={handleMultipleFilesDrag}
                                onDrop={handleMultipleFilesDrop}
                              >
                                {/* Hidden File Input */}
                                <input
                                  type="file"
                                  id="file"
                                  className="file-input"
                                  onChange={handleMultipleFileChange}
                                  accept=".doc,.docx"
                                  disabled={
                                    isSuccessUpdated === true &&
                                    createdClientID !== null
                                      ? false
                                      : true
                                  }
                                  multiple
                                  style={{
                                    opacity: 0,
                                    position: "absolute",
                                    pointerEvents: "none",
                                  }}
                                />

                                <div className="upload-content">
                                  <div className="icon-container">
                                    {isfileUploaded ? (
                                      <Paperclip className="cloud-icon" />
                                    ) : (
                                      <img
                                        style={{
                                          mixBlendMode: "multiply",
                                          width: "90px",
                                          height: "95px",
                                        }}
                                        src={word}
                                        className="cloud-icon"
                                      />
                                    )}
                                  </div>

                                  <div className="text-container">
                                    <p className="primary-text">
                                      <p
                                        style={{
                                          fontSize: "18px",
                                          margin: "0px",
                                        }}
                                      >
                                        Drag & Drop or{" "}
                                        <span
                                          style={{
                                            color: "#5abfec",
                                            cursor: "pointer",
                                          }}
                                          onClick={() =>
                                            document
                                              .getElementById("file")
                                              .click()
                                          }
                                        >
                                          <strong>Browse</strong>
                                        </span>
                                      </p>
                                      <br />
                                      <p>Supports: Word files only</p>
                                    </p>
                                  </div>
                                  <Box>
                                    {files.map((file, index) => (
                                      <Box
                                        key={file.name}
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "space-between",
                                          backgroundColor:
                                            "rgba(90, 191, 236, 0.1)",

                                          padding: "0px 6px 0px 18px",
                                          marginBottom: "10px",
                                          transition: "background-color 0.2s",
                                          "&:hover": {
                                            backgroundColor:
                                              "rgba(90, 191, 236, 0.2)",
                                          },
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: 2,
                                          }}
                                        >
                                          <Box>
                                            <Typography variant="subtitle1">
                                              {file.name}
                                            </Typography>
                                          </Box>
                                        </Box>
                                        <IconButton
                                          onClick={() =>
                                            handleRemoveFile(index)
                                          }
                                          sx={{
                                            color: "error.main",
                                            "&:hover": {
                                              backgroundColor:
                                                "rgba(255, 0, 0, 0.1)",
                                            },
                                          }}
                                        >
                                          <X size={20} />
                                        </IconButton>
                                      </Box>
                                    ))}
                                  </Box>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    </Box>
                    <Grid>
                      <Box sx={{ display: "inline-flex" }}>
                        <Button
                          disabled={templateFilesFormIsValid ? false : true}
                          type="submit"
                          variant="contained"
                          size="large"
                          color="success"
                          sx={{
                            ml: 0,
                            mr: 6,
                            mt: 1,
                            borderRadius: 8,
                            width: 225,
                          }}
                        >
                          Submit
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Snackbar
            open={open}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            autoHideDuration={6000}
            onClose={handleClose}
          >
            <Alert
              onClose={handleClose}
              severity={colorResponseModal}
              variant="filled"
              sx={{ width: "100%" }}
            >
              {responseText}
            </Alert>
          </Snackbar>
          <LoadingModal
            openStatus={loading}
            loadingText="Processing your request, please wait..."
          />
        </Grid>
      </Box>
      <Footer />
    </>
  );
}
