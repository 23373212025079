import React, { useState, useEffect, useRef } from "react";
import { createTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {
  Box,
  Button,
  Snackbar,
  Alert,
  Grid,
  Paper,
  Typography,
  IconButton,
} from "@mui/material";
import pdf from "../components/Common/assets/imgs/multiple-pdf.gif";
import excel2 from "../components/Common/assets/imgs/excel-icon.svg";
import fileFormatImage from "../assets/images/fileformat_r2.png";

import { Download, Loader, Trash, Paperclip, FileText, X } from "lucide-react";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import * as XLSX from "xlsx";

import Header from "./Common/Header";
import Footer from "./Common/Footer";
import PhoneMenu from "./Common/PhoneMenu";

import LoadingModal from "./Common/LoadingModal";
import SidebarMenu from "./Common/SidebarMenu";

import { useSelector } from "react-redux";

const defaultTheme = createTheme();

export default function UploadData({
  removeItems,
  handleLogout,
  clientName,
  clientNameHeader,
  isClientSelected,
  onisClientSelected,
}) {
  const clientId = useSelector((state) => state.userID.value);

  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const userRole = localStorage.getItem("userRoleName");
  const [formIsValid, setFormIsValid] = useState(false);
  const [isPreview, setIsPreview] = useState(false);
  const [isfileUploaded, setIsFileUploaded] = useState(false);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const source = params.get("for");

  const baseURL = process.env.REACT_APP_API_BASE_URL;

  const [dragActive, setDragActive] = useState(false);
  const [fileName, setFileName] = useState("");
  const [downloading, setDownloading] = useState(false);
  const [error, setError] = useState("");
  const [toggleMenus, setToggleMenus] = useState(false);
  const [updateNotification, setUpdateNotification] = useState(false);
  const [sheetData, setSheetData] = useState([]);

  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [responseText, setResponseText] = useState("");
  const [colorResponseModal, setColorResponseModal] = useState("success");
  const [uploadComplete, setUploadComplete] = useState(false);
  const [testReports, setTestReports] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [files, setFiles] = useState([]);
  const [processedFiles, setProcessedFiles] = useState([]);
  const MAX_TOTAL_SIZE = 20 * 1024 * 1024;

  const currentSheet = sheetData[currentIndex];
  useEffect(() => {
    if (userRole !== "superadmin") {
      navigate("/");
    }
  }, [userRole]);

  useEffect(() => {
    const hasDevices = localStorage.getItem("HasDevices");
    console.log(hasDevices, "hasDevices");
    if (hasDevices === "true" && source !== "excel") {
      setUploadComplete(true);
    }
  }, []);

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    const file = e.dataTransfer.files[0];
    if (file) {
      setUploading(true);
      readExcel(file);
      await new Promise((resolve) => setTimeout(resolve, 3000));
      setUploading(false);
      setFileName(file.name);

      setIsPreview(true);
      setIsFileUploaded(true);
    }
  };

  const handleChange = async (file) => {
    console.log("HERE");

    if (file && file.name.endsWith(".xlsx")) {
      setUploading(true);
      readExcel(file);
      await new Promise((resolve) => setTimeout(resolve, 3000));
      setUploading(false);
      setFileName(file.name);

      setIsPreview(true);
      setIsFileUploaded(true);
    } else {
      alert("Please upload a valid Excel file.");
    }
  };

  const readExcel = (file) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: "array" });

      const sheets = workbook.SheetNames;
      const dataArr = [];

      sheets.forEach((sheetName, index) => {
        const worksheet = workbook.Sheets[sheetName];

        const sheet = XLSX.utils.sheet_to_json(worksheet, {
          raw: false,
          defval: "",
          blankrows: false,
          header: 1,
        });

        if (sheet.length > 0) {
          const headers = sheet[0].filter(
            (header) =>
              header && !header.includes("EMPTY") && header.trim() !== ""
          );

          const rows = sheet.slice(1).map((row) => {
            const obj = {};
            headers.forEach((header, idx) => {
              if (row[idx] !== undefined && row[idx] !== "") {
                obj[header] = row[idx];
              }
            });
            return obj;
          });

          const filteredRows = rows.filter((row) =>
            Object.values(row).some(
              (value) => value !== undefined && value !== null && value !== ""
            )
          );

          if (filteredRows.length > 0) {
            dataArr.push({
              sheetName,
              data: filteredRows,
            });
          }
        }
      });

      setSheetData(dataArr);
    };
    reader.readAsArrayBuffer(file);
  };
  const handleSubmitExcel = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await fetch(`${baseURL}/${clientId}/upload_excel`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(sheetData),
      });

      const data = await response.json();

      if (response.ok) {
        setColorResponseModal("success");
        setResponseText("Excel file uploaded successfully!");
        setUploadComplete(true);
        setIsFileUploaded(false);
        localStorage.setItem("HasDevices", true);
        setFileName("");
        setSheetData([]);
      } else {
        setColorResponseModal("error");
        setResponseText(data.message || "Error uploading file");
      }
    } catch (error) {
      setColorResponseModal("error");
      setResponseText("Error uploading file: " + error.message);
    } finally {
      setLoading(false);
      setSnackbarOpen(true);
    }
  };
  const handleDownload = async () => {
    try {
      setDownloading(true);
      setError("");

      const response = await fetch(`${baseURL}/download_excel_template`, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error("Download failed");
      }

      const blob = await response.blob();

      const url = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;

      link.download = "Flo-Trace data template.xlsx";

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      window.URL.revokeObjectURL(url);
    } catch (err) {
      setError("Failed to download file. Please try again.");
    } finally {
      setDownloading(false);
    }
  };
  const validateFiles = (newFiles) => {
    const pdfFiles = Array.from(newFiles).filter(
      (file) => file.type === "application/pdf"
    );

    const previousSize = files.reduce((sum, file) => sum + file.size, 0);

    const newFilesSize = pdfFiles.reduce((sum, file) => sum + file.size, 0);

    const totalSize = previousSize + newFilesSize;

    if (totalSize > MAX_TOTAL_SIZE) {
      setColorResponseModal("error");

      setResponseText("The total size of all selected files exceeds 20 MB.");

      setSnackbarOpen(true);
      return [];
    }

    return pdfFiles;
  };

  const handleMultipleFilesDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(e.type === "dragenter" || e.type === "dragover");
  };

  const handleMultipleFilesDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    if (e.dataTransfer.files) {
      const validFiles = validateFiles(e.dataTransfer.files);
      setFiles((prevFiles) => [...prevFiles, ...validFiles]);
    }
  };

  const handleMultipleFileChange = (event) => {
    const validFiles = validateFiles(event.target.files);
    const selectedFiles = Array.from(validFiles).filter(
      (file) => file.type === "application/pdf"
    );
    setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
    event.target.value = "";
  };

  const handleRemoveFile = (indexToRemove) => {
    setFiles((prevFiles) =>
      prevFiles.filter((_, index) => index !== indexToRemove)
    );
  };

  const handleSubmitMultipleFileChange = async (event) => {
    event.preventDefault();
    setLoading(true);
    const clientNameFromLocalStorage = localStorage.getItem("clientname");
    const formData = new FormData();
    files.forEach((file, index) => {
      formData.append(`file${index}`, file);
    });

    try {
      const response = await fetch(
        `${baseURL}/${clientId}/${clientNameFromLocalStorage}/batch-process-files`,
        {
          method: "POST",
          body: formData,
        }
      );

      const result = await response.json();
      console.log("Upload results:", result);

      if (result.success) {
        const successfulFiles = result.processed_files.filter((f) => f.success);
        const noSerialNumberFiles = result.processed_files.filter(
          (f) =>
            f.error && f.error.includes("No serial number found in filename")
        );
        const deviceNotFoundFiles = result.processed_files.filter(
          (f) =>
            f.error && f.error.includes("No device found for serial number")
        );

        console.log("Successful files:", successfulFiles);
        console.log("Files with no serial number:", noSerialNumberFiles);
        console.log(
          "Files with serial number but device not found:",
          deviceNotFoundFiles
        );
        setProcessedFiles({
          success: successfulFiles.map((f) => ({
            filename: f.filename,
            error: null,
          })),
          no_sn: noSerialNumberFiles.map((f) => ({
            filename: f.filename,
            error: f.error,
          })),
          invalid_sn: deviceNotFoundFiles.map((f) => ({
            filename: f.filename,
            error: f.error,
          })),
        });
        setFiles([]);
        setColorResponseModal("success");
        setResponseText("Test Reports uploaded successfully!");
      }
    } catch (error) {
      console.error("Error uploading files:", error);
      setColorResponseModal("error");
      setResponseText("Error uploading file: " + error);
    } finally {
      setLoading(false);
      setSnackbarOpen(true);
    }

    event.target.value = "";
  };

  return (
    <>
      <Box>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Header
              clientId={clientId}
              clientName={clientName}
              handleLogout={handleLogout}
              istoggleMenu={toggleMenus}
              clientNameHeader={clientNameHeader}
              isUpdateNotification={setUpdateNotification}
              updateNotification={updateNotification}
              isClientSelected={isClientSelected}
              onisClientSelected={onisClientSelected}
              uploadComplete={uploadComplete}
            />
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Grid container spacing={0}>
          <Grid item xs={12} md={2}>
            <PhoneMenu />
            <SidebarMenu
              toggleMenu={setToggleMenus}
              defaults={source === "excel" ? true : false}
            />
          </Grid>
          <Grid item xs={12} md={10} className={"tooggleActive mainContent"}>
            <Grid item xs={12} className="tabSect">
              {!uploadComplete ? (
                <Box
                  align="left"
                  style={{ marginLeft: "8rem" }}
                  sx={{
                    mt: 1,
                    "& .MuiTextField-root": { m: 1, width: "30ch" },
                  }}
                  autoComplete="off"
                >
                  <h3 style={{ margin: "40px 0px 7px 7px" }}>
                    Upload Excel Template.
                    <br />
                    <span
                      style={{
                        fontSize: "14px",
                        fontWeight: "500",
                        color: "red",
                      }}
                    >
                      (Please verify all device & contact information is
                      correct. Single upload process only.)
                    </span>
                  </h3>
                  <Box
                    component="form"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Grid
                      container
                      sx={{ mt: 0 }}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Grid item xs={8} md={6}>
                        <div className="file-upload-container">
                          <div
                            className={`file-upload-area ${
                              dragActive ? "drag-active" : ""
                            }`}
                            onDragEnter={handleDrag}
                            onDragLeave={handleDrag}
                            onDragOver={handleDrag}
                            onDrop={handleDrop}
                          >
                            {/* Hidden File Input */}
                            <input
                              type="file"
                              id="file"
                              className="file-input"
                              onChange={(e) => handleChange(e.target.files[0])}
                              accept=".xlsx"
                              style={{
                                opacity: 0,
                                position: "absolute",
                                pointerEvents: "none",
                              }}
                            />

                            <div className="upload-content">
                              <div className="icon-container">
                                {isfileUploaded ? (
                                  <Paperclip className="cloud-icon" />
                                ) : (
                                  <img src={excel2} className="cloud-icon" />
                                )}
                              </div>

                              <div className="text-container">
                                <p className="primary-text">
                                  {fileName ? (
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "10px",
                                      }}
                                    >
                                      {fileName}

                                      <Trash
                                        style={{
                                          width: "25px",
                                          cursor: "pointer",
                                        }}
                                        className="cloud-icon"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          setFileName("");
                                          setIsPreview(false);
                                          setIsFileUploaded(false);
                                          setSheetData([]);
                                          document.getElementById(
                                            "file"
                                          ).value = "";
                                        }}
                                      />
                                    </div>
                                  ) : uploading ? (
                                    <span>Uploading...</span>
                                  ) : (
                                    <>
                                      {/* Custom File Selection Trigger */}
                                      <p style={{ fontSize: "18px" }}>
                                        Drag & Drop or{" "}
                                        <span
                                          style={{
                                            color: "#5abfec",
                                            cursor: "pointer",
                                          }}
                                          onClick={() =>
                                            document
                                              .getElementById("file")
                                              .click()
                                          }
                                        >
                                          <strong>Browse</strong>
                                        </span>
                                      </p>
                                      <br />
                                      <p>
                                        Supports: Excel file only (1 excel file
                                        containing 9 sheets)
                                      </p>
                                    </>
                                  )}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>

                        {isfileUploaded ? (
                          <div className="download-container">
                            <button
                              onClick={(e) => handleSubmitExcel(e)}
                              className="download-button"
                              style={{
                                margin: "0px 10px",
                                background: "green",
                                width: "90px",
                              }}
                            >
                              Submit
                            </button>
                          </div>
                        ) : (
                          ""
                        )}
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        md={2}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginRight: "10rem",
                        }}
                      >
                        <div className="file-upload-container">
                          <button
                            onClick={handleDownload}
                            disabled={downloading}
                            className="download-button"
                            style={{ width: "390px" }}
                          >
                            {downloading ? (
                              <>
                                <Loader className="download-icon spinner" />
                                <span className="button-text">
                                  Downloading...
                                </span>
                              </>
                            ) : (
                              <>
                                <Download className="download-icon" />
                                <span className="button-text">
                                  Download Template
                                </span>
                              </>
                            )}
                          </button>

                          {error ? (
                            <div className="error-message">{error}</div>
                          ) : (
                            <div
                              style={{ fontSize: "12px", marginTop: "0.3rem" }}
                            >
                              Download Excel Template with all sheets and sample
                              record added. DO NOT DELETE any sheets prior to
                              upload.
                            </div>
                          )}
                        </div>
                      </Grid>
                    </Grid>
                    {isPreview && sheetData.length > 0 ? (
                      <Box
                        style={{
                          width: "100rem",
                          marginTop: "2rem",
                          marginLeft: "0rem",
                          display: "flex",
                          flexDirection: "column-reverse",
                        }}
                      >
                        <div className="flex items-center justify-center w-full gap-4">
                          <h4 className="text-xl font-semibold mb-4">
                            {currentSheet?.sheetName}
                          </h4>
                          <DataGridPremium
                            className="dashboardTable"
                            pageSizeOptions={[5, 10, 25, 100]}
                            initialState={{
                              pagination: {
                                paginationModel: { pageSize: 10 },
                              },
                            }}
                            rows={currentSheet?.data
                              .filter((row) => {
                                return Object.values(row).some(
                                  (value) =>
                                    value !== undefined &&
                                    value !== null &&
                                    value !== ""
                                );
                              })
                              .map((row, index) => ({
                                ...row,
                                id: index,
                              }))}
                            columns={Object.keys(currentSheet?.data[0] || {})
                              .filter((col) => !col.includes("EMPTY"))
                              .map((col) => ({
                                field: col,
                                headerName: col.replace(/_EMPTY.*$/, "").trim(),
                                flex: 1,
                              }))}
                            pageSizeOptions={[5, 10, 25]}
                            pagination
                            density="compact"
                            autoHeight
                          />
                        </div>

                        <Box sx={{ mt: 3 }}>
                          {sheetData.map((item, idx) => (
                            <Button
                              variant="contained"
                              sx={{
                                marginLeft: "4px",
                                marginRight: "0px",
                                padding: "4px 16px",
                                background: "#9fb7c1",
                                textTransform: "none",
                                fontSize: "1rem",
                                boxShadow: "none",
                                borderRadius: "0px",
                              }}
                              key={idx}
                              onClick={(e) => {
                                e.preventDefault();
                                setCurrentIndex(idx);
                              }}
                              className={`w-2 h-2  transition-colors ${
                                idx === currentIndex
                                  ? "bg-blue-600"
                                  : "bg-gray-300"
                              }`}
                              aria-label={`Go to table ${idx + 1}`}
                            >
                              {item?.sheetName}
                            </Button>
                          ))}
                        </Box>
                      </Box>
                    ) : (
                      ""
                    )}
                    <Grid
                      style={{
                        marginTop: "5rem",
                        marginBottom: "2rem",
                        width: "100%",
                        display: "flex",
                        justifyContent: "start",
                      }}
                    >
                      <Box></Box>
                    </Grid>
                  </Box>
                </Box>
              ) : (
                <Box
                  align="left"
                  style={{ marginLeft: "15rem" }}
                  autoComplete="off"
                >
                  <h3 style={{ margin: "40px 0px 7px 7px" }}>
                    Upload Test Reports
                    <br />
                    <span
                      style={{
                        fontSize: "14px",
                        fontWeight: "500",
                        color: "red",
                      }}
                    >
                      (ONLY Test reports with a matched serial number in
                      filename to the associated device will be added and
                      automatically linked.)
                    </span>
                  </h3>
                  <Box
                    component="form"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Grid container spacing={2} sx={{ mt: 0 }}>
                      <Grid
                        style={{ display: "flex", flexDirection: "column" }}
                        container
                      >
                        <div className="file-upload-container">
                          <div>
                            <div
                              className={`file-upload-area ${
                                dragActive ? "drag-active" : ""
                              }`}
                              onDragEnter={handleMultipleFilesDrag}
                              onDragLeave={handleMultipleFilesDrop}
                              onDragOver={handleMultipleFilesDrag}
                              onDrop={handleMultipleFilesDrop}
                            >
                              {/* Hidden File Input */}
                              <input
                                type="file"
                                id="file"
                                className="file-input"
                                onChange={handleMultipleFileChange}
                                accept=".pdf"
                                multiple
                                style={{
                                  opacity: 0,
                                  position: "absolute",
                                  pointerEvents: "none",
                                }}
                              />

                              <div
                                className="upload-content"
                                style={{ width: "100%" }}
                              >
                                <div className="icon-container">
                                  {isfileUploaded ? (
                                    <Paperclip className="cloud-icon" />
                                  ) : (
                                    <img
                                      style={{
                                        mixBlendMode: "multiply",
                                        width: "56px",
                                        height: "64px",
                                      }}
                                      src={pdf}
                                      className="cloud-icon"
                                    />
                                  )}
                                </div>

                                <div className="text-container">
                                  <p className="primary-text">
                                    <p
                                      style={{
                                        fontSize: "18px",
                                        margin: "0px",
                                      }}
                                    >
                                      Drag & Drop or{" "}
                                      <span
                                        style={{
                                          color: "#5abfec",
                                          cursor: "pointer",
                                        }}
                                        onClick={() =>
                                          document
                                            .getElementById("file")
                                            .click()
                                        }
                                      >
                                        <strong>Browse</strong>
                                      </span>
                                    </p>
                                    <br />
                                    <p>
                                      Supports: PDF files only (Maximum upload
                                      20Mb or approx 100 reports)
                                    </p>
                                  </p>
                                </div>
                                <Box style={{ width: "100%" }}>
                                  {files.length > 0 ? (
                                    files.map((file, index) => (
                                      <Box
                                        key={file.name}
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "space-between",
                                          backgroundColor:
                                            "rgba(90, 191, 236, 0.1)",
                                          padding: "0px 6px 0px 18px",
                                          marginBottom: "10px",
                                          transition: "background-color 0.2s",
                                          "&:hover": {
                                            backgroundColor:
                                              "rgba(90, 191, 236, 0.2)",
                                          },
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: 2,
                                          }}
                                        >
                                          <Box>
                                            <Typography variant="subtitle1">
                                              {file.name}
                                            </Typography>
                                          </Box>
                                        </Box>
                                        <IconButton
                                          onClick={() =>
                                            handleRemoveFile(index)
                                          }
                                          sx={{
                                            color: "error.main",
                                            "&:hover": {
                                              backgroundColor:
                                                "rgba(255, 0, 0, 0.1)",
                                            },
                                          }}
                                        >
                                          <X size={20} />
                                        </IconButton>
                                      </Box>
                                    ))
                                  ) : (
                                    <>
                                      {processedFiles?.success?.length > 0 && (
                                        <Box
                                          sx={{
                                            marginBottom: "0px",
                                            padding: "10px",
                                            borderRadius: "8px",
                                            display: "flex",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            textAlign: "left",
                                          }}
                                        >
                                          <Typography
                                            variant="h6"
                                            sx={{
                                              color: "green",
                                              marginBottom: "10px",
                                            }}
                                          >
                                            Correctly linked reports to devices
                                          </Typography>
                                          {processedFiles.success.map(
                                            (file, index) => (
                                              <Box
                                                key={file.filename}
                                                sx={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                  justifyContent:
                                                    "space-between",
                                                  backgroundColor:
                                                    "rgba(0, 255, 0, 0.1)",
                                                  padding: "0px 6px 0px 18px",
                                                  marginBottom: "10px",
                                                  transition:
                                                    "background-color 0.2s",
                                                  "&:hover": {
                                                    backgroundColor:
                                                      "rgba(0, 255, 0, 0.1)",
                                                  },
                                                }}
                                              >
                                                <Box
                                                  sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    gap: 2,
                                                  }}
                                                >
                                                  <Box>
                                                    <Typography variant="subtitle1">
                                                      {file.filename}
                                                    </Typography>
                                                  </Box>
                                                </Box>
                                              </Box>
                                            )
                                          )}
                                        </Box>
                                      )}

                                      {/* Section for files with no serial number */}
                                      {processedFiles.no_sn?.length > 0 && (
                                        <Box
                                          sx={{
                                            marginBottom: "0px",
                                            padding: "10px",
                                            borderRadius: "8px",
                                            display: "flex",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            textAlign: "left",
                                          }}
                                        >
                                          <Typography
                                            variant="h6"
                                            sx={{
                                              color: "gray",
                                              marginBottom: "10px",
                                            }}
                                          >
                                            Invalid Filename format
                                          </Typography>
                                          {processedFiles.no_sn.map(
                                            (file, index) => (
                                              <Box
                                                key={file.filename}
                                                sx={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                  justifyContent:
                                                    "space-between",
                                                  backgroundColor:
                                                    "rgba(128, 128, 128, 0.1)",
                                                  padding: "0px 6px 0px 18px",
                                                  marginBottom: "10px",
                                                  transition:
                                                    "background-color 0.2s",
                                                  "&:hover": {
                                                    backgroundColor:
                                                      "rgba(128, 128, 128, 0.1)",
                                                  },
                                                }}
                                              >
                                                <Box
                                                  sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    gap: 2,
                                                  }}
                                                >
                                                  <Box>
                                                    <Typography variant="subtitle1">
                                                      {file.filename}
                                                    </Typography>
                                                  </Box>
                                                </Box>
                                              </Box>
                                            )
                                          )}
                                        </Box>
                                      )}

                                      {processedFiles.invalid_sn?.length >
                                        0 && (
                                        <Box
                                          sx={{
                                            marginBottom: "0px",
                                            padding: "10px",
                                            borderRadius: "8px",
                                            display: "flex",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            textAlign: "left",
                                          }}
                                        >
                                          <Typography
                                            variant="h6"
                                            sx={{
                                              color: "red",
                                              marginBottom: "10px",
                                            }}
                                          >
                                            Missing Device to match Test Report
                                            <br />
                                            (able to add/edit remove devices in
                                            the next step)
                                          </Typography>

                                          {processedFiles.invalid_sn.map(
                                            (file, index) => (
                                              <Box
                                                key={file.filename}
                                                sx={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                  justifyContent:
                                                    "space-between",
                                                  backgroundColor:
                                                    "rgba(255, 0, 0, 0.1)",
                                                  padding: "0px 6px 0px 18px",
                                                  marginBottom: "10px",
                                                  transition:
                                                    "background-color 0.2s",
                                                  "&:hover": {
                                                    backgroundColor:
                                                      "rgba(255, 0, 0, 0.1)",
                                                  },
                                                }}
                                              >
                                                <Box
                                                  sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    gap: 2,
                                                  }}
                                                >
                                                  <Box>
                                                    <Typography variant="subtitle1">
                                                      {file.filename}
                                                    </Typography>
                                                  </Box>
                                                </Box>
                                              </Box>
                                            )
                                          )}
                                        </Box>
                                      )}
                                    </>
                                  )}
                                </Box>
                              </div>
                            </div>
                            {files.length > 0 ? (
                              <div
                                className="download-container"
                                style={{
                                  display: "flex",
                                  justifyContent: "end",
                                }}
                              >
                                <button
                                  onClick={(e) =>
                                    handleSubmitMultipleFileChange(e)
                                  }
                                  className="download-button"
                                  style={{
                                    margin: "20px 5px",
                                    background: "green",
                                    width: "max-content",
                                  }}
                                >
                                  Upload ({files.length})
                                </button>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          <div
                            style={{
                              background: "transparent",
                              height: "88%",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <img
                              style={{
                                mixBlendMode: "multiply",
                                width: "477px",
                                height: "437px",
                              }}
                              src={fileFormatImage}
                              className="cloud-icon"
                            />
                          </div>
                        </div>
                      </Grid>
                    </Grid>

                    <Grid
                      style={{ marginTop: "5rem", marginBottom: "2rem" }}
                    ></Grid>
                  </Box>
                </Box>
              )}
            </Grid>
          </Grid>
        </Grid>
        <LoadingModal
          openStatus={loading}
          loadingText="Processing your request, please wait..."
        />

        <Snackbar
          open={snackbarOpen}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={colorResponseModal}
            variant="filled"
            sx={{ width: "100%" }}
          >
            {responseText}
          </Alert>
        </Snackbar>
      </Box>
      <Footer />
    </>
  );
}
