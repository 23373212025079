import React, { useState, useEffect, useRef } from "react";
import { TextField, Button } from "@mui/material";
import moment from "moment/moment";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import UploadTest from "../UploadTest";
import axios from "axios";
import AddTestModal from "../AddTestModal";
import { Link } from "react-router-dom";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import PhoneMenu from "../Common/PhoneMenu";
import SidebarMenu from "../Common/SidebarMenu";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import { useSelector } from "react-redux";
import { Tooltip, IconButton, Popover } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import tooltipImage from "../../assets/images/tooltip.jpeg";

import {
  isTokenExpired,
  handleExpiredToken,
  handleTokenExpiryWarning,
} from "../Common/CheckJwtExpires";

const AddTest = ({ clientName, handleLogout, clientNameHeader }) => {
  const clientId = useSelector((state) => state.userID.value);

  // const [open, setOpen] = useState(true);
  const [companiesData, setCompaniesData] = useState([]);
  const [techniciansData, setTechniciansData] = useState([]);
  const [companyDevicesData, setCompanyDevicesData] = useState([]);
  const [testFiles, setTestFiles] = useState([]);
  const [uploadedFileName, setUploadedFileName] = useState("");
  const [toggleMenus, setToggleMenus] = useState(false);
  const [loginCount, setLoginCount] = useState(0);
  const [anchorEls, setAnchorEls] = useState({
    CompanyName: false,
    DeviceName: false,
    technicianName: false,
  });

  const [formData, setFormData] = useState({
    deviceLocation: "",
    deviceID: "",
    serialNumber: "",
    companyName: "",
    actualTestDate: "",
    gaugeCalibrationDate: "",
    testResult: "",
    technician: "",
    technicianID: "",
  });
  const [deviceSerialNumbers, setDeviceSerialNumbers] = useState([]);
  const [deviceID, setDeviceID] = useState([]);
  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState("");
  const [openSubmitModal, setOpenSubmitModal] = useState(false);
  const [deviceScheduledTestDate, setDeviceScheduledTestDate] = useState("");

  const [selectedDeviceID, setSelectedDeviceID] = useState();
  const handleSelectedIDChange = (id) => setSelectedDeviceID(id);
  //const handleCloseSubmitModal = () => setOpenSubmitModal(false);

  const handleOpenSubmitModal = () => setOpenSubmitModal(true);
  const handleCloseSubmitModal = () => setOpenSubmitModal(false);
  const [formIsValid, setFormIsValid] = useState(false);

  const [noDeviceFoundModal, setnoDeviceFoundModal] = useState(false);
  const handleNoDeviceFoundModalOpen = () => setnoDeviceFoundModal(true);
  const handleNoDeviceFoundModalClose = () => setnoDeviceFoundModal(false);
  const token = localStorage.getItem("token");

  const tooltipRefs = {
    CompanyName: useRef(null),
    DeviceName: useRef(null),
    technicianName: useRef(null),
  };

  useEffect(() => {
    const checkTokenExpiry = () => {
      if (isTokenExpired(token)) {
        handleExpiredToken();
      } else {
        handleTokenExpiryWarning(token);
      }
    };

    checkTokenExpiry();
    const interval = setInterval(checkTokenExpiry, 60000);

    return () => clearInterval(interval);
  }, [token]);

  const handleClick = (event, field) => {
    console.log(event.currentTarget);
    setAnchorEls({ ...anchorEls, [field]: !anchorEls[field] });
  };

  const handleClickOutside = (e) => {
    // Close the tooltips only if the click is outside the tooltip trigger and content
    if (
      !tooltipRefs["CompanyName"].current.contains(e.target) &&
      !tooltipRefs["DeviceName"].current.contains(e.target) &&
      !tooltipRefs["technicianName"].current.contains(e.target)
    ) {
      setAnchorEls({
        CompanyName: false,
        DeviceName: false,
        technicianName: false,
      });
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (
      formData.deviceID &&
      formData.companyName &&
      formData.actualTestDate &&
      formData.gaugeCalibrationDate &&
      formData.testResult &&
      formData.technician &&
      formData.technicianID
    ) {
      setFormIsValid(true);
    } else {
      setFormIsValid(false);
    }
  }, [
    formData.deviceID,
    formData.companyName,
    formData.actualTestDate,
    formData.gaugeCalibrationDate,
    formData.testResult,
    formData.technician,
    formData.technicianID,
  ]);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const uploadFormData = new FormData();
    uploadFormData.append("file", file);

    const filename = file.name;
    setUploadedFileName(filename);
    setTestFiles(uploadFormData);
  };

  const dynamicSort = (property) => {
    return function (a, b) {
      return a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    };
  };

  const baseURL = process.env.REACT_APP_API_BASE_URL;
  // GET DEVICES
  useEffect(() => {
    fetch(
      `${baseURL}/${clientId}/${formData.companyName.replace(
        / /g,
        "%20"
      )}/getcompanydevices`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.data.length > 0) {
          setCompanyDevicesData(data.data.sort(dynamicSort("Location")));
          console.log("Company devices data", data.data);
        } else {
          handleNoDeviceFoundModalOpen();
        }
      });
  }, [formData.companyName, clientId]);

  // GET COMPANY NAMES
  useEffect(() => {
    fetch(`${baseURL}/${clientId}/getcompanynames`)
      .then((response) => response.json())
      .then((data) => {
        setCompaniesData(data.data.sort(dynamicSort("CompanyName")));
      });
  }, [clientId]);

  // GET TECHNICNANS
  useEffect(() => {
    fetch(`${baseURL}/${clientId}/gettechnicians`)
      .then((response) => response.json())
      .then((data) => {
        setTechniciansData(data.data.sort(dynamicSort("Technician")));
      });
  }, [clientId]);

  // GET TECHNICNANS
  // useEffect(() => {

  //   if (formData.technician !== "") {
  //     let filteredTechData = techniciansData.filter((row) => row.Technician==formData.technician);

  //     setFormData({...formData, technicianID: filteredTechData[0].TechID})

  //     console.log("FORM DATA", formData)
  //   } else {

  //   }

  // }, [formData.technician]);

  // GET DEVICE SERIAL NUMBER
  // useEffect(() => {
  //   fetch(`https://flo-trace-api-bmjve.ondigitalocean.app/${clientId}/${formData.companyName.replace(/ /g, '%20')}/${formData.deviceLocation.replace(/ /g, '%20')}/identifydeviceserialnumber`)
  //   .then(response => response.json())
  //   .then(data => {
  //       setDeviceSerialNumbers(data.data)
  //   })
  //   },[formData.deviceLocation]);

  // // GET DEVICE SERIAL NUMBER
  // useEffect(() => {
  //   fetch(`https://flo-trace-api-bmjve.ondigitalocean.app/${clientId}/${formData.companyName.replace(/ /g, '%20')}/${formData.deviceID}/identifydeviceserialnumber2`)
  //   .then(response => response.json())
  //   .then(data => {
  //       setDeviceSerialNumbers(data.data)
  //   })
  //   },[formData.deviceLocation]);

  // GET DEVICE ID FOR SINGLE DEVICE
  // useEffect(() => {
  //   fetch(`https://flo-trace-api-bmjve.ondigitalocean.app/${clientId}/${formData.companyName.replace(/ /g, '%20')}/${formData.deviceLocation.replace(/ /g, '%20')}/${formData.serialNumber.replace(/ /g, '%20')}/identifydeviceid`)
  //   .then(response => response.json())
  //   .then(data => {
  //       setDeviceID(data.data);
  //       setFormData({...formData, deviceID:data.data});
  //   })
  //   },[formData.serialNumber]);

  // GET SCHEDULED TEST DATE FOR SINGLE DEVICE
  // useEffect(() => {
  //   fetch(`https://flo-trace-api-bmjve.ondigitalocean.app/${clientId}/${formData.deviceID}/getdevicescheduledtestdate`)
  //   .then(response => response.json())
  //   .then(data => {setDeviceScheduledTestDate(data.data[0].NewSchTestDate)})
  //   },[formData.deviceLocation]);

  // useEffect(() => {
  //   fetch(`https://flo-trace-api-bmjve.ondigitalocean.app/${clientId}/${selectedDeviceID}/getdevicescheduledtestdate`)
  //   .then(response => response.json())
  //   .then(data => {setDeviceScheduledTestDate(data.data[0].NewSchTestDate)})
  //   },[formData.deviceLocation]);

  useEffect(() => {
    console.log("FORM DATa", formData);
  }, [formData]);

  // Handle getting scheduled test date
  useEffect(() => {
    const filteredDeviceData = companyDevicesData.filter(
      (row) => formData.deviceLocation === row.Location
    )[0];

    if (filteredDeviceData !== undefined) {
      setSelectedDeviceID(filteredDeviceData.DeviceID);
      setFormData({ ...formData, deviceID: filteredDeviceData.DeviceID });
      fetch(
        `${baseURL}/${clientId}/${filteredDeviceData.DeviceID}/getdevicescheduledtestdate`
      )
        .then((response) => response.json())
        //.then(data => console.log('RESPONSE', data))

        .then((data) => {
          console.log("RESPONSE", data);
          setDeviceScheduledTestDate(data.data[0].NewSchTestDate);
        });
    } else {
    }
  }, [formData.deviceLocation, clientId]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const action = "Test results added";

    axios
      .post(
        `${baseURL}/${clientId}/${clientName}/${
          formData.deviceID
        }/${uploadedFileName.replace(/ /g, "%20")}/${action.replace(
          / /g,
          "%20"
        )}/uploadfile`,
        testFiles,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        if (response.data.success === "success") {
          setAlertContent("Test results uploaded successfully!");
          setAlert(true);
          handleOpenSubmitModal();
        } else {
          setAlertContent("Error uploading file");
          setAlert(true);
          handleOpenSubmitModal();
        }
      })
      .catch((error) => {
        //alert(error)
      });

    // Send the Add a Test formdata to the filestore via the post test route

    fetch(`${baseURL}/${clientId}/posttest`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
      });
  };

  // const toggleDrawer = () => {
  //   setOpen(!open);
  // };

  const statusTypes = [
    {
      value: 1,
      label: "Pass",
    },
    {
      value: 2,
      label: "Fail",
    },
  ];

  return (
    <>
      <Box>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            {/* Header Section */}
            <Header
              clientId={clientId}
              clientName={clientName}
              handleLogout={handleLogout}
              istoggleMenu={toggleMenus}
              clientNameHeader={clientNameHeader}
            />
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Grid container spacing={0}>
          <Grid item xs={12} md={2}>
            {/* Footer Section */}
            <PhoneMenu slug="/technicians" />
            <SidebarMenu toggleMenu={setToggleMenus} />
          </Grid>
          <Grid
            item
            xs={12}
            md={10}
            className={
              toggleMenus === true ? "tooggleActive mainContent" : "mainContent"
            }
          >
            <Container maxWidth="xlg" sx={{ mt: 8, mb: 4 }}>
              <Grid container spacing={10} className="topDueSect">
                <Grid item xs={12} sm={12} md={6} className="beforetable">
                  <Typography
                    className="SelDevice"
                    align="left"
                    variant="h4"
                    gutterBottom
                  >
                    ADD A TEST
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  sx={{ textAlign: "right" }}
                  className="beforetable"
                ></Grid>

                <Grid item xs={12} className="tabSect" sx={{ mt: 0 }}>
                  {/* <Typography>* Denotes a required field</Typography> */}
                  <Box
                    align="left"
                    sx={{
                      mt: 2,
                      "& .MuiTextField-root": { m: 1, width: "30ch" },
                    }}
                    autoComplete="off"
                  >
                    <h3>Device Details</h3>
                    <p>
                      Select a device below or{" "}
                      <Link to="/adddevice">add a new device</Link>
                    </p>

                    <Box component="form" onSubmit={handleSubmit}>
                      <Grid sx={{ mt: 2 }}>
                        <TextField
                          error={formData.companyName == ""}
                          id="outlined-select-currency"
                          select
                          required
                          style={{ width: 550 }}
                          // label="Company Name"
                          helperText={
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              Select company Name
                              <img
                                src={tooltipImage}
                                width={"15px"}
                                style={{
                                  cursor: "pointer",
                                  marginLeft: 5,
                                  marginTop: "1.5px",
                                }}
                                onMouseOver={(e) =>
                                  handleClick(e, "CompanyName")
                                }
                                onMouseOut={(e) =>
                                  handleClick(e, "CompanyName")
                                }
                                ref={tooltipRefs["CompanyName"]}
                              />
                              <Tooltip
                                id="simple-popover"
                                open={anchorEls["CompanyName"]}
                                arrow
                                placement="right"
                                PopperProps={{
                                  modifiers: [
                                    {
                                      name: "maxWidth",
                                      enabled: true,
                                      phase: "beforeWrite",
                                      fn({ state }) {
                                        state.styles.popper.maxWidth = "205px";
                                      },
                                    },
                                  ],
                                }}
                                title={
                                  <div>
                                    <Typography
                                      variant="body1"
                                      style={{
                                        textAlign: "center",
                                        fontSize: "15px",
                                        fontWeight: "600",
                                        padding: "15px",
                                        lineHeight: "1rem",
                                      }}
                                    >
                                      Add company and contact before they are
                                      visible here
                                    </Typography>
                                    <div
                                      style={{
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "end",
                                      }}
                                    ></div>
                                  </div>
                                }
                              ></Tooltip>
                            </div>
                          }
                          value={formData.companyName}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              companyName: e.target.value,
                            })
                          }
                        >
                          <MenuItem
                            style={{ paddingTop: "0px", paddingBottom: "0px" }}
                          >
                            <Link
                              style={{
                                textDecoration: "none",
                                color: "black",
                                width: "100%",
                                height: "100%",
                                marginTop: "2px",
                                marginBottom: "2px",
                                fontFamily:
                                  "Roboto, Helvetica, Arial, sans-serif",
                              }}
                              to="/addcompany"
                            >
                              Add a Company
                            </Link>
                          </MenuItem>
                          {companiesData.map((option) => (
                            <MenuItem
                              key={option.CompanyID}
                              value={option.CompanyName}
                            >
                              {option.CompanyName}
                            </MenuItem>
                          ))}
                        </TextField>

                        <TextField
                          error={formData.deviceLocation == ""}
                          id="outlined-select-currency"
                          select
                          required
                          style={{ width: 660 }}
                          disabled={!formData.companyName}
                          sx={{
                            opacity: formData.companyName ? 1 : 0.5,
                          }}
                          // label="Device"
                          helperText={
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              Select a Device
                              <img
                                src={tooltipImage}
                                width={"15px"}
                                style={{
                                  cursor: "pointer",
                                  marginLeft: 5,
                                  marginTop: "1.5px",
                                }}
                                ref={tooltipRefs["DeviceName"]}
                                onMouseOver={(e) =>
                                  handleClick(e, "DeviceName")
                                }
                                onMouseOut={(e) => handleClick(e, "DeviceName")}
                              />
                              <Tooltip
                                id="simple-popover test"
                                open={anchorEls["DeviceName"]}
                                placement="right"
                                arrow
                                title={
                                  <div>
                                    <Typography
                                      variant="body2"
                                      style={{
                                        textAlign: "center",
                                        fontSize: "15px",
                                        fontWeight: "600",
                                        padding: "15px",
                                        lineHeight: "1rem",
                                      }}
                                    >
                                      Add a device to be visible in this list
                                    </Typography>
                                  </div>
                                }
                                PopperProps={{
                                  modifiers: [
                                    {
                                      name: "maxWidth",
                                      enabled: true,
                                      phase: "beforeWrite",
                                      fn({ state }) {
                                        state.styles.popper.maxWidth = "200px";
                                      },
                                    },
                                  ],
                                }}
                              ></Tooltip>
                            </div>
                          }
                          value={formData.deviceLocation}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              deviceLocation: e.target.value,
                            })
                          }
                          // onChange={e => setSelectedDeviceID(companyDevicesData.filter((row) => e.target.value.has(row.deviceLocation)))}
                        >
                          {companyDevicesData.map((option) => (
                            <MenuItem
                              key={option.DeviceID}
                              value={option.Location}
                            >
                              {option.Location}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid sx={{ mt: 2 }}>
                        {/* <TextField
              id="outlined-select-currency"
              label="Device Serial Number"
              required
              helperText="Select Serial Number"
              select
              value={formData.serialNumber}
              onChange={e => setFormData({...formData, serialNumber: e.target.value})}
            >
              {deviceSerialNumbers.map((option) => (
                <MenuItem key={option.DeviceID} value={option.SerialNumber}>
                  {option.SerialNumber}
                </MenuItem>
              ))}
            </TextField> */}
                        {console.log("asdfasdf", formData.serialNumber)}
                        <Typography>
                          Test date info: {formData.serialNumber}
                        </Typography>

                        <TextField
                          id="outlined-select-currency"
                          // label="Scheduled Test Date"
                          helperText="Select a device to see scheduled test date."
                          style={{ width: 325 }}
                          disabled
                          value={moment(deviceScheduledTestDate).format(
                            "MM/DD/YYYY"
                          )}
                        ></TextField>

                        <h3>Technician</h3>
                        <p>
                          Select technician below or{" "}
                          <Link to="/addtechnician">add a new technician</Link>
                        </p>
                        <Grid>
                          <TextField
                            error={formData.technician == ""}
                            id="outlined-select-currency"
                            select
                            required
                            style={{ width: 550 }}
                            // label="Technician"

                            helperText={
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                Select the certified technician who performed
                                the test
                                <img
                                  src={tooltipImage}
                                  width={"15px"}
                                  style={{
                                    cursor: "pointer",
                                    marginLeft: 5,
                                    marginTop: "1.5px",
                                  }}
                                  ref={tooltipRefs["technicianName"]}
                                  onMouseOver={(e) =>
                                    handleClick(e, "technicianName")
                                  }
                                  onMouseOut={(e) =>
                                    handleClick(e, "technicianName")
                                  }
                                />
                                <Tooltip
                                  id="simple-popover test"
                                  open={anchorEls["technicianName"]}
                                  arrow
                                  placement="right"
                                  title={
                                    <div>
                                      <Typography
                                        variant="body2"
                                        style={{
                                          textAlign: "center",
                                          fontSize: "15px",
                                          fontWeight: "600",
                                          padding: "15px",
                                          lineHeight: "1rem",
                                        }}
                                      >
                                        Add a technician before it’s visible in
                                        this list
                                      </Typography>
                                    </div>
                                  }
                                  PopperProps={{
                                    modifiers: [
                                      {
                                        name: "maxWidth",
                                        enabled: true,
                                        phase: "beforeWrite",
                                        fn({ state }) {
                                          state.styles.popper.maxWidth =
                                            "190px";
                                        },
                                      },
                                    ],
                                  }}
                                ></Tooltip>
                              </div>
                            }
                            value={formData.technician}
                            onChange={(e) => {
                              let filteredTechData = techniciansData.filter(
                                (row) => row.Technician === e.target.value
                              );

                              setFormData({
                                ...formData,
                                technician: e.target.value,
                                technicianID: filteredTechData[0].TechID,
                              });

                              //setFormData({...formData, technicianID: filteredTechData[0].TechID})
                            }}
                            // onChange={e => {
                            //   let filteredTechs = techniciansData.filter((row) => row.Technician==e.target.value);
                            //   console.log('filtered technician id data', filteredTechs)
                            //   setFormData({...formData, technician: filteredTechs.id})
                            // }}
                          >
                            {techniciansData.map((option) => (
                              <MenuItem
                                key={option.TechnicianID}
                                value={option.Technician}
                              >
                                {option.Technician}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>

                        <h3>Dates</h3>
                        {/* <p>The scheduled test date for this device:</p> */}

                        <Grid container spacing={0}>
                          <Grid item xs={2}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer components={["DatePicker"]}>
                                <DatePicker
                                  style={{ width: "1000%" }}
                                  helperText="Select originally scheduled test date"
                                  required
                                  label="Actual Test Date"
                                  // onChange={(date) => {
                                  //   setFormData({...formData, actualTestDate:date.format('YYYY-MM-DD')})}}
                                  //   //.toISOString()

                                  onChange={(date) => {
                                    if (date && date.format("MM/DD/YYYY")) {
                                      setFormData({
                                        ...formData,
                                        actualTestDate:
                                          date.format("MM/DD/YYYY"),
                                      });
                                    } else {
                                      setFormData({
                                        ...formData,
                                        actualTestDate: "2000-01-01",
                                      });
                                    }
                                  }}
                                  slotProps={{
                                    textField: {
                                      required: true,
                                      error: formData.actualTestDate === "",
                                    },
                                  }}
                                />
                              </DemoContainer>
                            </LocalizationProvider>
                          </Grid>
                          <Grid item xs={2}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer components={["DatePicker"]}>
                                <DatePicker
                                  helperText="Select date gauge was calibrated"
                                  required
                                  label="Gauge Calibration Date"
                                  onChange={(date) => {
                                    if (date && date.format("MM/DD/YYYY")) {
                                      setFormData({
                                        ...formData,
                                        gaugeCalibrationDate:
                                          date.format("MM/DD/YYYY"),
                                      });
                                    } else {
                                      setFormData({
                                        ...formData,
                                        gaugeCalibrationDate: "2000-01-01",
                                      });
                                    }
                                  }}
                                  //.toISOString()
                                  slotProps={{
                                    textField: {
                                      required: true,
                                      error:
                                        formData.gaugeCalibrationDate === "",
                                    },
                                  }}
                                />
                              </DemoContainer>
                            </LocalizationProvider>
                          </Grid>
                        </Grid>

                        {/* <h3>Result</h3> */}
                        <Grid container spacing={0}>
                          <Grid sx={{ mt: 4 }}>
                            <h3>Result</h3>
                            <TextField
                              error={formData.testResult === ""}
                              sx={{ mt: 8 }}
                              id="outlined-select-currency"
                              select
                              // label="Result"
                              helperText="Result"
                              required
                              defaultValue="Pass"
                              value={formData.testResult}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  testResult: e.target.value,
                                })
                              }
                            >
                              {statusTypes.map((option) => (
                                <MenuItem
                                  key={option.value}
                                  value={option.label}
                                >
                                  {option.label}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Grid>
                          <Grid sx={{ mt: 4, pl: 5 }}>
                            <h3>Upload Test Result</h3>
                            <Typography>
                              Files must be in PDF or DOCX format
                            </Typography>
                            <Box sx={{ display: "inline-flex" }}>
                              <UploadTest onUploadTestFile={handleFileUpload} />
                              <p>{uploadedFileName}</p>
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid>
                          <Box sx={{ display: "inline-flex" }}>
                            <AddTestModal
                              submitTestFileAndData={handleSubmit}
                              openStatus={openSubmitModal}
                              handleModalClose={handleCloseSubmitModal}
                              alertContentText={alertContent}
                            />
                            <Button
                              disabled={formIsValid ? false : true}
                              type="submit"
                              variant="contained"
                              size="large"
                              color="success"
                              sx={{
                                ml: 1,
                                mr: 6,
                                mt: 6,
                                borderRadius: 8,
                                width: 225,
                              }}
                            >
                              Submit
                            </Button>
                            <Button
                              size="large"
                              href="/"
                              variant="contained"
                              sx={{
                                mr: 6,
                                mt: 6,
                                borderRadius: 8,
                                borderRadius: 8,
                                width: 225,
                              }}
                              color="error"
                            >
                              Cancel
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </>
  );
};

export default AddTest;
