import { createSlice, createAsyncThunk  } from '@reduxjs/toolkit'


const initialState = {
  value: {},
}

export const clientSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clientsList :  (state, action) => {
      state.value = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const {clientsList} = clientSlice.actions

export default clientSlice.reducer