import React, { useState, useEffect, useContext, useRef } from "react";
import { TextField, FormControl, Button } from "@mui/material";
import { mainListItems, secondaryListItems } from "../listItems";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MuiPhoneNumber from "mui-phone-number";
import MenuItem from "@mui/material/MenuItem";
import SubmitAddDeviceModal from "../SubmitAddDeviceModalDeprecated";
import Copyright from "../Copyright";
import Select from "@mui/material/Select";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "../AppBar";
import FloTraceDrawer from "../FloTraceDrawer";
import CssBaseline from "@mui/material/CssBaseline";
import List from "@mui/material/List";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import AddTestModal from "../AddTestModal";
import MuiAlert from "@mui/material/Alert";
import InputMask from "react-input-mask";
import FormattedInputs from "../PhoneMask";
import { states } from "../States.js";
import Header from "../Common/Header.js";
import Footer from "../Common/Footer.js";
import { LoginContext } from "../store/LoginContext.js";
// =========
import Container from "@mui/material/Container";
import PhoneMenu from "../Common/PhoneMenu";
import SidebarMenu from "../Common/SidebarMenu";
import { useSelector } from "react-redux";
import { Tooltip, Popover } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import tooltipImage from "../../assets/images/tooltip.jpeg";

import {
  isTokenExpired,
  handleExpiredToken,
  handleTokenExpiryWarning,
} from "../Common/CheckJwtExpires";

const AddContact = ({ clientName, handleLogout, clientNameHeader }) => {
  const lgnContext = useContext(LoginContext);

  const clientId = useSelector((state) => state.userID.value);

  const [device, setDevice] = useState("");
  const [password, setPassword] = useState("");
  const [open, setOpen] = React.useState(true);
  const [companiesData, setCompaniesData] = useState([]);
  const [deviceTypesData, setDeviceTypesData] = useState([]);
  const [sizesData, setSizesData] = useState([]);
  const [manufacturersData, setManufacturersData] = useState([]);
  const [companyQueryName, setCompanyQueryName] = useState("");
  const [openSubmitModal, setOpenSubmitModal] = useState(false);
  const [alertContent, setAlertContent] = useState("");
  const [alert, setAlert] = useState(false);
  const [anchorEls, setAnchorEls] = useState({
    CompanyName: false,
  });
  const tooltipRefs = {
    CompanyName: useRef(null),
  };
  const [loginCount, setLoginCount] = useState(0);
  const companyNameRef = useRef(null);

  const handleOpenSubmitModal = () => setOpenSubmitModal(true);
  const handleCloseSubmitModal = () => setOpenSubmitModal(false);
  const token = localStorage.getItem("token");
  useEffect(() => {
    const checkTokenExpiry = () => {
      if (isTokenExpired(token)) {
        handleExpiredToken();
      } else {
        handleTokenExpiryWarning(token);
      }
    };

    checkTokenExpiry();
    const interval = setInterval(checkTokenExpiry, 60000);

    return () => clearInterval(interval);
  }, [token]);

  const handleClick = (event, field) => {
    setAnchorEls({ ...anchorEls, [field]: !anchorEls[field] });
  };
  const handleClickOutside = (e) => {
    if (!tooltipRefs["CompanyName"].current.contains(e.target)) {
      setAnchorEls({
        CompanyName: false,
      });
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [contactFormData, setContactFormData] = React.useState({
    companyName: "",
    lastName: "",
    firstName: "",
    emailAddress: "",
    jobTitle: "",
    businessPhone: "",
    homePhone: "",
    mobilePhone: "",
    faxNumber: "",
    streetAddress: "",
    city: "",
    state: "",
    postalCode: "",
    country: "",
    notes: "",
  });

  const [companyNameIsValid, setCompanyNameIsValid] = useState(false);
  const [firstNameIsValid, setFirstNameIsValid] = useState(false);
  const [lastNameIsValid, setLastNameIsValid] = useState(false);
  const [jobTitleIsValid, setJobTitleIsValid] = useState(false);
  const [emailIsValid, setEmailIsValid] = useState(false);
  const [streetAddressIsValid, setStreetAddressIsValid] = useState(false);

  const [deviceTypeIsValid, setDeviceTypeIsValid] = useState(false);
  const [manufacturerIsValid, setManufacturerIsValid] = useState(false);
  const [modelNumberIsValid, setModelNumberIsValid] = useState(false);
  const [serialNumberIsValid, setSerialNumberIsValid] = useState(false);
  const [sizeIsValid, setSizeIsValid] = useState(false);
  const [conditionIsValid, setConditionIsValid] = useState(false);
  const [installedDateIsValid, setInstalledDateIsValid] = useState(false);
  const [locationNotesIsValid, setLocationNotesIsValid] = useState(false);
  const [cityIsValid, setCityIsValid] = useState(false);
  const [stateIsValid, setStateIsValid] = useState(false);
  const [postalCodeIsValid, setPostalCodeIsValid] = useState(false);

  const [businessPhoneIsValid, setBusinessPhoneIsValid] = useState(false);
  const [homePhoneIsValid, setHomePhoneIsValid] = useState(false);
  const [mobilePhoneIsValid, setMobilePhoneIsValid] = useState(false);
  const [faxIsValid, setFaxIsValid] = useState(false);
  const [zipIsValid, setZipIsValid] = useState(false);
  const [formIsValid, setFormIsValid] = useState(false);
  const [toggleMenus, setToggleMenus] = useState(false);

  useEffect(() => {
    if (
      businessPhoneIsValid &&
      // && homePhoneIsValid
      // && mobilePhoneIsValid
      // && faxIsValid
      zipIsValid &&
      companyNameIsValid &&
      firstNameIsValid &&
      lastNameIsValid &&
      jobTitleIsValid &&
      emailIsValid &&
      streetAddressIsValid &&
      stateIsValid
    ) {
      setFormIsValid(true);
    } else {
      setFormIsValid(false);
    }
  }, [
    businessPhoneIsValid,
    homePhoneIsValid,
    mobilePhoneIsValid,
    faxIsValid,
    zipIsValid,
    companyNameIsValid,
    firstNameIsValid,
    lastNameIsValid,
    jobTitleIsValid,
    emailIsValid,
    streetAddressIsValid,
    stateIsValid,
  ]);

  function dynamicSort(property) {
    return function (a, b) {
      return a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    };
  }

  const handleCompanyChange = (e) => {
    setContactFormData({ ...contactFormData, companyName: e.target.value });
    setCompanyQueryName(e.target.value.replace(/ /g, "%20"));
    console.log("Query Name", companyQueryName);
  };

  //FETCH COMPANY CONTACT INFO
  useEffect(() => {
    fetch(
      `https://flo-trace-api-prod-ugyxg.ondigitalocean.app/${clientId}/${contactFormData.companyName.replace(
        / /g,
        "%20"
      )}/getcompanycontactinfo`
    ).then((response) => response.json());
  }, [contactFormData.companyName, clientId]);

  //FETCH COMPANY NAMES BY CLIENT ID
  useEffect(() => {
    fetch(
      `https://flo-trace-api-prod-ugyxg.ondigitalocean.app/${clientId}/getcompanynames`
    )
      .then((response) => response.json())
      .then((data) => {
        setCompaniesData(data.data.sort(dynamicSort("CompanyName")));
      });
  }, [clientId]);

  const handleSubmit = (e) => {
    e.preventDefault();

    fetch(
      `https://flo-trace-api-prod-ugyxg.ondigitalocean.app/${clientId}/postcontact`,
      {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(contactFormData),
      }
    )
      .then((response) => response.json())
      .then((response) => {
        setAlertContent("Contact created successfully!");
        setAlert(true);
        handleOpenSubmitModal();
      })
      .then((result) => {
        console.log(result);
      });
  };

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const [phoneNumber, setPhoneNumber] = useState({
    phone: null,
  });

  function handlePhoneChange(value) {
    setPhoneNumber({
      phone: value,
    });
    console.log("Phone number", phoneNumber);
  }

  return (
    <>
      <Box>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            {/* Header Section */}
            <Header
              clientId={clientId}
              clientName={clientName}
              handleLogout={handleLogout}
              istoggleMenu={toggleMenus}
              clientNameHeader={clientNameHeader}
            />
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Grid container spacing={0}>
          <Grid item xs={12} md={2}>
            {/* Footer Section */}
            <PhoneMenu slug="/technicians" />
            <SidebarMenu toggleMenu={setToggleMenus} />
          </Grid>
          <Grid
            item
            xs={12}
            md={10}
            className={
              toggleMenus === true ? "tooggleActive mainContent" : "mainContent"
            }
          >
            <Container maxWidth="xlg" sx={{ mt: 8, mb: 4 }}>
              <Grid container spacing={10} className="topDueSect">
                <Grid item xs={12} sm={12} md={6} className="beforetable">
                  <Typography
                    className="SelDevice"
                    align="left"
                    variant="h4"
                    gutterBottom
                  >
                    ADD NEW CONTACT
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  sx={{ textAlign: "right" }}
                  className="beforetable"
                ></Grid>
                <Grid item xs={12} style={{ paddingTop: 0 }}>
                  {/* <Typography>* Denotes a required field</Typography> */}
                  <Box
                    align="left"
                    component="form"
                    sx={{
                      "& .MuiTextField-root": { m: 1, width: "30ch" },
                    }}
                    autoComplete="off"
                  >
                    <h3>Contact Company</h3>
                    <Grid>
                      <TextField
                        error={contactFormData.companyName == ""}
                        id="outlined-select-currency"
                        select
                        required
                        style={{ width: 500 }}
                        //label="Company Name"
                        helperText={
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            Select associated company contact
                            <img
                              src={tooltipImage}
                              width={"15px"}
                              style={{
                                cursor: "pointer",
                                marginLeft: 5,
                                marginTop: "1.5px",
                              }}
                              onMouseOver={(e) => handleClick(e, "CompanyName")}
                              onMouseOut={(e) => handleClick(e, "CompanyName")}
                              ref={tooltipRefs["CompanyName"]}
                            />
                            <Tooltip
                              id="simple-popover test"
                              open={anchorEls["CompanyName"]}
                              arrow
                              placement="right"
                              title={
                                <div>
                                  <Typography
                                    variant="body2"
                                    style={{
                                      textAlign: "center",
                                      fontSize: "15px",
                                      fontWeight: "600",
                                      padding: "15px",
                                      lineHeight: "1rem",
                                    }}
                                  >
                                    Add company before it’s visible here
                                  </Typography>
                                </div>
                              }
                              PopperProps={{
                                modifiers: [
                                  {
                                    name: "maxWidth",
                                    enabled: true,
                                    phase: "beforeWrite",
                                    fn({ state }) {
                                      state.styles.popper.maxWidth = "220px";
                                    },
                                  },
                                ],
                              }}
                            ></Tooltip>
                          </div>
                        }
                        value={contactFormData.companyName}
                        //onChange={e => setContactFormData({...contactFormData, companyName: e.target.value})}

                        onChange={(e) => {
                          setContactFormData({
                            ...contactFormData,
                            companyName: e.target.value,
                          });

                          let digitLength = e.target.value.toString().length;
                          if (e.target.value == "") {
                            setCompanyNameIsValid(false);
                            console.log(companyNameIsValid);
                          } else {
                            setCompanyNameIsValid(true);
                            console.log(companyNameIsValid);
                          }
                        }}
                      >
                        {companiesData.map((option) => (
                          <MenuItem
                            key={option.CompanyID}
                            value={option.CompanyName}
                          >
                            {option.CompanyName}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <h3>Contact Info</h3>

                    <Grid></Grid>
                    <Grid>
                      <TextField
                        error={contactFormData.firstName == ""}
                        id="outlined-select-currency"
                        //label="First Name"
                        required
                        helperText="Contact First Name"
                        value={contactFormData.firstName}
                        //onChange={e => setContactFormData({...contactFormData, firstName:e.target.value})}
                        onChange={(e) => {
                          setContactFormData({
                            ...contactFormData,
                            firstName: e.target.value,
                          });

                          //let digitLength = e.target.value.toString().length
                          if (e.target.value == "") {
                            setFirstNameIsValid(false);
                            console.log(firstNameIsValid);
                          } else {
                            setFirstNameIsValid(true);
                            console.log(firstNameIsValid);
                          }
                        }}
                      ></TextField>
                      <TextField
                        error={contactFormData.lastName == ""}
                        id="outlined-select-currency"
                        //label="Last Name"
                        required
                        helperText="Contact Last name"
                        value={contactFormData.lastName}
                        //onChange={e => setContactFormData({...contactFormData, lastName:e.target.value})}
                        onChange={(e) => {
                          setContactFormData({
                            ...contactFormData,
                            lastName: e.target.value,
                          });

                          //let digitLength = e.target.value.toString().length
                          if (e.target.value == "") {
                            setLastNameIsValid(false);
                            console.log(lastNameIsValid);
                          } else {
                            setLastNameIsValid(true);
                            console.log(lastNameIsValid);
                          }
                        }}
                      ></TextField>
                      <TextField
                        error={contactFormData.jobTitle == ""}
                        id="outlined-select-currency"
                        //label="Job Title"
                        required
                        // style={{ width: 550 }}
                        helperText="Contact job title"
                        value={contactFormData.jobTitle}
                        //onChange={e => setContactFormData({...contactFormData, jobTitle:e.target.value})}
                        onChange={(e) => {
                          setContactFormData({
                            ...contactFormData,
                            jobTitle: e.target.value,
                          });

                          //let digitLength = e.target.value.toString().length
                          if (e.target.value == "") {
                            setJobTitleIsValid(false);
                            console.log(jobTitleIsValid);
                          } else {
                            setJobTitleIsValid(true);
                            console.log(jobTitleIsValid);
                          }
                        }}
                      ></TextField>
                      <TextField
                        error={contactFormData.emailAddress == ""}
                        id="outlined-select-currency"
                        //label="Email Address"
                        required
                        helperText="Contact email address"
                        value={contactFormData.emailAddress}
                        //onChange={e => setContactFormData({...contactFormData, emailAddress:e.target.value})}
                        onChange={(e) => {
                          setContactFormData({
                            ...contactFormData,
                            emailAddress: e.target.value,
                          });
                          if (e.target.value == "") {
                            setEmailIsValid(false);
                          } else {
                            setEmailIsValid(true);
                          }
                        }}
                      ></TextField>
                    </Grid>
                    <Grid>
                      {/* <InputMask mask="+4\9 99 999 99" maskChar=" " /> */}
                      {/* <InputMask mask="999 999-9999" maskChar="_" /> */}
                      {/* <FormattedInputs /> */}
                      {/* <InputMask
                          mask="(999) 999-9999"
                          value={contactFormData.businessPhone}
                          disabled={false}
                          maskChar="-"
                          onChange={e => setContactFormData({...contactFormData, businessPhone:e.target.value})}
                        >
                        {() => <TextField />}
                        </InputMask> 
                        */}

                      <InputMask
                        mask="(999) 999-9999"
                        value={contactFormData.businessPhone}
                        disabled={false}
                        maskChar=" "
                        //onChange= {e =>
                        //{setContactFormData({...contactFormData, businessPhone:e.target.value})}}
                        onChange={(e) => {
                          setContactFormData({
                            ...contactFormData,
                            businessPhone: e.target.value,
                          });

                          let digitLength = e.target.value
                            .toString()
                            .replace(/[^0-9]/g, "").length;
                          if (digitLength < 10) {
                            setBusinessPhoneIsValid(false);
                          } else {
                            setBusinessPhoneIsValid(true);
                          }
                        }}
                      >
                        {() => (
                          <TextField
                            required
                            error={
                              contactFormData.businessPhone
                                .toString()
                                .replace(/[^0-9]/g, "").length < 10
                            }
                            helperText={
                              contactFormData.businessPhone
                                .toString()
                                .replace(/[^0-9]/g, "").length < 10
                                ? "Business phone (must be 10 digits)"
                                : " "
                            }
                            id="outlined-select-currency"
                            autoComplete="off"
                            inputProps={{
                              autoComplete: "off",
                            }}
                            //label="Business Phone"
                            value={contactFormData.businessPhone}
                          ></TextField>
                        )}
                      </InputMask>

                      <InputMask
                        mask="(999) 999-9999"
                        value={contactFormData.homePhone}
                        disabled={false}
                        maskChar=" "
                        onChange={(e) => {
                          setContactFormData({
                            ...contactFormData,
                            homePhone: e.target.value,
                          });
                          console.log(
                            "Home phone",
                            contactFormData.homePhone
                              .toString()
                              .replace(/[^0-9]/g, "").length
                          );

                          let digitLength = e.target.value
                            .toString()
                            .replace(/[^0-9]/g, "").length;
                          if (digitLength < 10) {
                            setHomePhoneIsValid(false);
                          } else {
                            setHomePhoneIsValid(true);
                          }
                        }}
                      >
                        {() => (
                          <TextField
                            //required
                            //error={contactFormData.homePhone.toString().replace(/[^0-9]/g,"").length <10}
                            helperText={
                              contactFormData.homePhone
                                .toString()
                                .replace(/[^0-9]/g, "").length < 10
                                ? "Home phone (must be 10 digits)"
                                : " "
                            }
                            id="outlined-select-currency"
                            //label="Home Phone"
                            value={contactFormData.homePhone}
                          ></TextField>
                        )}
                      </InputMask>

                      <InputMask
                        mask="(999) 999-9999"
                        //required
                        value={contactFormData.mobilePhone}
                        disabled={false}
                        maskChar=" "
                        onChange={(e) => {
                          setContactFormData({
                            ...contactFormData,
                            mobilePhone: e.target.value,
                          });
                          console.log(
                            "Mobile phone",
                            contactFormData.mobilePhone
                              .toString()
                              .replace(/[^0-9]/g, "").length
                          );

                          let digitLength = e.target.value
                            .toString()
                            .replace(/[^0-9]/g, "").length;
                          if (digitLength < 10) {
                            setMobilePhoneIsValid(false);
                          } else {
                            setMobilePhoneIsValid(true);
                          }
                        }}
                      >
                        {() => (
                          <TextField
                            //error={contactFormData.mobilePhone.toString().replace(/[^0-9]/g,"").length <10}
                            helperText={
                              contactFormData.mobilePhone
                                .toString()
                                .replace(/[^0-9]/g, "").length < 10
                                ? "Mobile phone (must be 10 digits)"
                                : " "
                            }
                            id="outlined-select-currency"
                            //label="Mobile Phone"
                            value={contactFormData.mobilePhone}
                          ></TextField>
                        )}
                      </InputMask>

                      <InputMask
                        mask="(999) 999-9999"
                        value={contactFormData.faxNumber}
                        disabled={false}
                        maskChar=" "
                        //onChange={setContactFormData({...contactFormData, faxNumber:e.target.value})}
                        onChange={(e) => {
                          setContactFormData({
                            ...contactFormData,
                            faxNumber: e.target.value,
                          });

                          let digitLength = e.target.value
                            .toString()
                            .replace(/[^0-9]/g, "").length;
                          if (digitLength < 10) {
                            setFaxIsValid(false);
                            //console.log("setting fax valid to false")
                          } else {
                            setFaxIsValid(true);
                            //console.log('Setting fax valid to tru')
                          }
                        }}
                      >
                        {() => (
                          <TextField
                            //error={faxIsValid}
                            //error={contactFormData.faxNumber.toString().replace(/[^0-9]/g,"").length <10}
                            helperText={
                              contactFormData.faxNumber
                                .toString()
                                .replace(/[^0-9]/g, "").length < 10
                                ? "Fax number (must be 10 digits)"
                                : " "
                            }
                            id="outlined-select-currency"
                            //label="Fax Number"
                            value={contactFormData.faxNumber}
                          ></TextField>
                        )}
                      </InputMask>

                      {/* <TextField
            id="outlined-select-currency"
            //label="Fax Number"
            helperText="Type fax number"
            value={contactFormData.faxNumber}
            onChange={e => setContactFormData({...contactFormData, faxNumber:e.target.value})}
            >
          </TextField> */}
                    </Grid>

                    <h3>Contact Address</h3>
                    <Grid>
                      <TextField
                        error={contactFormData.streetAddress == ""}
                        id="outlined-select-currency"
                        //label="Street Address"
                        required
                        helperText="Street address (eg. 102 S Main St)"
                        value={contactFormData.streetAddress}
                        //onChange={e => setContactFormData({...contactFormData, streetAddress:e.target.value})}
                        onChange={(e) => {
                          setContactFormData({
                            ...contactFormData,
                            streetAddress: e.target.value,
                          });
                          if (e.target.value == "") {
                            setStreetAddressIsValid(false);
                          } else {
                            setStreetAddressIsValid(true);
                          }
                        }}
                      ></TextField>
                      <TextField
                        error={contactFormData.city == ""}
                        id="outlined-select-currency"
                        // //label="City"
                        required
                        helperText="City (eg. Houston)"
                        value={contactFormData.city}
                        //onChange={e => setContactFormData({...contactFormData, city:e.target.value})}
                        onChange={(e) => {
                          setContactFormData({
                            ...contactFormData,
                            city: e.target.value,
                          });
                          if (e.target.value == "") {
                            setCityIsValid(false);
                          } else {
                            setCityIsValid(true);
                          }
                        }}
                      ></TextField>

                      <TextField
                        error={contactFormData.state == ""}
                        id="outlined-select-currency"
                        select
                        required
                        //style = {{width: 260}}
                        //label="State"
                        helperText="Select associated State"
                        value={contactFormData.state}
                        //onChange={e => setContactFormData({...contactFormData, state: e.target.value})}
                        onChange={(e) => {
                          setContactFormData({
                            ...contactFormData,
                            state: e.target.value,
                          });
                          if (e.target.value == "") {
                            setStateIsValid(false);
                          } else {
                            setStateIsValid(true);
                          }
                        }}
                      >
                        {states.map((option) => (
                          <MenuItem key={option.id} value={option.state}>
                            {option.state}
                          </MenuItem>
                        ))}
                      </TextField>

                      {/* <InputMask
              mask="aa"
              value={contactFormData.state}
              disabled={false}
              maskChar=" "
              onChange={e => setContactFormData({...contactFormData, state:e.target.value})}
              >
              {() => <TextField
                style = {{width: 260}}
                required
                //label="State"
                helperText="Type state"
                value={contactFormData.state}
                >
            </TextField>}
          </InputMask> */}

                      {/* <TextField
            id="outlined-select-currency"
            //label="State"
            required
            helperText="eg TX"
            value={contactFormData.state}
            onChange={e => setContactFormData({...contactFormData, state:e.target.value})}
            >
          </TextField> */}

                      <InputMask
                        mask="99999-9999"
                        value={contactFormData.postalCode}
                        disabled={false}
                        maskChar=" "
                        //onChange={e => setContactFormData({...contactFormData, postalCode:e.target.value})}
                        onChange={(e) => {
                          setContactFormData({
                            ...contactFormData,
                            postalCode: e.target.value,
                          });

                          let digitLength = e.target.value
                            .toString()
                            .replace(/[^0-9]/g, "").length;
                          if (digitLength < 5) {
                            setZipIsValid(false);
                          } else if (digitLength > 5 && digitLength < 9) {
                            setZipIsValid(false);
                          } else {
                            setZipIsValid(true);
                            //console.log('Setting fax valid to tru')
                          }
                        }}
                      >
                        {() => (
                          <TextField
                            error={
                              contactFormData.postalCode
                                .toString()
                                .replace(/[^0-9]/g, "").length < 5 ||
                              (contactFormData.postalCode
                                .toString()
                                .replace(/[^0-9]/g, "").length > 5 &&
                                contactFormData.postalCode
                                  .toString()
                                  .replace(/[^0-9]/g, "").length < 9)
                            }
                            helperText={
                              contactFormData.postalCode
                                .toString()
                                .replace(/[^0-9]/g, "").length < 5 ||
                              (contactFormData.postalCode
                                .toString()
                                .replace(/[^0-9]/g, "").length > 5 &&
                                contactFormData.postalCode
                                  .toString()
                                  .replace(/[^0-9]/g, "").length < 9)
                                ? "Zipcode (must be either 5 or 9 digits)"
                                : " "
                            }
                            id="outlined-select-currency"
                            //style = {{width: 275}}
                            required
                            //label="Postal Code"
                            //helperText="Type 5-digit postal code e.g. 12345"
                            value={contactFormData.postalCode}
                          ></TextField>
                        )}
                      </InputMask>

                      {/* <TextField
            id="outlined-select-currency"
            //label="Zip Code"
            required
            helperText="eg 55555"
            value={contactFormData.postalCode}
            onChange={e => setContactFormData({...contactFormData, postalCode:e.target.value})}
            >
          </TextField> */}
                    </Grid>

                    {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker']}>
                <DatePicker //label="Installed Date"
                onChange={(date) => {
                    setContactFormData({...contactFormData, installedDate:date.format('YYYY-MM-DD')})}}
                  />
            </DemoContainer>
        </LocalizationProvider> */}

                    <Box sx={{ mt: 6 }}>
                      {/* <SubmitAddDeviceModal /> */}

                      <AddTestModal
                        submitTestFileAndData={handleSubmit}
                        openStatus={openSubmitModal}
                        handleModalClose={handleCloseSubmitModal}
                        alertContentText={alertContent}
                      />
                      <Button
                        disabled={formIsValid ? false : true}
                        size="large"
                        variant="contained"
                        color="success"
                        onClick={handleSubmit}
                        sx={{
                          ml: 1,
                          mr: 6,
                          mt: 2,
                          mb: 2,
                          borderRadius: 8,
                          width: 225,
                        }}
                      >
                        Submit
                      </Button>
                      <Button
                        size="large"
                        href="/contacts"
                        variant="contained"
                        sx={{
                          ml: 1,
                          mr: 6,
                          mt: 2,
                          mb: 2,
                          borderRadius: 8,
                          width: 225,
                        }}
                        color="error"
                      >
                        Cancel
                      </Button>
                      {/* <AddTestModal submitTestFileAndData={handleSubmit} openStatus={openSubmitModal} handleModalClose={handleCloseSubmitModal} alertContentText={alertContent}/>
            <Button type="submit" size="large" variant="contained" color="success" sx = {{ mr: 6, mt: 4, mb:4}}>
              Submit
            </Button> 
            <Button size="large" href="/" variant="contained" sx = {{ mt: 4, mb:4}}color="error">
              Cancel
            </Button> */}
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </>
  );
};

export default AddContact;
