import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
} from "@mui/x-data-grid";
import {
  Tooltip,
  IconButton,
  Popover,
  Typography,
  Button,
} from "@mui/material";
import { useState, useEffect, useRef } from "react";

export default function CustomToolbar() {
  const [anchorEls, setAnchorEls] = useState({
    filterOptions: null,
    columnsOptions: null,
    exportOptions: null,
  });
  const filterOptionsRef = useRef(null);
  const exportOptionsRef = useRef(null);
  const columnsOptionsRef = useRef(null);

  const [loginCount, setLoginCount] = useState(0);

  useEffect(() => {
    const storedLoginCount = localStorage.getItem("LoginAttempts");
    const count = storedLoginCount ? parseInt(storedLoginCount, 10) : 0;

    if (count <= 3) {
      console.log("HERE", count);
      setAnchorEls({
        ...anchorEls,
        filterOptions: filterOptionsRef.current,
        columnsOptions: columnsOptionsRef.current,
        exportOptions: exportOptionsRef.current,
      });
      setLoginCount(count);
    }
  }, []);

  const handleClick = (event, field) => {
    setAnchorEls({ ...anchorEls, [field]: event.currentTarget });
  };

  const handleClose = (field) => {
    setAnchorEls({ ...anchorEls, [field]: null });
  };

  return (
    <GridToolbarContainer>
      <Tooltip
        placement="top"
        title={
          <div
            style={{
              textAlign: "center",
              fontSize: "15px",
              fontWeight: "600",
              padding: "15px",
              lineHeight: "1rem",
            }}
          >
            <span>Select the columns you want to see</span>
          </div>
        }
        PopperProps={{
          modifiers: [
            {
              name: "maxWidth",
              enabled: true,
              phase: "beforeWrite",
              fn({ state }) {
                state.styles.popper.maxWidth = "190px";
              },
            },
          ],
        }}
        onClose={() => handleClose("columnsOptions")}
        arrow
      >
        <GridToolbarColumnsButton />
      </Tooltip>

      <Tooltip
        placement="top"
        title={
          <div
            style={{
              textAlign: "center",
              fontSize: "15px",
              fontWeight: "600",
              padding: "15px",
              lineHeight: "1rem",
            }}
          >
            <span>Filter the data as you prefer</span>
          </div>
        }
        PopperProps={{
          modifiers: [
            {
              name: "maxWidth",
              enabled: true,
              phase: "beforeWrite",
              fn({ state }) {
                state.styles.popper.maxWidth = "150px";
              },
            },
          ],
        }}
        onClose={() => handleClose("columnsOptions")}
        arrow
      >
        <GridToolbarFilterButton />
      </Tooltip>

      <Tooltip
        placement="top"
        title={
          <div
            style={{
              textAlign: "center",
              fontSize: "15px",
              fontWeight: "600",
              padding: "15px",
              lineHeight: "1.1rem",
            }}
          >
            <span>Export to different formats</span>
          </div>
        }
        PopperProps={{
          modifiers: [
            {
              name: "maxWidth",
              enabled: true,
              phase: "beforeWrite",
              fn({ state }) {
                state.styles.popper.maxWidth = "170px";
              },
            },
          ],
        }}
        onClose={() => handleClose("filterOptions")}
        arrow
      >
        <GridToolbarExport />
      </Tooltip>
    </GridToolbarContainer>
  );
}
