import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import FloTraceLogo from "./FloTraceLogo";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useState, useContext } from "react";
import { LoginContext } from "./store/LoginContext";
import axios from "axios";
import background from "../background.png";
import { useDispatch } from "react-redux";
import { updateUserID } from "./store/UserReducer";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EmailSentModal from "./EmailSentModal";

function Copyright(props) {
  return (
    <Typography variant="body2" color="black" align="center" {...props}>
      {"Copyright © "}
      <Link color="inherit" href="https://www.lspssolutions.com/">
        LSPS
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const defaultTheme = createTheme();

export default function ForgetPassword() {
  const dispatch = useDispatch();
  const [ForgetPasswordSuccessful, setForgetPasswordSuccessful] = useState();
  const [forgetPAssword, setforgetPassword] = useState({
    email: "",
  });
  const [isOpenModal, setOpenModal] = useState(false);
  const [error, setError] = useState(null);
  const baseURL = process.env.REACT_APP_API_BASE_URL;

  const forgetPassword = (e) => {
    e.preventDefault();

    axios({
      method: "POST",
      url: `${baseURL}/forgot-password`,
      data: {
        email: forgetPAssword.email,
      },
    })
      .then((response) => {
        console.log(response.data.msg);

        setForgetPasswordSuccessful(true);
        setOpenModal(true);

        setforgetPassword({
          email: "",
        });
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data.msg);
          setError(error.response.data.msg);
          setForgetPasswordSuccessful(false);
          setOpenModal(true);
        } else {
          console.log("An error occurred:", error.message);
        }
      });
  };

  const handleChange = (event) => {
    const { value, name } = event.target;
    setforgetPassword((prevNote) => ({
      ...prevNote,
      [name]: value,
    }));
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container
        component="main"
        maxWidth="sm"
        sx={{ paddingTop: 8, paddingBottom: 8 }}
        // sx={{
        //   backgroundImage:`url(${background})`,
        //       // backgroundRepeat: "no-repeat",
        //       // backgroundSize: "cover",
        //       // height: "385px",}}>
        // }}>
      >
        <CssBaseline />
        {/* <Box sx={{backgroundImage:`url(${background})`}}> */}
        <Box
          sx={{
            borderRadius: 1,
            bgcolor: "white",
            marginTop: 8,
            marginBottom: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <FloTraceLogo />
          {/* <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
            <LockOutlinedIcon />
          </Avatar> */}
          <Typography component="h1" variant="h5">
            Forget Password
          </Typography>
          <Box
            component="form"
            onSubmit={forgetPassword}
            noValidate
            sx={{ mt: 1 }}
            style={{ width: "500px" }}
          >
            <TextField
              onChange={handleChange}
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              text={forgetPAssword.email}
              value={forgetPAssword.email}
            />

            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Submit
            </Button>

            <p style={{ display: "flex", alignItems: "center" }}>
              <ArrowBackIcon style={{ color: "grey" }} sx={{ mt: 0.2 }} />
              <span
                style={{ marginLeft: "8px", fontSize: "16px", color: "grey" }}
                sx
              >
                Back to
              </span>
              <Link
                href="/"
                sx={{ ml: 1 }}
                style={{ marginLeft: "8px", fontSize: "16px" }}
              >
                Sign in
              </Link>
            </p>
            <EmailSentModal
              color={ForgetPasswordSuccessful ? "" : "error"}
              openStatus={isOpenModal}
              handleModalClose={() => setOpenModal(false)}
              alertContentText={
                ForgetPasswordSuccessful ? "Email sent successfuly" : error
              }
            />

            {/* {isLoggedIn && <h1>Welcome back!</h1>} */}
            {/* <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                {/* <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link> 
              </Grid>
            </Grid> */}
          </Box>
          <Copyright sx={{ mt: 4, mb: 4 }} />
        </Box>
        {/* </Box> */}
        {/* <Copyright sx={{ mt: 8, mb: 4 }} /> */}
      </Container>
    </ThemeProvider>
  );
}
