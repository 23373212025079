import React, { useState, useEffect, useContext } from "react";
import Title from "./Title";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import Box from "@mui/material/Box";
import { Tooltip } from "@mui/material";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import CustomToolbar from "./CustomToolbar";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import Container from "@mui/material/Container";
import Header from "./Common/Header";
import Footer from "./Common/Footer";
import PhoneMenu from "./Common/PhoneMenu";
import SidebarMenu from "./Common/SidebarMenu";
import AddContactIcon from "./Common/assets/imgs/add-contact.svg";
import MapBlueIcon from "./Common/assets/imgs/map.svg";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import ModalPopUp from "./Common/Modal/ModalPopUp";
import EditContact from "./EditFroms/EditContact";
import { useSelector } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import {
  isTokenExpired,
  handleExpiredToken,
  handleTokenExpiryWarning,
} from "./Common/CheckJwtExpires";

export default function ContactsTable({
  handleLogout,
  onSelectRows,
  currentFilterModel,
  clientName,
  clientNameHeader,
}) {
  const clientId = useSelector((state) => state.userID.value);
  const token = localStorage.getItem("token");
  const userRole = localStorage.getItem("userRoleName");
  const [editModal, setEditModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const [contactsData, setContactsData] = useState([]);
  const [retireContactModalStatus, setRetireContactModalStatus] =
    useState(false);
  const [openSubmitModal, setOpenSubmitModal] = useState(false);
  const [alertContent, setAlertContent] = useState("");
  const baseURL = process.env.REACT_APP_API_BASE_URL;
  const [alert, setAlert] = useState(false);
  const [selectedIDs, setSelectedIDs] = useState(1);
  const [open, setOpen] = useState(true);
  const [confimModal, setConfrimModal] = useState(false);

  const handleOpenModal = () => setRetireContactModalStatus(true);
  const handleCloseModal = () => setRetireContactModalStatus(false);
  const [toggleMenus, setToggleMenus] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  useEffect(() => {
    const checkTokenExpiry = () => {
      if (isTokenExpired(token)) {
        handleExpiredToken();
      } else {
        handleTokenExpiryWarning(token);
      }
    };

    checkTokenExpiry();
    const interval = setInterval(checkTokenExpiry, 60000);

    return () => clearInterval(interval);
  }, [token]);

  const UpdatedMessageOptions = {
    title: "This contact has been retired and removed.",
    overlayClassName: "overlay-custom-class-name successMessage",
    buttons: [
      {
        label: "Close",
        className: "cancelBtn",
        onClick: () => {
          setConfrimModal(false);
          return false;
        },
      },
    ],
  };
  const options = {
    // title: "Title",
    message: "Are you sure you want to retire contact?",
    buttons: [
      {
        label: "Yes",
        className: "confimBtn",
        onClick: () => {
          setConfrimModal(false);
          retiredContactByID();
        },
      },
      {
        label: "No",
        className: "cancelBtn",
        onClick: () => {
          setConfrimModal(false);
          handleEditCloseModal();
        },
      },
    ],
    closeOnEscape: true,
    closeOnClickOutside: false,
    keyCodeForClose: [8, 32],
    willUnmount: () => {},
    afterClose: () => {},
    onClickOutside: () => {},
    onKeypress: () => {},
    onKeypressEscape: () => {},
    overlayClassName: "overlay-custom-class-name",
  };
  const handleRetireTechnician = (params) => {
    setConfrimModal(true);
    if (!confimModal) {
      confirmAlert(options);
    }
  };

  const retiredContactByID = () => {
    fetch(`${baseURL}/${clientId}/${selectedIDs}/retirecontact`)
      .then((response) => response.json())
      .then((response) => {
        confirmAlert(UpdatedMessageOptions);
        setIsUpdate(!isUpdate);
      })
      .then((result) => {
        console.log(result);
      });
  };

  const handleEditOpenModal = (data) => {
    setEditModal(true);
    setSelectedRow(data);
  };
  const handleEditCloseModal = (event, reason) => {
    if (reason !== "backdropClick") {
      setEditModal(false);
    }
  };

  useEffect(() => {}, [selectedIDs, clientId]);

  const contactsColumns = [
    { field: "ContactID", headerName: "Contact ID", editable: true, width: 75 },
    { field: "LastName", headerName: "Last Name", editable: true, width: 210 },
    {
      field: "FirstName",
      headerName: "First Name",
      editable: true,
      width: 110,
    },
    { field: "EmailAddress", headerName: "Email", editable: true, width: 125 },
    { field: "JobTitle", headerName: "Job Title", editable: true, width: 175 },
    {
      field: "BusinessPhone",
      headerName: "Business Phone",
      editable: true,
      width: 175,
    },
    {
      field: "HomePhone",
      headerName: "Home Phone",
      editable: true,
      width: 125,
    },
    {
      field: "MobilePhone",
      headerName: "Mobile Phone",
      editable: true,
      width: 125,
    },
    { field: "FaxNumber", headerName: "Fax", editable: true, width: 125 },
    {
      field: "StreetAddress",
      headerName: "Street Address",
      editable: true,
      width: 125,
    },
    { field: "City", headerName: "City", editable: true, width: 90 },
    { field: "State", headerName: "State", editable: true, width: 80 },
    {
      field: "PostalCode",
      headerName: "Postal Code",
      editable: true,
      align: "center",
      width: 110,
    },
    {
      field: "",
      width: 80,
      align: "center",
      headerName: "",
      renderCell: (params) =>
        userRole !== "user" && (
          <Tooltip
            title={
              !params.row.EmailEnabled ? (
                <div
                  style={{
                    textAlign: "center",
                    fontSize: "15px",
                    fontWeight: "600",
                    padding: "15px",
                    lineHeight: "1rem",
                  }}
                >
                  <span>Edit Contact</span>
                </div>
              ) : (
                ""
              )
            }
            PopperProps={{
              modifiers: [
                {
                  name: "maxWidth",
                  enabled: true,
                  phase: "beforeWrite",
                  fn({ state }) {
                    state.styles.popper.maxWidth = "150px";
                  },
                },
              ],
            }}
            placement="right"
            arrow
          >
            <BorderColorIcon
              onClick={() => handleEditOpenModal(params.row)}
              color="info"
              className="editIconCustom"
            />
          </Tooltip>
        ),
    },
  ];

  if (userRole !== "user") {
    contactsColumns.splice(contactsColumns.length - 1, 0, {
      field: "Retire",
      width: 120,
      headerName: "Retire Contact",
      align: "center",
      renderCell: (params) => (
        <Tooltip
          title={
            <div
              style={{
                textAlign: "center",
                fontSize: "15px",
                fontWeight: "600",
                padding: "15px",
                lineHeight: "1rem",
              }}
            >
              <span>
                Retire this contact
                <br />
                <span>[set to non-active]</span>
              </span>
            </div>
          }
          placement="left"
          arrow
        >
          <RemoveCircleIcon
            onClick={(e) => handleRetireTechnician(params)}
            color="error"
          />
        </Tooltip>
      ),
    });
  }

  useEffect(() => {
    fetch(`${baseURL}/${clientId}/contacts`)
      .then((response) => response.json())
      .then((data) => {
        setContactsData(data.data);
      });
  }, [isUpdate, clientId]);

  const editModalData = () => {
    return (
      <EditContact
        clientID={clientId}
        data={selectedRow}
        handleClose={handleEditCloseModal}
        updateSatus={setIsUpdate}
        updateCurrentState={isUpdate}
      />
    );
  };

  return (
    <>
      <Box>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            {/* Header Section */}
            <Header
              clientId={clientId}
              clientName={clientName}
              handleLogout={handleLogout}
              istoggleMenu={toggleMenus}
              clientNameHeader={clientNameHeader}
            />
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Grid container spacing={0}>
          <Grid item xs={12} md={2}>
            {/* Footer Section */}
            <PhoneMenu slug="/technicians" />
            <SidebarMenu toggleMenu={setToggleMenus} />
          </Grid>
          <Grid
            item
            xs={12}
            md={10}
            className={
              toggleMenus === true ? "tooggleActive mainContent" : "mainContent"
            }
          >
            <Container maxWidth="xlg" sx={{ mt: 8, mb: 4 }}>
              <Grid container spacing={10} className="topDueSect">
                <Grid item xs={12} sm={12} md={6} className="beforetable">
                  <Typography
                    className="SelDevice"
                    align="left"
                    variant="h4"
                    gutterBottom
                  >
                    CONTACTS
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  sx={{ textAlign: "right" }}
                  className="beforetable"
                >
                  <Link to="/map" title="View Map">
                    <img
                      src={MapBlueIcon}
                      width={80}
                      className="Icon_blue"
                      alt="deviceBlue_ICON"
                    />
                  </Link>
                  {userRole !== "admin" && userRole !== "user" && (
                    <Link to="/addcontact" title="Add Contact">
                      <img
                        src={AddContactIcon}
                        width={75}
                        className="Icon_black"
                        alt="AddCompanyIcon"
                      />
                    </Link>
                  )}
                </Grid>

                <Grid item xs={12} className="tabSect">
                  <DataGridPremium
                    className="dashboardTable"
                    getRowId={(row) => row.ContactID}
                    pagination
                    checkboxSelection={false}
                    disableMultipleRowSelection={true}
                    onRowSelectionModelChange={(ids) => {
                      setSelectedIDs(ids[0]);

                      //const selectedIDs = new Set(ids);
                      //console.log(selectedIDs)
                      // const selectedRows = contactsData.filter((row) =>
                      //   selectedIDs.has(row.ContactID)
                      // );
                    }}
                    //   onSelectRows(selectedRows);
                    //   console.log("Selected Rows", selectedRows)
                    // }}

                    // getDetailPanelHeight={getDetailPanelHeight}
                    // getDetailPanelContent={getDetailPanelContent}
                    // detailPanelExpandedRowIds={detailPanelExpandedRowIds}
                    // onDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}
                    initialState={{
                      ...contactsData.initialState,
                      pagination: { paginationModel: { pageSize: 10 } },
                      columns: {
                        columnVisibilityModel: {
                          ContactID: false,
                          HomePhone: false,
                          FaxNumber: false,
                        },
                      },
                    }}
                    pageSizeOptions={[5, 10, 25, 100]}
                    editMode="row"
                    filterModel={currentFilterModel}
                    rows={contactsData}
                    loading={contactsData.length === 0}
                    columns={contactsColumns}
                    //onFilterModelChange={(newFilterModel) => props.setFilterModelFunction(newFilterModel)}
                    components={{ Toolbar: CustomToolbar }}
                  />
                </Grid>
              </Grid>
            </Container>
          </Grid>
        </Grid>
      </Box>
      {editModal && (
        <ModalPopUp
          open={true}
          handleClose={handleEditCloseModal}
          title="Edit Contact Details"
          content=""
          formData={editModalData()}
        />
      )}
      <Footer />
    </>
  );
}
