import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import WarningIcon from "@mui/icons-material/Warning";
import { Stack, Divider } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "background.paper",
  boxShadow: "0 8px 32px rgba(0, 0, 0, 0.08)",
  borderRadius: 3,
  p: 4,
};

const warningIconStyle = {
  fontSize: 64,
  color: "#f44336",
  marginBottom: 2,
};

const buttonStyle = {
  minWidth: 120,
  fontWeight: "bold",
  borderRadius: 2,
  textTransform: "none",
  padding: "8px 24px",
};

export default function DeleteAllDataModal({
  openStatus,
  handleCloseModal,
  deleteData,
}) {
  return (
    <div>
      <Modal
        open={openStatus}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack spacing={3} alignItems="center">
            <WarningIcon sx={warningIconStyle} />

            <Typography
              variant="h5"
              component="h2"
              fontWeight="bold"
              color="error"
            >
              Delete All Data
            </Typography>

            <Divider flexItem />

            <Typography variant="body1" color="text.secondary" align="center">
              Are you sure you want to delete this client data? This action is
              irreversible and all associated data will be removed.
            </Typography>

            <Stack direction="row" spacing={2} sx={{ mt: 3 }}>
              <Button
                onClick={handleCloseModal}
                size="large"
                variant="outlined"
                color="inherit"
                sx={{
                  ...buttonStyle,
                  borderColor: "grey.300",
                  color: "text.secondary",
                  "&:hover": {
                    borderColor: "grey.400",
                    bgcolor: "grey.50",
                  },
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={deleteData}
                size="large"
                variant="contained"
                color="error"
                sx={{
                  ...buttonStyle,
                  "&:hover": {
                    bgcolor: "error.dark",
                  },
                }}
              >
                Delete Data
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
}
