import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
// import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import List from "@mui/material/List";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import FloTraceDrawer from "../FloTraceDrawer";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { SecondaryListItems, MainListItems } from "../listItems";
import { TrendingUpRounded } from "@mui/icons-material";

export default function SidebarMenu({
  handleLogout,
  clientId,
  clientName,
  toggleMenu,
}) {
  const userRole = localStorage.getItem("userRoleName");
  const [minHeight, setMinHeight] = useState();
  const [isToggleMenu, setIsToggleMenu] = useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
    if (open) {
      toggleMenu(true);
      setIsToggleMenu(TrendingUpRounded);
    } else {
      toggleMenu(false);
      setIsToggleMenu(false);
    }
  };
  const location = useLocation();
  useEffect(() => {
    var height = document.body.offsetHeight - 50;
    if (location.pathname === "/") {
      height = document.body.offsetHeight + 100;
    } else {
      height = document.body.offsetHeight - 420;
    }

    // if(
    //   location.pathname === "/addtechnician"
    //   || location.pathname === "/addcompany"
    //   || location.pathname === "/addcontact"
    //   || location.pathname === "/adddevice"
    //   || location.pathname === "/addtest"
    //   || location.pathname === "/map"
    // ){
    //   console.log("asdfasdfasdf")
    //   height = height-450;
    // }
    setMinHeight(height);
  }, []);
  const [open, setOpen] = useState(true);
  return (
    <FloTraceDrawer className="sidebarMenu" variant="permanent" open={open}>
      <Toolbar>
        <IconButton
          className={open ? "open toggleBtn" : "close toggleBtn"}
          onClick={toggleDrawer}
        >
          <ChevronLeftIcon />
        </IconButton>
      </Toolbar>

      <List
        component="nav"
        className="leftMenu"
        style={{ minHeight: minHeight }}
      >
        <div className="primary">{MainListItems(location.pathname)}</div>

        <div className="secondary">
          {SecondaryListItems(userRole, location.pathname, isToggleMenu)}
        </div>
      </List>
      {/* </Drawer> */}
    </FloTraceDrawer>
  );
}
