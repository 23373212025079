import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";
import { Link } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxHeight: "80%",
  overflowY: "scroll",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const ModalPopUp = ({
  isLoading,
  open,
  handleClose,
  title,
  content,
  formData,
  modalClass = "default",
}) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      className={modalClass}
    >
      <Box sx={style}>
        {isLoading ? (
          <Box display="flex" justifyContent="center" alignItems="center">
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Typography
              className="SelDevice"
              align="left"
              variant="h4"
              gutterBottom
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              {title}
              <Link
                onClick={handleClose}
                style={{
                  padding: "5px",
                  textDecoration: "none",
                  color: "black",
                }}
              >
                <CloseIcon
                  variant="h3"
                  style={{
                    width: "40px",
                    height: "40px",
                    paddingTop: "-10px",
                    marginTop: "-3px",
                  }}
                  className="clickIconModal"
                />
              </Link>
            </Typography>
            <Typography id="modal-description" sx={{ mt: 2 }}>
              {content}
              {formData}
            </Typography>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default ModalPopUp;
