import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  textAlign: "center",
  borderRadius: 1,
};

export default function LoadingModal(props) {
  return (
    <div>
      <Modal
        open={props.openStatus}
        onClose={props.handleModalClose}
        aria-labelledby="loading-modal-title"
        aria-describedby="loading-modal-description"
      >
        <Box sx={style}>
          <CircularProgress color="primary" />
          {props.loadingText && (
            <Typography
              id="loading-modal-description"
              sx={{ mt: 2 }}
              variant="subtitle1"
            >
              {props.loadingText}
            </Typography>
          )}
        </Box>
      </Modal>
    </div>
  );
}
