import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { TextField, Button } from "@mui/material";
import dayjs, { Dayjs } from 'dayjs';
import Alert from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import MuiAlert from "@mui/material/Alert";
import AddTestModal from "../AddTestModal";
import InputMask from "react-input-mask";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { states } from "../States";
import LoaderImage from "../../assets/images/spinner.gif";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

const EditTechnician = ({
  clientID,
  data,
  handleClose,
  updateSatus,
  updateCurrentState,
}) => {
  const baseURL = process.env.REACT_APP_API_BASE_URL;
  const clientId = clientID;
  console.log({data})
  const [formData, setFormData] = useState({
    technicianName: data?.TechName,
    firmName: data?.FirmName,
    certTesterNumber: data?.CertTestNumber,
    certExpirationDate: dayjs(data?.ExpiredDate),
    streetAddress: data?.StreetAddress,
    city: data?.City,
    state: data?.State,
    postalCode: data?.PostalCode,
    workPhoneOne: data?.WorkPhone1,
    workPhoneTwo: data?.WorkPhone2,
    mobilePhone: data?.MobilePhone,
  });
  const [deviceSerialNumbers, setDeviceSerialNumbers] = useState([]);
  const [deviceID, setDeviceID] = useState([]);
  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState("");
  const [openSubmitModal, setOpenSubmitModal] = useState(false);
  const [deviceScheduledTestDate, setDeviceScheduledTestDate] = useState("");
  const [selectedDeviceID, setSelectedDeviceID] = useState("");

  const handleOpenSubmitModal = () => setOpenSubmitModal(true);
  const handleCloseSubmitModal = () => setOpenSubmitModal(false);

  const [workPhoneOneIsValid, setWorkPhoneOneIsValid] = useState(false);
  const [workPhoneTwoIsValid, setWorkPhoneTwoIsValid] = useState(false);
  const [mobilePhoneIsValid, setMobilePhoneIsValid] = useState(false);
  const [formIsValid, setFormIsValid] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [confimModal, setConfrimModal] = useState(false);

  useEffect(() => {
    data.WorkPhone1 && setWorkPhoneOneIsValid(true);
  }, [data]);

  useEffect(() => {
    // console.log("bisphone", businessPhoneIsValid)
    // console.log("home", homePhoneIsValid)
    // console.log("mobile", mobilePhoneIsValid)
    // console.log("fax", faxIsValid)

    if (workPhoneOneIsValid) {
      setFormIsValid(true);
    } else {
      setFormIsValid(false);
    }
  }, [workPhoneOneIsValid]);

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const UpdatedMessageOptions = {
    title: "This technician record has been updated.",
    overlayClassName: "overlay-custom-class-name successMessage",
    buttons: [
      {
        label: "Close",
        className: "cancelBtn",
        onClick: () => {
          setConfrimModal(false);
          return false;
        },
      },
    ],
  };

  const updateTechnician = (e) => {
    setShowLoader(true);
    const token = localStorage.getItem("token");
    const techID = data?.TechID;
    const form_Data = JSON.stringify(formData);
    axios({
      method: "PUT",
      url: `${baseURL}/1/edittechnician/${techID}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: formData,
    })
      .then((response) => {
        if (response.data.success) {
          setShowLoader(false);
          updateSatus(!updateCurrentState);
          handleClose();
          confirmAlert(UpdatedMessageOptions);        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      });

    e.preventDefault();
  };

  const options = {
    // title: "Title",
    message: "Are you sure you want to cancel? Any changes will not be saved.",
    buttons: [
      {
        label: "NO",
        className: "confimBtn",
        onClick: () => {
          setConfrimModal(false);
          return false;
        },
      },
      {
        label: "YES",
        className: "cancelBtn",
        onClick: () => {
          setConfrimModal(false);
          handleClose();
        },
      },
    ],
    closeOnEscape: true,
    closeOnClickOutside: false,
    keyCodeForClose: [8, 32],
    willUnmount: () => {},
    afterClose: () => {},
    onClickOutside: () => {},
    onKeypress: () => {},
    onKeypressEscape: () => {},
    overlayClassName: "overlay-custom-class-name",
  };

  const handleConfirmationBox = () => {
    setConfrimModal(true);
    if (!confimModal) {
      confirmAlert(options);
    }
  };

  return (
    <>
      <Box>
        {showLoader && (
          <Grid item className="loaderMain">
            <img src={LoaderImage} alt="loaderImg" />
          </Grid>
        )}
        <Grid item xs={12} className="tabSect" sx={{ mt: 4 }}>
          {/* <Typography>* Denotes a required field</Typography> */}
          <Box
            align="left"
            sx={{mt: 4,"& .MuiTextField-root": { m: 1, width: "30ch" },}}
            autoComplete="off"
          >
            <Typography component="h6">Technician Details</Typography>
            <Box component="form">
              <Grid sx={{ mt: 2 }}>
                <Grid>
                  <TextField
                    error={formData.technicianName == ""}
                    id="outlined-select-currency"
                    style={{ width: 500 }}
                    // label="Technician Name"
                    required
                    helperText="First and last name (eg. John Doe)"
                    value={formData.technicianName}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        technicianName: e.target.value,
                      })
                    }
                  ></TextField>

                  <TextField
                    error={formData.firmName == ""}
                    id="outlined-select-currency"
                    style={{ width: 500 }}
                    required
                    // label="Technician Firm Name"
                    helperText="Certification Firm Name"
                    value={formData.firmName}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        firmName: e.target.value,
                      })
                    }
                  ></TextField>

                  <TextField
                    error={formData.certTesterNumber == ""}
                    id="outlined-select-currency"
                    style={{ width: 270 }}
                    required
                    // label="Certification Tester Number"
                    helperText="Certification Tester Number"
                    value={formData.certTesterNumber}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        certTesterNumber: e.target.value,
                      })
                    }
                  ></TextField>

                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DatePicker"]}>
                      <DatePicker
                        style={{ width: 300 }}
                        label="Certification Expiration Date"
                        value={formData.certExpirationDate}
                        onChange={(date) => {
                          setFormData({
                            ...formData,
                            certExpirationDate: dayjs(date.format("MM/DD/YYYY")),
                          });
                        }}
                        slotProps={{
                          textField: {
                            required: true,
                            error: formData.certExpirationDate == "",
                          },
                        }}
                        // value={formData.certExpirationDate}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </Grid>
                <Typography mt={3} component="h6">
                  Certification Firm Details
                </Typography>
                <Grid>
                  <TextField
                    required
                    error={formData.streetAddress == ""}
                    id="outlined-select-currency"
                    style={{ width: 500 }}
                    // label="Firm Street Address"
                    helperText="Address (e.g. 123 Main St)"
                    value={formData.streetAddress}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        streetAddress: e.target.value,
                      })
                    }
                  ></TextField>

                  <TextField
                    id="outlined-select-currency"
                    error={formData.city == ""}
                    style={{ width: 275 }}
                    required
                    // label="City"
                    helperText="City"
                    value={formData.city}
                    onChange={(e) =>
                      setFormData({ ...formData, city: e.target.value })
                    }
                  ></TextField>

                  <TextField
                    error={formData.state == ""}
                    id="outlined-select-currency"
                    select
                    required
                    //style = {{width: 260}}
                    // label="State"
                    helperText="State (eg. TX)"
                    value={formData.state}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        state: e.target.value,
                      })
                    }
                  >
                    {states.map((option) => (
                      <MenuItem key={option.id} value={option.state}>
                        {option.state}
                      </MenuItem>
                    ))}
                  </TextField>

                  <InputMask
                    mask="99999-9999"
                    value={formData.postalCode}
                    disabled={false}
                    maskChar=""
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        postalCode: e.target.value,
                      })
                    }
                  >
                    {() => (
                      <TextField
                        error={
                          (formData.postalCode &&
                            formData?.postalCode
                              .toString()
                              .replace(/[^0-9]/g, "").length < 5) ||
                          (formData.postalCode &&
                            formData?.postalCode
                              .toString()
                              .replace(/[^0-9]/g, "").length > 5 &&
                            formData?.postalCode
                              .toString()
                              .replace(/[^0-9]/g, "").length < 9) ||
                            formData?.postalCode == "" 
                        }
                        helperText={
                          (formData?.postalCode &&
                            formData?.postalCode
                              .toString()
                              .replace(/[^0-9]/g, "").length < 5) ||
                          (formData.postalCode &&
                            formData?.postalCode
                              .toString()
                              .replace(/[^0-9]/g, "").length > 5 &&
                            formData?.postalCode
                              .toString()
                              .replace(/[^0-9]/g, "").length < 9)
                            ? "Zipcode (must be either 5 or 9 digits)"
                            : "Zipcode (must be either 5 or 9 digits)"
                        }
                        id="outlined-select-currency"
                        style={{ width: 275 }}
                        required
                        // label="Postal Code"
                        // helperText="Type 5-digit postal code e.g. 12345"
                        value={formData.postalCode}
                      ></TextField>
                    )}
                  </InputMask>
                </Grid>
                <Typography component="h6" mt={3}>
                  Technician Contact Details
                </Typography>
                <Grid>
                  <InputMask
                    mask="(999) 999-9999"
                    value={formData.workPhoneOne}
                    disabled={false}
                    maskChar=" "
                    //onChange={e => setFormData({...formData, workPhoneOne:e.target.value})}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        workPhoneOne: e.target.value,
                      });
                      if (e.target.value || formData.workPhoneOne) {
                        let digitLength = e.target.value
                          .toString()
                          .replace(/[^0-9]/g, "").length;
                        if (digitLength < 10) {
                          setWorkPhoneOneIsValid(false);
                        } else {
                          setWorkPhoneOneIsValid(true);
                        }
                      }
                    }}
                  >
                    {() => (
                      <TextField
                        error={
                          formData.workPhoneOne &&
                          formData.workPhoneOne
                            .toString()
                            .replace(/[^0-9]/g, "").length < 10 ||
                            formData.workPhoneOne ==""
                        }
                        helperText={
                          formData.workPhoneOne &&
                          formData.workPhoneOne
                            .toString()
                            .replace(/[^0-9]/g, "").length < 10
                            ? "Work Phone 1 phone (must be 10 digits)"
                            : "Work Phone 1 phone (must be 10 digits)"
                        }
                        required
                        // label="Work Phone 1"
                        //helperText="Type primary work phone number"
                        value={formData.workPhoneOne}
                      ></TextField>
                    )}
                  </InputMask>

                  <InputMask
                    mask="(999) 999-9999"
                    value={formData.workPhoneTwo}
                    disabled={false}
                    maskChar=" "
                    //onChange={e => setFormData({...formData, workPhoneTwo:e.target.value})}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        workPhoneTwo: e.target.value,
                      });

                      if (e.target.value || formData.workPhoneTwo) {
                        let digitLength = e.target.value
                          .toString()
                          .replace(/[^0-9]/g, "").length;
                        if (digitLength < 10) {
                          setWorkPhoneTwoIsValid(false);
                        } else {
                          setWorkPhoneTwoIsValid(true);
                        }
                      }
                    }}
                  >
                    {() => (
                      <TextField
                        error={
                          formData.workPhoneTwo &&
                          formData?.workPhoneTwo
                            .toString()
                            .replace(/[^0-9]/g, "").length < 10
                        }
                        helperText={
                          formData.workPhoneTwo &&
                          formData?.workPhoneTwo
                            .toString()
                            .replace(/[^0-9]/g, "").length < 10
                            ? "Work phone 2 (must be 10 digits)"
                            : "Work phone 2 (must be 10 digits)"
                        }
                        id="outlined-select-currency"
                        style={{ width: 275 }}
                        // label="Work Phone 2"
                        // helperText="Type secondary work phone number"
                        value={formData?.workPhoneTwo}
                      ></TextField>
                    )}
                  </InputMask>

                  <InputMask
                    mask="(999) 999-9999"
                    value={formData.mobilePhone}
                    disabled={false}
                    maskChar=" "
                    //onChange={e => setFormData({...formData, mobilePhone:e.target.value})}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        mobilePhone: e.target.value,
                      });
                      if (e.target.value || formData.mobilePhone) {
                        let digitLength = e.target.value
                          .toString()
                          .replace(/[^0-9]/g, "").length;
                        if (digitLength < 10) {
                          setMobilePhoneIsValid(false);
                        } else {
                          setMobilePhoneIsValid(true);
                        }
                      }
                    }}
                  >
                    {() => (
                      <TextField
                        error={
                          formData.mobilePhone &&
                          formData.mobilePhone.toString().replace(/[^0-9]/g, "")
                            .length < 10
                        }
                        helperText={
                          formData.mobilePhone &&
                          formData.mobilePhone.toString().replace(/[^0-9]/g, "")
                            .length < 10
                            ? "Mobile phone (must be 10 digits)"
                            : "Mobile phone (must be 10 digits)"
                        }
                        id="outlined-select-currency"
                        style={{ width: 275 }}
                        // label="Mobile Phone"
                        value={formData.mobilePhone}
                      ></TextField>
                    )}
                  </InputMask>
                </Grid>
              </Grid>

              <Grid>
                <Box sx={{ display: "inline-flex" }}>
                  <AddTestModal
                    submitTestFileAndData={updateTechnician}
                    openStatus={openSubmitModal}
                    handleModalClose={handleCloseSubmitModal}
                    alertContentText={alertContent}
                  />
                  <Button
                    disabled={formIsValid ? false : true}
                    type="submit"
                    variant="contained"
                    size="large"
                    color="primary"
                    sx={{
                      ml: 1,
                      mr: 6,
                      mt: 4,
                      borderRadius: 8,
                      width: 225,
                    }}
                    onClick={updateTechnician}
                  >
                    Submit
                  </Button>
                  <Button
                    size="large"
                    variant="contained"
                    sx={{
                      mr: 6,
                      mt: 4,
                      borderRadius: 8,
                      borderRadius: 8,
                      width: 225,
                    }}
                    onClick={handleConfirmationBox}
                    color="inherit"
                  >
                    Cancel
                  </Button>
                </Box>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Box>
    </>
  );
};

export default EditTechnician;
