import { jwtDecode } from "jwt-decode";
import { confirmAlert } from "react-confirm-alert";

const baseURL = process.env.REACT_BASE_URL;

export function isTokenExpired(token) {
  if (!token) {
    return true;
  }

  try {
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000; // Get current time in seconds
    return decodedToken.exp < currentTime; // Compare token's exp with current time
  } catch (error) {
    return true; // If decoding fails, treat the token as expired
  }
}

const redirectToBaseUrl = () => {
  window.location.href = "https://dev-flo-trace-app-tbnru.ondigitalocean.app/"; // Redirects to the base URL
};

export function handleExpiredToken() {
  confirmAlert({
    title: "Session Expired",
    message: (
      <div>
        Your login session has expired.
        <br />
        Please reload the page to log in again.
      </div>
    ),
    buttons: [
      {
        label: "Reload",
        onClick: () => {
          localStorage.clear();
          redirectToBaseUrl();
        },
      },
    ],
    closeOnEscape: false,
    closeOnClickOutside: false,
    overlayClassName: "overlay-custom-class-name",
  });
}

export function handleTokenExpiryWarning(token) {
  if (!token) return;
  console.log("Checking warning");

  try {
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    const timeUntilExpiry = decodedToken.exp - currentTime;
    const warningTime = timeUntilExpiry - 120;
    console.log(warningTime, " warnied");
    if (warningTime > 0) {
      console.log(" warnied");
      setTimeout(() => {
        confirmAlert({
          title: "Session Expiry Warning",
          message:
            "Your session will expire in 2 minutes. Would you like to relogin now or continue?",
          buttons: [
            {
              label: "Login Again",
              style: { backgroundColor: "#1976d2", color: "white" },
              onClick: () => {
                localStorage.clear();
                redirectToBaseUrl();
              },
            },
            {
              label: "Close",
              onClick: () => {
                // Just close the popup, user can continue browsing
              },
            },
          ],
          closeOnEscape: false,
          closeOnClickOutside: false,
          overlayClassName: "overlay-custom-class-name",
        });
      }, warningTime * 1000); // Set timeout to show warning 2 minutes before expiry
    }
  } catch (error) {
    console.error("Error decoding token:", error);
  }
}
