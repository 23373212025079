import React, { useState, useEffect, useMemo } from "react";
import {
  TextField,
  Button,
  FormControl,
  IconButton,
  OutlinedInput,
  InputAdornment,
  InputLabel,
  MenuItem,
} from "@mui/material";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import InputMask from "react-input-mask";
import MuiAlert from "@mui/material/Alert";
import AddTestModal from "../AddTestModal";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Container from "@mui/material/Container";
import { confirmAlert } from "react-confirm-alert";

const EditUser = ({
  role,
  isProfile,
  clientID,
  data,
  handleClose,
  updateSatus,
  updateCurrentState,
}) => {
  console.log({ data }, "data in edit");
  const [open, setOpen] = useState(true);
  const [formData, setFormData] = useState({
    ClientID: clientID,
    UserFirstName: data?.UserFirstName,
    UserLastName: data?.UserLastName,
    UserEMail: data?.EmailAddress,
    UserPhone: data?.MobilePhone,
    Password: data?.Password.trim(),
    Role: data?.Role,
  });
  const [confimModal, setConfrimModal] = useState(false);
  const [isValidForm, setIsValidFrom] = useState(false);
  const [deviceID, setDeviceID] = useState([]);
  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState("");
  const [openSubmitModal, setOpenSubmitModal] = useState(false);
  const [, setDeviceScheduledTestDate] = useState("");

  const [password, setPassword] = useState(data?.Password.trim());
  const [confirmPassword, setConfirmPassword] = useState(data?.Password.trim());
  const [confirmPass, setConfrimPass] = useState(false);

  const [companyNameDupeExists, setCompanyNameDupeExists] = useState(false);

  const handleOpenSubmitModal = () => setOpenSubmitModal(true);
  const handleCloseSubmitModal = () => setOpenSubmitModal(false);
  const [toggleMenus, setToggleMenus] = useState(false);

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const [showPassword, setShowPassword] = React.useState(false);
  const [showPasswordConfrm, setShowPasswordConfrm] = React.useState(false);

  const handleClickShowPassword = (type = "pass") => {
    type === "confirm"
      ? setShowPasswordConfrm((show) => !show)
      : setShowPassword((show) => !show);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleMouseUpPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    console.log({ formData });

    if (!isProfile) {
      if (
        formData.UserFirstName !== "" &&
        formData.UserLastName !== "" &&
        formData.UserEMail !== "" &&
        formData.Password === confirmPassword &&
        // formData.Password === password &&
        formData.Role !== ""
      ) {
        setIsValidFrom(true);
      } else {
        setIsValidFrom(false);
      }
    } else {
      if (
        formData.UserFirstName !== "" &&
        formData.UserLastName !== "" &&
        formData.Password === confirmPassword
      ) {
        setIsValidFrom(true);
      } else {
        setIsValidFrom(false);
      }
    }
  }, [formData, confirmPass, confirmPassword]);

  // useEffect(() => {
  //   fetch(
  //     `https://flo-trace-api-prod-ugyxg.ondigitalocean.app/${clientId}/${formData.companyName.replace(
  //       / /g,
  //       "%20"
  //     )}/checkcompanyduplicates`
  //   )
  //     .then((response) => response.json())
  //     .then((data) => {
  //       if (data.response === "Company with this name already exists.") {
  //         setCompanyNameDupeExists(true);
  //         console.log("setting state", companyNameDupeExists);
  //       } else {
  //         setCompanyNameDupeExists(false);
  //         console.log("Not a dupe");
  //       }
  //     });
  // }, [formData.companyName]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const baseURL = process.env.REACT_APP_API_BASE_URL;
    const token = localStorage.getItem("token");

    fetch(`${baseURL}/users/${data?.UserID}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        // Check if response is ok (status in the range of 200–299)
        if (!response.ok) {
          return response.json().then((errorData) => {
            throw new Error(errorData.message || "An error occurred");
          });
        }
        return response.json();
      })
      .then(() => {
        handleOpenSubmitModal();
        updateCurrentState(!updateSatus);
        handleClose();
        confirmAlert(UpdatedMessageOptions);
      })
      .catch((error) => {
        // Handle errors here
        confirmAlert({
          title: "Error",
          message: error.message || "An unexpected error occurred",
          overlayClassName: "overlay-custom-class-name errorMessage",
          buttons: [
            {
              label: "Close",
              className: "cancelBtn",
              onClick: () => {
                setConfrimModal(false);
                return false;
              },
            },
          ],
        });
      });
  };

  // Updated success message options
  const UpdatedMessageOptions = {
    title: "This user record has been updated.",
    overlayClassName: "overlay-custom-class-name successMessage",
    buttons: [
      {
        label: "Close",
        className: "cancelBtn",
        onClick: () => {
          setConfrimModal(false);
          return false;
        },
      },
    ],
  };

  const options = {
    // title: "Title",
    message: `Are you sure you want to cancel? \n Any changes will not be saved.`,
    buttons: [
      {
        label: "NO",
        className: "confimBtn",
        onClick: () => {
          setConfrimModal(false);
          return false;
        },
      },
      {
        label: "YES",
        className: "cancelBtn",
        onClick: () => {
          setConfrimModal(false);
          handleClose();
        },
      },
    ],
    closeOnEscape: true,
    closeOnClickOutside: false,
    keyCodeForClose: [8, 32],
    willUnmount: () => {},
    afterClose: () => {},
    onClickOutside: () => {},
    onKeypress: () => {},
    onKeypressEscape: () => {},
    overlayClassName: "overlay-custom-class-name",
  };

  const handleConfirmationBox = () => {
    setConfrimModal(true);
    if (!confimModal) {
      confirmAlert(options);
    }
  };

  //   useMemo(()=>{
  //     setTimeout(
  //         () => setIsValidFrom(true),
  //         10000
  //       );
  //     // setIsValidFrom(true)
  //   },[formData])

  return (
    <>
      <Box>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Container maxWidth="xlg" sx={{ mt: 4, mb: 4 }}>
              <Grid container spacing={10} className="topDueSect">
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  sx={{ textAlign: "right" }}
                  className="beforetable"
                ></Grid>

                <Grid item xs={12} className="tabSect">
                  <Box
                    align="left"
                    sx={{
                      mt: 1,
                      "& .MuiTextField-root": { m: 1, width: "30ch" },
                    }}
                    autoComplete="off"
                  >
                    <h3>User Details</h3>
                    <Box component="form" onSubmit={handleSubmit}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <TextField
                            //error={formData.companyName === ''}
                            error={formData?.companyName === ""}
                            id="firstName"
                            style={{ width: "100%" }}
                            //label="Company Name"
                            required
                            helperText="First Name"
                            value={formData?.UserFirstName}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                UserFirstName: e.target.value,
                              })
                            }
                          ></TextField>
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            id="lastName"
                            style={{ width: "100%" }}
                            //label="Website"
                            helperText="Last Name"
                            value={formData?.UserLastName}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                UserLastName: e.target.value,
                              })
                            }
                            required
                          ></TextField>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        {!isProfile && (
                          <Grid item xs={6}>
                            <TextField
                              id="email"
                              style={{ width: "100%" }}
                              //label="Owner"
                              helperText="Email"
                              value={formData?.UserEMail}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  UserEMail: e.target.value,
                                })
                              }
                              required
                            ></TextField>
                          </Grid>
                        )}
                        <Grid item xs={6}>
                          <InputMask
                            mask="(999) 999-9999"
                            value={formData?.UserPhone}
                            disabled={false}
                            maskChar=" "
                            onChange={(e) => {
                              const phoneValue = e.target.value;
                              const digitLength = phoneValue.replace(
                                /[^0-9]/g,
                                ""
                              ).length;
                              const numericValue = e.target.value.replace(
                                /[^0-9]/g,
                                ""
                              );

                              setFormData({
                                ...formData,
                                UserPhone: numericValue,
                              });
                            }}
                          >
                            {() => (
                              <TextField
                                id="phone"
                                style={{ width: "100%" }}
                                helperText={
                                  formData?.UserPhone &&
                                  formData?.UserPhone.replace(/[^0-9]/g, "")
                                    .length < 10
                                    ? "Phone must be at least 10 digits"
                                    : "Phone"
                                }
                                autoComplete="off"
                                inputProps={{
                                  autoComplete: "off",
                                }}
                                value={formData?.UserPhone}
                              />
                            )}
                          </InputMask>
                        </Grid>

                        <Grid item xs={4}>
                          <FormControl
                            sx={{ m: 1, width: "100%" }}
                            variant="outlined"
                          >
                            <InputLabel htmlFor="outlined-adornment-password">
                              Password
                            </InputLabel>
                            <OutlinedInput
                              id="outlined-adornment-password"
                              type={showPassword ? "text" : "password"}
                              value={password}
                              onChange={(e) => {
                                setPassword(e.target.value);
                                setFormData({
                                  ...formData,
                                  Password: e.target.value,
                                });
                                formData.Password !== e.target.value
                                  ? setConfrimPass(false)
                                  : setConfrimPass(true);
                              }}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    onMouseUp={handleMouseUpPassword}
                                    edge="end"
                                  >
                                    {showPassword ? (
                                      <VisibilityOff />
                                    ) : (
                                      <Visibility />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              }
                              label="Password"
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                          <FormControl
                            sx={{ m: 1, width: "100%" }}
                            variant="outlined"
                          >
                            <InputLabel htmlFor="outlined-adornment-password">
                              Confrim Password
                            </InputLabel>
                            <OutlinedInput
                              id="outlined-adornment-password-confrim"
                              type={showPasswordConfrm ? "text" : "password"}
                              error={confirmPassword !== formData?.Password}
                              value={confirmPassword}
                              onChange={(e) => {
                                setConfirmPassword(e.target.value);

                                password !== e.target.value
                                  ? setConfrimPass(false)
                                  : setConfrimPass(true);
                              }}
                              required
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={(e) =>
                                      handleClickShowPassword("confirm")
                                    }
                                    onMouseDown={handleMouseDownPassword}
                                    onMouseUp={handleMouseUpPassword}
                                    edge="end"
                                  >
                                    {showPasswordConfrm ? (
                                      <VisibilityOff />
                                    ) : (
                                      <Visibility />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              }
                              label="Confrim Password"
                            />
                          </FormControl>
                        </Grid>
                        {!isProfile && role !== "admin" && (
                          <Grid item xs={4}>
                            <TextField
                              select
                              //error={formData.companyName === ''}
                              error={formData.companyName === ""}
                              id="role"
                              style={{ width: "100%" }}
                              //label="Company Name"
                              required
                              helperText="Role"
                              value={formData.Role}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  Role: e.target.value,
                                })
                              }
                            >
                              {role === "masteradmin" && (
                                <MenuItem key={"edit_1"} value="masteradmin">
                                  Master admin
                                </MenuItem>
                              )}

                              <MenuItem key={"edit_2"} value="superadmin">
                                Super admin
                              </MenuItem>
                              <MenuItem key={"edit_3"} value="admin">
                                Admin
                              </MenuItem>
                              <MenuItem key={"edit_4"} value="user">
                                User
                              </MenuItem>

                              {/* <MenuItem key={3} value="masteradmin">Master Admin</MenuItem> */}
                            </TextField>
                          </Grid>
                        )}
                      </Grid>
                      <Grid>
                        <Box sx={{ display: "inline-flex" }}>
                          <AddTestModal
                            submitTestFileAndData={handleSubmit}
                            openStatus={openSubmitModal}
                            handleModalClose={handleCloseSubmitModal}
                            alertContentText={alertContent}
                          />
                          <Button
                            type="submit"
                            variant="contained"
                            disabled={isValidForm ? false : true}
                            size="large"
                            color="info"
                            sx={{
                              ml: 1,
                              mr: 6,
                              mt: 4,
                              borderRadius: 8,
                              width: 225,
                            }}
                          >
                            Submit
                          </Button>
                          <Button
                            size="large"
                            onClick={handleConfirmationBox}
                            variant="contained"
                            sx={{
                              mr: 6,
                              mt: 4,
                              borderRadius: 8,
                              borderRadius: 8,
                              width: 225,
                            }}
                            color="inherit"
                          >
                            Cancel
                          </Button>
                        </Box>
                      </Grid>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default EditUser;
