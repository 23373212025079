import React, { useState, useEffect, useMemo } from "react";
import Title from "./Title";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import moment from "moment";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import UserCustomToolbar from "./UserCustomToolbar";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { Checkbox, Tooltip } from "@mui/material";
import Container from "@mui/material/Container";
import PhoneMenu from "./Common/PhoneMenu";
import SidebarMenu from "./Common/SidebarMenu";
import Header from "./Common/Header";
import Footer from "./Common/Footer";
import AddContactIcon from "./Common/assets/imgs/add-contact.svg";
import MapBlueIcon from "./Common/assets/imgs/map.svg";
import ModalPopUp from "./Common/Modal/ModalPopUp";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import EditUser from "./EditFroms/EditUser";
import { useSelector } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import EmailIcon from "@mui/icons-material/Email";
import MessageIcon from "@mui/icons-material/Message";

import {
  isTokenExpired,
  handleExpiredToken,
  handleTokenExpiryWarning,
} from "./Common/CheckJwtExpires";

export default function Users({
  handleLogout,
  onSelectRows,
  currentFilterModel,
  // clientId,
  clientName,
  clientNameHeader,
}) {
  const clientId = useSelector((state) => state.userID.value);
  const userRole = localStorage.getItem("userRoleName");
  const [editModal, setEditModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const [usersData, setUsersData] = useState([]);
  const [open, setOpen] = useState(true);
  const [selectedIDs, setSelectedIDs] = useState(1);
  const [retireTechnicianModalStatus, setRetireTechnicianModalStatus] =
    useState(false);
  const [openSubmitModal, setOpenSubmitModal] = useState(false);
  const [alertContent, setAlertContent] = useState("");
  const [alert, setAlert] = useState(false);
  var seletedUSerID = null;
  var selectedUserFirstName = null;
  var seletedUserLastName = null;

  const handleOpenModal = () => setRetireTechnicianModalStatus(true);
  const handleCloseModal = () => setRetireTechnicianModalStatus(false);
  const [toggleMenus, setToggleMenus] = useState(false);
  const [confimModal, setConfrimModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const options = {
    // title: "Title",
    message: "Are you sure you want to retire this user?",
    buttons: [
      {
        label: "Yes",
        className: "confimBtn",
        onClick: () => {
          setConfrimModal(false);
          retiredUserByID(seletedUSerID);
        },
      },
      {
        label: "No",
        className: "cancelBtn",
        onClick: () => {
          setConfrimModal(false);
          handleEditCloseModal();
        },
      },
    ],
    closeOnEscape: true,
    closeOnClickOutside: false,
    keyCodeForClose: [8, 32],
    willUnmount: () => {},
    afterClose: () => {},
    onClickOutside: () => {},
    onKeypress: () => {},
    onKeypressEscape: () => {},
    overlayClassName: "overlay-custom-class-name",
  };

  const handleRetireUser = (data) => {
    seletedUSerID = data.UserID;
    setConfrimModal(true);
    if (!confimModal) {
      confirmAlert(options);
    }
  };
  const handleEmailEnable = (data) => {
    selectedUserFirstName = data.UserFirstName;
    seletedUserLastName = data.UserLastName;
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditOpenModal = (data) => {
    setEditModal(true);
    setSelectedRow(data);
  };
  const handleEditCloseModal = (event, reason) => {
    if (reason !== "backdropClick") {
      setEditModal(false);
    }
  };
  const [isUpdate, setIsUpdate] = useState(false);
  const token = localStorage.getItem("token");
  const baseURL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const checkTokenExpiry = () => {
      if (isTokenExpired(token)) {
        handleExpiredToken();
      } else {
        handleTokenExpiryWarning(token);
      }
    };

    checkTokenExpiry();
    const interval = setInterval(checkTokenExpiry, 60000);

    return () => clearInterval(interval);
  }, [token]);

  const UpdatedMessageOptions = {
    title: "This user record has been removed successfully.",
    overlayClassName: "overlay-custom-class-name successMessage",
    buttons: [
      {
        label: "Close",
        className: "cancelBtn",
        onClick: () => {
          setConfrimModal(false);
          return false;
        },
      },
    ],
  };
  const removeUSerByID = () => {
    console.log("usertID: ", selectedIDs);
    fetch(`${baseURL}/users/${selectedIDs}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json",
      },
    })
      .then((response) => response.json())
      .then(() => {
        handleClose();
        confirmAlert(UpdatedMessageOptions);
        setIsUpdate(!isUpdate);
      })
      .then((result) => {
        console.log(result);
      });
  };

  const handleConfirmationBox = (userID) => {
    setConfrimModal(true);
    const options = {
      // title: "Title",
      message: `Are you sure you want to remove this user.`,
      buttons: [
        {
          label: "NO",
          className: "cancelBtn",
          onClick: () => {
            setConfrimModal(false);
            return false;
          },
        },
        {
          label: "YES",
          className: "confimBtn",
          onClick: () => {
            removeUSerByID();
            confirmAlert(UpdatedMessageOptions);
            setConfrimModal(false);
            handleClose();
          },
        },
      ],
      closeOnEscape: true,
      closeOnClickOutside: false,
      keyCodeForClose: [8, 32],
      willUnmount: () => {},
      afterClose: () => {},
      onClickOutside: () => {},
      onKeypress: () => {},
      onKeypressEscape: () => {},
      overlayClassName: "overlay-custom-class-name",
    };

    if (!confimModal) {
      confirmAlert(options);
    }
  };

  const handleEmailEnabled = (data, currentState) => {
    fetch(`${baseURL}/${clientId}/edit/notification/email/enable`, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        firstName: data?.UserFirstName,
        lastName: data?.UserLastName,
        IsEmailEnabled: !currentState,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        fetchUsers();
        console.log(result);
      });
  };
  const handlePhoneEnabled = (data, currentState) => {
    fetch(`${baseURL}/${clientId}/edit/notification/phone/enable`, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        firstName: data?.UserFirstName,
        lastName: data?.UserLastName,
        isPhoneNumberEnabled: !currentState,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        fetchUsers();
        console.log(result);
      });
  };

  const techniciansColumns = [
    {
      field: "UserFirstName",
      headerName: "First Name",
      editable: true,
      width: 120,
    },
    {
      field: "UserLastName",
      headerName: "Last Name",
      editable: true,
      width: 120,
    },
    {
      field: "Password",
      headerName: "Password",
      editable: true,
      width: 120,
      renderCell: (params) => "•••••••••",
      renderEditCell: (params) => (
        <input
          type="password"
          value={params.value || ""}
          onChange={(e) =>
            params.api.setEditCellValue({
              id: params.id,
              field: params.field,
              value: e.target.value,
            })
          }
          style={{ width: "100%" }}
        />
      ),
    },
    { field: "EmailAddress", headerName: "Email", editable: true, width: 190 },
    {
      field: "MobilePhone",
      headerName: "Mobile",
      editable: true,
      width: 120,
    },
    {
      field: "Role",
      headerName: "Role",
      editable: true,
      type: "number",
      width: 180,
      headerAlign: "center",
      align: "center",
      textAlign: "center",
    },
    {
      field: "",
      headerName: "",
      width: 100,
      align: "center",
      headerAlign: "center",
      textAlign: "center",
      renderCell: (params) =>
        userRole !== "user" && (
          <Tooltip
            title={
              <div
                style={{
                  textAlign: "center",
                  fontSize: "15px",
                  fontWeight: "600",
                  padding: "15px",
                  lineHeight: "1rem",
                }}
              >
                <span>Edit user</span>
              </div>
            }
            placement="right"
            arrow
          >
            <BorderColorIcon
              onClick={() => handleEditOpenModal(params.row)}
              color="info"
              className="editIconCustom"
            />
          </Tooltip>
        ),
    },
  ];

  if (userRole !== "user") {
    techniciansColumns.splice(techniciansColumns.length - 1, 0, {
      field: "isRetired",
      width: 100,
      headerAlign: "center",
      align: "center",
      cursor: "pointer",
      headerName: "Retire User",
      renderCell: (params) => (
        <Tooltip
          title={
            <div
              style={{
                textAlign: "center",
                fontSize: "15px",
                fontWeight: "600",
                padding: "15px",
                lineHeight: "1rem",
              }}
            >
              <span>
                Retire this user
                <br />
                <span>[set to non-active]</span>
              </span>
            </div>
          }
          placement="left"
          arrow
        >
          <RemoveCircleIcon
            style={{ cursor: "pointer" }}
            onClick={(e) => handleRetireUser(params.row)}
            color="error"
          />
        </Tooltip>
      ),
      renderHeader: (params) => (
        <strong>
          {"Retire"}
          <br />
          {"User"}
        </strong>
      ),
    });
  }
  if (userRole !== "user") {
    techniciansColumns.splice(techniciansColumns.length, 0, {
      field: "methodEnabled", // Placeholder field to group the columns visually
      headerName: "Notification",
      width: 130,
      headerAlign: "center",

      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",

            width: "100%",
          }}
        >
          <div style={{ marginBottom: "8px" }}>
            <strong>Notification</strong>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <EmailIcon />
            <MessageIcon />
          </div>
        </div>
      ),
      sortable: false,
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Tooltip
            title={
              !params.row.EmailEnabled ? (
                <div
                  style={{
                    textAlign: "center",
                    fontSize: "15px",
                    fontWeight: "600",
                    padding: "15px",
                    lineHeight: "1rem",
                  }}
                >
                  <span>Receive email notifications</span>
                </div>
              ) : (
                ""
              )
            }
            PopperProps={{
              modifiers: [
                {
                  name: "maxWidth",
                  enabled: true,
                  phase: "beforeWrite",
                  fn({ state }) {
                    state.styles.popper.maxWidth = "160px";
                  },
                },
              ],
            }}
            placement="right"
            arrow
          >
            <Checkbox
              checked={params.row.EmailEnabled}
              style={{ cursor: "pointer", marginRight: "12px" }}
              onClick={() =>
                handleEmailEnabled(params.row, params.row.EmailEnabled)
              }
              color="success"
            />
          </Tooltip>
          {!params.row.MobilePhone || params.row.MobilePhone?.length !== 10 ? (
            <Tooltip
              title={
                <div
                  style={{
                    textAlign: "center",
                    fontSize: "15px",
                    fontWeight: "600",
                    padding: "15px",
                    lineHeight: "1rem",
                  }}
                >
                  <span>
                    Please add a valid phone number to enable phone
                    notifications
                  </span>
                </div>
              }
              PopperProps={{
                modifiers: [
                  {
                    name: "maxWidth",
                    enabled: true,
                    phase: "beforeWrite",
                    fn({ state }) {
                      state.styles.popper.maxWidth = "240px";
                    },
                  },
                ],
              }}
              placement="right"
              arrow
            >
              <span>
                <Checkbox
                  disabled={
                    !params.row.MobilePhone ||
                    params.row.MobilePhone?.length !== 10
                  }
                  checked={params.row.PhoneEnabled}
                  style={{ cursor: "pointer", marginRight: "-7px" }}
                  onClick={() =>
                    handlePhoneEnabled(params.row, params.row.PhoneEnabled)
                  }
                  color="success"
                />
              </span>
            </Tooltip>
          ) : (
            <Tooltip
              title={
                <div
                  style={{
                    textAlign: "center",
                    fontSize: "15px",
                    fontWeight: "600",
                    padding: "15px",
                    lineHeight: "1rem",
                  }}
                >
                  <span>Receive SMS notifications</span>
                </div>
              }
              PopperProps={{
                modifiers: [
                  {
                    name: "maxWidth",
                    enabled: true,
                    phase: "beforeWrite",
                    fn({ state }) {
                      state.styles.popper.maxWidth = "160px";
                    },
                  },
                ],
              }}
              placement="right" // Tooltip will appear on top of the checkbox
              arrow // Optional: Add an arrow to the tooltip
            >
              <span>
                <Checkbox
                  disabled={
                    !params.row.MobilePhone ||
                    params.row.MobilePhone?.length !== 10
                  }
                  checked={params.row.PhoneEnabled}
                  style={{ cursor: "pointer", marginRight: "-7px" }}
                  onClick={() =>
                    handlePhoneEnabled(params.row, params.row.PhoneEnabled)
                  }
                  color="success"
                />
              </span>
            </Tooltip>
          )}
        </div>
      ),
    });
  }

  const fetchUsers = () => {
    fetch(`${baseURL}/${clientId}/users`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data.users);
        setUsersData(data.users);
      });
  };
  useEffect(() => {
    fetchUsers();
  }, [isUpdate, clientId]);

  const retiredUserByID = (user_id) => {
    fetch(`${baseURL}/retire_user/${user_id}`, {
      method: "POST", // Specify the method as POST
      headers: {
        "Content-Type": "application/json", // Set the content type to JSON
      },
    })
      .then((response) => response.json())
      .then((response) => {
        confirmAlert(UpdatedMessageOptions);
        setIsUpdate(true);
        fetchUsers();
      })
      .then((result) => {
        console.log(result);
      });
  };

  useEffect(() => {
    // console.log("WILL BE DELETED", selectedIDs);
  }, [selectedIDs]);

  const editModalData = () => {
    return (
      <EditUser
        role={userRole}
        isProfile={false}
        clientID={clientId}
        data={selectedRow}
        handleClose={handleEditCloseModal}
        updateSatus={isUpdate}
        updateCurrentState={setIsUpdate}
      />
    );
  };

  return (
    <>
      <Box>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            {/* Header Section */}
            <Header
              clientId={clientId}
              clientName={clientName}
              handleLogout={handleLogout}
              istoggleMenu={toggleMenus}
              clientNameHeader={clientNameHeader}
            />
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Grid container spacing={0}>
          <Grid item xs={12} md={2}>
            {/* Footer Section */}
            <PhoneMenu slug="/technicians" />
            <SidebarMenu toggleMenu={setToggleMenus} />
          </Grid>
          <Grid
            item
            xs={12}
            md={10}
            className={
              toggleMenus === true ? "tooggleActive mainContent" : "mainContent"
            }
          >
            <Container maxWidth="xlg" sx={{ mt: 8, mb: 4 }}>
              <Grid container spacing={10} className="topDueSect">
                <Grid item xs={12} sm={12} md={6} className="beforetable">
                  <Typography
                    className="SelDevice"
                    align="left"
                    variant="h4"
                    gutterBottom
                  >
                    USERS
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  sx={{ textAlign: "right" }}
                  className="beforetable"
                >
                  {(userRole === "superadmin" ||
                    userRole === "masteradmin") && (
                    <Link to="/adduser" title="Add User">
                      <img
                        src={AddContactIcon}
                        width={75}
                        className="Icon_black"
                        alt="AddCompanyIcon"
                      />
                    </Link>
                  )}
                </Grid>

                <Grid item xs={12} className="tabSect">
                  <div
                    style={{
                      height: "800px",
                      width: "100%",
                      overflow: "auto",
                    }}
                  >
                    <DataGridPremium
                      className="dashboardTable"
                      getRowId={(row) => row.UserID}
                      pagination
                      checkboxSelection={false}
                      disableMultipleRowSelection={true}
                      onRowSelectionModelChange={(ids) => {
                        setSelectedIDs(ids[0]);
                        {
                          console.log({ selectedIDs });
                        }
                      }}
                      sx={{
                        "& .MuiDataGrid-row:hover": {
                          cursor: "pointer",
                        },
                        ".MuiDataGrid-cellContent": {
                          pointerEvents: "none",
                        },
                      }}
                      initialState={{
                        ...usersData,
                        pagination: { paginationModel: { pageSize: 10 } },
                        columns: {
                          columnVisibilityModel: {
                            TechID: false,
                            MobilePhone: true,
                            ExpiredDate: false,
                          },
                        },
                      }}
                      pageSizeOptions={[5, 10, 25, 100]}
                      editMode="row"
                      filterModel={currentFilterModel}
                      rows={usersData ? usersData : []}
                      loading={usersData?.length === 0}
                      columns={techniciansColumns}
                      components={{ Toolbar: UserCustomToolbar }}
                    />
                  </div>
                </Grid>
              </Grid>
            </Container>
          </Grid>
        </Grid>
      </Box>
      {editModal && (
        <ModalPopUp
          open={true}
          disableEscapeKeyDown={true}
          handleClose={handleEditCloseModal}
          title="Edit User Details"
          content=""
          formData={editModalData()}
          modalClass="notificationModal"
        />
      )}
      <Footer />
    </>
  );
}
