import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Divider,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { grey } from "@mui/material/colors";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { states } from "../States";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import InputMask from "react-input-mask";
import moment from "moment";
import axios from "axios";
import LoaderImage from "../../assets/images/spinner.gif";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

const EditDeviceTable = ({
  clientID,
  data,
  handleClose,
  updateSatus,
  updateCurrentState,
}) => {
  // console.log({ data });
  const clientId = clientID;

  const [companiesData, setCompaniesData] = useState([]);
  const [deviceTypesData, setDeviceTypesData] = useState([]);
  const [sizesData, setSizesData] = useState([]);
  const [manufacturersData, setManufacturersData] = useState([]);
  const [formIsValid, setFormIsValid] = useState(false);
  const [formData, setFormData] = useState({
    deviceType: data?.DeviceType,
    manufacturer: data?.Manufacturer,
    modelNumber: data?.Model,
    serialNumber: data?.SerialNumber,
    size: data?.Size,
    condition: data?.Condition,
    installedDate: dayjs(data?.InstalledDate),
    companyName: data?.CompanyName,
    location: data?.Location,
    addressLine1: data?.StreetAddress,
    addressLine2: data?.ContactStreetAddress,
    city: data?.City,
    state: data?.State,
    postalCode: data?.ContactPostalCode,
    comments: data?.Comments,
    contactFirstName: data?.ContactFirstName,
    contactLastName: data?.ContactLastName,
    // contactJobTitle: data?.ContactJobTitle,
    contactEmailAddress: data?.ContactEmailAddress,
    contactPostalCode: data?.ContactPostalCode,
  });
  const [showLoader, setShowLoader] = useState(false);
  const [confimModal, setConfrimModal] = useState(false)
  const [companyNameIsValid, setCompanyNameIsValid] = useState(false);
  const [deviceTypeIsValid, setDeviceTypeIsValid] = useState(false);
  const [manufacturerIsValid, setManufacturerIsValid] = useState(false);
  const [modelNumberIsValid, setModelNumberIsValid] = useState(false);
  const [serialNumberIsValid, setSerialNumberIsValid] = useState(false);
  const [sizeIsValid, setSizeIsValid] = useState(false);
  const [conditionIsValid, setConditionIsValid] = useState(false);
  const [installedDateIsValid, setInstalledDateIsValid] = useState(false);
  const [locationIsValid, setLocationIsValid] = useState(false);
  const [addressLineOneIsValid, setAddressLineOneIsValid] = useState(false);
  const [addressLineTwoIsValid, setAddressLineTwoIsValid] = useState(false);
  const [cityIsValid, setCityIsValid] = useState(false);
  const [stateIsValid, setStateIsValid] = useState(false);
  const [postalCodeIsValid, setPostalCodeIsValid] = useState(false);
  const [latitudeIsValid, setLatitudeIsValid] = useState(false);
  const [longitudeIsValid, setLongitudeIsValid] = useState(false);

  const buttonCancelColor = grey[500];
  const token = localStorage.getItem("token");
  // timeZones
  const timeZones = ["PST", "CST", "MST", "EST"];
  const [isTimezone, setIsTimezone] = useState('');
  const [isPhoneEnabled, setIsPhoneEnabled] = useState(false);
  const [isEmailEnabled, setIsEmailEnabled] = useState(false);

  useEffect(() => {
    data?.DeviceType && setCompanyNameIsValid(true);
    data?.DeviceType && setDeviceTypeIsValid(true);
    data?.DeviceType && setManufacturerIsValid(true);
    data?.DeviceType && setModelNumberIsValid(true);
    data?.DeviceType && setSerialNumberIsValid(true);
    data?.DeviceType && setSizeIsValid(true);
    data?.DeviceType && setConditionIsValid(true);
    data?.DeviceType && setInstalledDateIsValid(true);
    data?.DeviceType && setLocationIsValid(true);
    data?.DeviceType && setAddressLineOneIsValid(true);
    data?.DeviceType && setAddressLineTwoIsValid(true);
    data?.DeviceType && setCityIsValid(true);
    data?.DeviceType && setStateIsValid(true);
    data?.DeviceType && setPostalCodeIsValid(true);
  }, [data]);

  useEffect(() => {
    if (
      companyNameIsValid &&
      deviceTypeIsValid &&
      manufacturerIsValid &&
      modelNumberIsValid &&
      serialNumberIsValid &&
      sizeIsValid &&
      conditionIsValid &&
      installedDateIsValid &&
      locationIsValid &&
      addressLineOneIsValid &&
      addressLineTwoIsValid &&
      cityIsValid &&
      stateIsValid &&
      postalCodeIsValid
    ) {
      setFormIsValid(true);
    } else {
      setFormIsValid(false);
    }

    // console.log(
    //   {companyNameIsValid},
    //   {deviceTypeIsValid},
    //   {manufacturerIsValid},
    //   {modelNumberIsValid},
    //   {serialNumberIsValid},
    //   {sizeIsValid},
    //   {conditionIsValid},
    //   {installedDateIsValid},
    //   {locationIsValid},
    //   {addressLineOneIsValid},
    //   {addressLineTwoIsValid},
    //   {cityIsValid},
    //   {stateIsValid},
    //   {postalCodeIsValid}
    // )
  }, [
    companyNameIsValid,
    deviceTypeIsValid,
    manufacturerIsValid,
    modelNumberIsValid,
    serialNumberIsValid,
    sizeIsValid,
    conditionIsValid,
    installedDateIsValid,
    locationIsValid,
    addressLineOneIsValid,
    addressLineTwoIsValid,
    cityIsValid,
    stateIsValid,
    postalCodeIsValid,
  ]);

  const UpdatedMessageOptions = {
    title: "This device record has been updated.",
    overlayClassName: "overlay-custom-class-name successMessage",
    buttons: [
      {
        label: "Close",
        className: "cancelBtn",
        onClick: () => {
          setConfrimModal(false);
          return false;
        },
      },
    ],
  };

  const conditionTypes = [
    {
      value: 1,
      label: "New",
    },
    {
      value: 2,
      label: "Moved",
    },
    {
      value: 3,
      label: "Decomissioned",
    },
    {
      value: 4,
      label: "Defective",
    },
    {
      value: 5,
      label: "Normal",
    },
  ];

  function dynamicSort(property) {
    return function (a, b) {
      return a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    };
  }

  const baseURL = process.env.REACT_APP_API_BASE_URL;

  // FETCH COMPANY NAMES BY CLIENT ID
  useEffect(() => {
    fetch(`${baseURL}/${clientId}/getcompanynames`)
      .then((response) => response.json())
      .then((data) => {
        setCompaniesData(data.data.sort(dynamicSort("CompanyName")));
      });
  }, []);

  // FETCH DEVICE TYPES BY CLIENT ID
  useEffect(() => {
   
  }, []);

  // FETCH SIZES BY CLIENT ID
  useEffect(() => {
    fetch(`${baseURL}/${clientId}/getdevicesizes`)
      .then((response) => response.json())
      .then((data) => {
        setSizesData(data.data.sort(dynamicSort("Size")));
      });
  }, []);

  // FETCH MANUFACTURERS BY CLIENT ID
  useEffect(() => {
    fetch(`${baseURL}/${clientId}/getmanufacturers`)
      .then((response) => response.json())
      .then((data) => {
        setManufacturersData(data.data.sort(dynamicSort("Manufacturer")));
      });
  }, []);

  useEffect(() => {
    fetch(
      `${baseURL}/${clientId}/getdevicetypes`
    )
      .then((response) => response.json())
      .then((data) => {
        setDeviceTypesData(data.data.sort(dynamicSort("DeviceType")));
      });
  }, []);

  // Fetch notification setting data
  useEffect(() => {
    axios({
      method: "GET",
      url: `${baseURL}/notifications?client_id=${clientId}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        // if (response.data.notifications[0].length > 0 ) {
        //  setNotificationData(response.data.notifications[0])
         setIsEmailEnabled(response.data.notifications[0].EmailEnabled);
         setIsPhoneEnabled(response.data.notifications[0].PhoneEnabled);
         setIsTimezone(response.data.notifications[0].TimeZone)
        // }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      });
  }, []);

  const updateDevice = (e) => {
    setShowLoader(true);
    const deviceID = data?.DeviceID;
    axios({
      method: "PUT",
      url: `${baseURL}/${clientId}/editdevice/${deviceID}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: formData,
    })
      .then((response) => {
        if (response.data.success) {
          setShowLoader(false);
          updateSatus(!updateCurrentState);
          handleClose();
          confirmAlert(UpdatedMessageOptions);
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      });

    e.preventDefault();
  };

  const options = {
    // title: "Title",
    message: "Are you sure you want to cancel? Any changes will not be saved.",
    buttons: [
      {
        label: "NO",
        className: "confimBtn",
        onClick: () => {
          setConfrimModal(false);
          return false;
        },
      },
      {
        label: "YES",
        className: "cancelBtn",
        onClick: () => {
          setConfrimModal(false);
          handleClose();
        },
      },
    ],
    closeOnEscape: true,
    closeOnClickOutside: false,
    keyCodeForClose: [8, 32],
    willUnmount: () => {},
    afterClose: () => {},
    onClickOutside: () => {},
    onKeypress: () => {},
    onKeypressEscape: () => {},
    overlayClassName: "overlay-custom-class-name",
  };

  const handleConfirmationBox = () => {
    setConfrimModal(true);
    if (!confimModal) {
      confirmAlert(options);
    }
  };

  const isEmailEnabledFunct = (e)=>{
    setIsEmailEnabled(e.target.checked);
    axios({
      method: "PATCH",
      url: `${baseURL}/${clientId}/edit/notification/email/enable`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data:{ IsEmailEnabled: e.target.checked}
    })
      .then((response) => {
        // console.log({response})
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      });
  }

  const isPhoneEnabledFunct = (e)=>{
    setIsPhoneEnabled(e.target.checked)
    axios({
      method: "PATCH",
      url: `${baseURL}/${clientId}/edit/notification/phone/enable`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data:{ isPhoneNumberEnabled: e.target.checked}
    })
      .then((response) => {
        console.log({response})
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      });
  }

  const selectTimezoneFunct = (e) =>{
    setIsTimezone(e.target.value);
    axios({
      method: "PATCH",
      url: `${baseURL}/${clientId}/edit/notification/timezone`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data:{ timeZone: e.target.value}
    })
      .then((response) => {
        console.log({response})
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      });
  }

  // console.log(">>>>",{isEmailEnabled}, {isPhoneEnabled});

  return (
    <>
      <Box
        align="left"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "30ch" },
        }}
        noValidate
        autoComplete="off"
      >
        <Grid>
          {showLoader && (
            <Grid item className="loaderMain">
              <img src={LoaderImage} alt="loaderImg" />
            </Grid>
          )}
          <Grid item xs={12} sm={12} md={6}></Grid>
          <Grid item xs={12} className="tabSect">
            {/* <Typography>* Denotes a required field</Typography> */}
            <Box
              align="left"
              sx={{
                "& .MuiTextField-root": { m: 1, width: "30ch" },
              }}
              noValidate
              autoComplete="off"
            >
              <h3>Company and Contact Details</h3>
              <p>Select company below</p>
              <Grid>
                <TextField
                  error={formData.companyName == ""}
                  id="outlined-select-currency"
                  select
                  required
                  style={{ width: 500 }}
                  // label="Company Name"
                  helperText="Select company name in order to view contact info"
                  value={formData.companyName}
                  //onChange={e => setFormData({...formData, companyName: e.target.value})}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      companyName: e.target.value,
                    });
                    if (e.target.value == "") {
                      setCompanyNameIsValid(false);
                    } else {
                      setCompanyNameIsValid(true);
                    }
                  }}
                >
                  {companiesData.map((option) => (
                    <MenuItem key={option.CompanyID} value={option.CompanyName}>
                      {option.CompanyName}
                    </MenuItem>
                  ))}
                </TextField>
                <h3>Device Details</h3>
              </Grid>
              <Box component="form" onSubmit="">
                <Grid container sx={{ mt: 4 }}>
                  <TextField
                    style={{ width: 375 }}
                    error={formData.deviceType == ""}
                    id="outlined-select-currency"
                    select
                    // label="Device Type"
                    helperText="Select device type"
                    required
                    value={formData.deviceType}
                    //onChange={e => setFormData({...formData, deviceType: e.target.value})}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        deviceType: e.target.value,
                      });
                      if (e.target.value == "") {
                        setDeviceTypeIsValid(false);
                      } else {
                        setDeviceTypeIsValid(true);
                      }
                    }}
                  >
                    {deviceTypesData.map((option) => (
                      <MenuItem key={option.ID} value={option.DeviceType}>
                        {option.DeviceType}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    style={{ width: 375 }}
                    error={formData.manufacturer == ""}
                    id="outlined-select-currency"
                    select
                    // label="Manufacturer"
                    required
                    helperText="Select manufacturer"
                    //value={newEnteredManValue ? newEnteredManValue !== "" : formData.manufacturer}
                    value={formData.manufacturer}
                    //onChange={e => setFormData({...formData, manufacturer:e.target.value})}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        manufacturer: e.target.value,
                      });
                      if (e.target.value == "") {
                        setManufacturerIsValid(false);
                      } else {
                        setManufacturerIsValid(true);
                      }
                    }}
                  >
                    {manufacturersData.map((option) => (
                      <MenuItem key={option.ID} value={option.Manufacturer}>
                        {option.Manufacturer}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    style={{ width: 375 }}
                    error={formData.modelNumber == ""}
                    id="outlined-select-currency"
                    // label="Model Number"
                    required
                    helperText="Model number"
                    value={formData.modelNumber}
                    //onChange={e => setFormData({...formData, modelNumber:e.target.value})}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        modelNumber: e.target.value,
                      });
                      if (e.target.value == "") {
                        setModelNumberIsValid(false);
                      } else {
                        setModelNumberIsValid(true);
                      }
                    }}
                  >
                    {deviceTypesData.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>

                  <TextField
                    style={{ width: 375 }}
                    error={formData.serialNumber == ""}
                    id="outlined-select-currency"
                    // label="Serial Number"
                    required
                    helperText="Serial number"
                    value={formData.serialNumber}
                    //onChange={e => setFormData({...formData, serialNumber:e.target.value})}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        serialNumber: e.target.value,
                      });
                      if (e.target.value == "") {
                        setSerialNumberIsValid(false);
                      } else {
                        setSerialNumberIsValid(true);
                      }
                    }}
                  >
                    {deviceTypesData.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    style={{ width: 375 }}
                    error={formData.size == ""}
                    id="outlined-select-currency"
                    select
                    // label="Size"
                    required
                    helperText="Select device size"
                    value={formData.size}
                    //onChange={e => setFormData({...formData, size: e.target.value})}
                    onChange={(e) => {
                      setFormData({ ...formData, size: e.target.value });
                      if (e.target.value == "") {
                        setSizeIsValid(false);
                      } else {
                        setSizeIsValid(true);
                      }
                    }}
                  >
                    {sizesData.map((option) => (
                      <MenuItem key={option.SizeID} value={option.Size}>
                        {option.Size}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    style={{ width: 375 }}
                    error={formData.condition == ""}
                    id="outlined-select-currency"
                    select
                    required
                    // label="Condition"
                    helperText="Select condition"
                    value={formData.condition}
                    //onChange={e => setFormData({...formData, condition: e.target.value})}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        condition: e.target.value,
                      });
                      if (e.target.value == "") {
                        setConditionIsValid(false);
                      } else {
                        setConditionIsValid(true);
                      }
                    }}
                  >
                    {conditionTypes.map((option) => (
                      <MenuItem key={option.value} value={option.label}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      value={formData.installedDate}
                      label="Installed Date"
                      onChange={(date) => {
                        setFormData({
                          ...formData,
                          installedDate: dayjs(date.format("MM/DD/YYYY")),
                        });
                      }}
                      slotProps={{
                        textField: {
                          required: true,
                          error: formData.installedDate == "",
                        },
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
                <h3>Device Location</h3>
                <Grid>
                  <TextField
                    style={{ width: 375 }}
                    error={formData.location == ""}
                    id="outlined-select-currency"
                    required
                    // label="Location Notes"
                    helperText="Describe location (eg. 'Front lawn')"
                    value={formData.location}
                    //onChange={e => setFormData({...formData, location:e.target.value})}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        location: e.target.value,
                      });
                      if (e.target.value == "") {
                        setLocationIsValid(false);
                      } else {
                        setLocationIsValid(true);
                      }
                    }}
                  >
                    {deviceTypesData.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    style={{ width: 375 }}
                    error={formData.addressLine1 == ""}
                    id="outlined-select-currency"
                    // label="Address Line 1"
                    required
                    helperText="Address (eg. 123 Main St)"
                    value={formData.addressLine1}
                    //onChange={e => setFormData({...formData, addressLine1:e.target.value})}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        addressLine1: e.target.value,
                      });
                      if (e.target.value == "") {
                        setAddressLineOneIsValid(false);
                      } else {
                        setAddressLineOneIsValid(true);
                      }
                    }}
                  >
                    {deviceTypesData.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    style={{ width: 375 }}
                    id="outlined-select-currency"
                    // label="Address Line 2"
                    helperText="Address line 2 (Apt 5 or Unit 2)"
                    value={formData.addressLine2}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        addressLine2: e.target.value,
                      });
                      if (e.target.value == "") {
                        setAddressLineTwoIsValid(false);
                      } else {
                        setAddressLineTwoIsValid(true);
                      }
                    }}
                  >
                    {deviceTypesData.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>

                  <TextField
                    style={{ width: 375 }}
                    error={formData.city == ""}
                    id="outlined-select-currency"
                    // label="City"
                    required
                    helperText="City (eg. Houston)"
                    value={formData.city}
                    //onChange={e => setFormData({...formData, city:e.target.value})}
                    onChange={(e) => {
                      setFormData({ ...formData, city: e.target.value });
                      if (e.target.value == "") {
                        setCityIsValid(false);
                      } else {
                        setCityIsValid(true);
                      }
                    }}
                  >
                    {deviceTypesData.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>

                  <TextField
                    style={{ width: 375 }}
                    error={formData.state == ""}
                    id="outlined-select-currency"
                    select
                    required
                    //style = {{width: 260}}
                    // label="State"
                    helperText="State (eg. TX)"
                    value={formData.state}
                    //onChange={e => setFormData({...formData, state: e.target.value})}
                    onChange={(e) => {
                      setFormData({ ...formData, state: e.target.value });
                      if (e.target.value == "") {
                        setStateIsValid(false);
                      } else {
                        setStateIsValid(true);
                      }
                    }}
                  >
                    {states.map((option) => (
                      <MenuItem key={option.id} value={option.state}>
                        {option.state}
                      </MenuItem>
                    ))}
                  </TextField>

                  <InputMask
                    style={{ width: 375 }}
                    mask="99999-9999"
                    value={formData.postalCode}
                    disabled={false}
                    maskChar=" "
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        postalCode: e.target.value,
                      });

                      let digitLength = e.target.value
                        .toString()
                        .replace(/[^0-9]/g, "").length;
                      if (digitLength < 5) {
                        setPostalCodeIsValid(false);
                      } else if (digitLength > 5 && digitLength < 9) {
                        setPostalCodeIsValid(false);
                      } else {
                        setPostalCodeIsValid(true);
                        //console.log('Setting fax valid to tru')
                      }
                    }}
                  >
                    {() => (
                      <TextField
                        style={{ width: 375 }}
                        error={
                          (formData.postalCode &&
                            formData.postalCode
                              .toString()
                              .replace(/[^0-9]/g, "").length < 5) ||
                          (formData.postalCode &&
                            formData.postalCode
                              .toString()
                              .replace(/[^0-9]/g, "").length > 5 &&
                            formData.postalCode
                              .toString()
                              .replace(/[^0-9]/g, "").length < 9) ||
                          formData.postalCode == ""
                        }
                        helperText={
                          (formData.postalCode &&
                            formData.postalCode
                              .toString()
                              .replace(/[^0-9]/g, "").length < 5) ||
                          (formData.postalCode &&
                            formData.postalCode
                              .toString()
                              .replace(/[^0-9]/g, "").length > 5 &&
                            formData.postalCode
                              .toString()
                              .replace(/[^0-9]/g, "").length < 9)
                            ? "Zipcode (must be either 5 or 9 digits)"
                            : "Zipcode"
                        }
                        id="outlined-select-currency"
                        //style = {{width: 275}}
                        required
                        // label="Zipcode (must be either 5 or 9 digits)"
                        //helperText="Type 5-digit postal code e.g. 12345"
                        value={formData.postalCode}
                      ></TextField>
                    )}
                  </InputMask>
                </Grid>
                <h3>Additional Comments</h3>
                <TextField
                  id="outlined-select-currency"
                  label="Comments"
                  style={{ width: 900, marginBottom: 20 }}
                  value={formData.comments}
                  onChange={(e) =>
                    setFormData({ ...formData, comments: e.target.value })
                  }
                >
                  {deviceTypesData.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <Box>
                  {/* <SubmitAddDeviceModal /> */}
                  {/* sx = {{ mr: 6, mt: 4, mb:4}} */}
                  {/* <AddTestModal submitTestFileAndData={handleSubmit} openStatus={openSubmitModal} handleModalClose={handleCloseSubmitModal} alertContentText={alertContent}/> */}

                  <Button
                    disabled={formIsValid ? false : true}
                    type="submit"
                    size="large"
                    sx={{
                      ml: 1,
                      mr: 6,
                      mt: 4,
                      borderRadius: 8,
                      width: 225,
                    }}
                    variant="contained"
                    color="info"
                    onClick={updateDevice}
                  >
                    Save
                  </Button>
                  <Button
                    size="large"
                    variant="contained"
                    sx={{
                      ml: 1,
                      mr: 6,
                      mt: 4,
                      borderRadius: 8,
                      width: 225,
                    }}
                    onClick={handleConfirmationBox}
                    color={"inherit"}
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default EditDeviceTable;
