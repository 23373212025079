import React, { useState, useEffect, useContext } from "react";
import { TextField, Button } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import MuiAlert from "@mui/material/Alert";
import AddTestModal from "../AddTestModal";
import InputMask from "react-input-mask";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { states } from "../States";
import Container from "@mui/material/Container";
import PhoneMenu from "../Common/PhoneMenu";
import SidebarMenu from "../Common/SidebarMenu";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import { useSelector } from "react-redux";
import {
  isTokenExpired,
  handleExpiredToken,
  handleTokenExpiryWarning,
} from "../Common/CheckJwtExpires";

const AddTechnician = ({ clientName, handleLogout, clientNameHeader }) => {
  const clientId = useSelector((state) => state.userID.value);

  const [open, setOpen] = useState(true);
  const [companiesData, setCompaniesData] = useState([]);
  const [companyDevicesData, setCompanyDevicesData] = useState([]);
  const [testFiles, setTestFiles] = useState([]);
  const [uploadedFileName, setUploadedFileName] = useState("");
  const [toggleMenus, setToggleMenus] = useState(false);
  const [formData, setFormData] = useState({
    technicianName: "",
    firmName: "",
    certTesterNumber: "",
    certExpirationDate: "",
    streetAddress: "",
    city: "",
    state: "",
    postalCode: "",
    workPhoneOne: "",
    workPhoneTwo: "",
    mobilePhone: "",
  });
  const [deviceSerialNumbers, setDeviceSerialNumbers] = useState([]);
  const [deviceID, setDeviceID] = useState([]);
  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState("");
  const [openSubmitModal, setOpenSubmitModal] = useState(false);
  const [deviceScheduledTestDate, setDeviceScheduledTestDate] = useState("");
  const [selectedDeviceID, setSelectedDeviceID] = useState("");

  const handleOpenSubmitModal = () => setOpenSubmitModal(true);
  const handleCloseSubmitModal = () => setOpenSubmitModal(false);

  const [workPhoneOneIsValid, setWorkPhoneOneIsValid] = useState(false);
  const [workPhoneTwoIsValid, setWorkPhoneTwoIsValid] = useState(false);
  const [mobilePhoneIsValid, setMobilePhoneIsValid] = useState(false);
  const token = localStorage.getItem("token");

  useEffect(() => {
    const checkTokenExpiry = () => {
      if (isTokenExpired(token)) {
        handleExpiredToken();
      } else {
        handleTokenExpiryWarning(token);
      }
    };

    checkTokenExpiry();
    const interval = setInterval(checkTokenExpiry, 60000);

    return () => clearInterval(interval);
  }, [token]);
  const [formIsValid, setFormIsValid] = useState(false);

  useEffect(() => {
    // console.log("bisphone", businessPhoneIsValid)
    // console.log("home", homePhoneIsValid)
    // console.log("mobile", mobilePhoneIsValid)
    // console.log("fax", faxIsValid)

    if (workPhoneOneIsValid) {
      console.log("Setting form valid to true");

      setFormIsValid(true);
    } else {
      console.log("setting form valid to false");
      setFormIsValid(false);
    }
  }, [workPhoneOneIsValid]);

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    fetch(
      `https://flo-trace-api-prod-ugyxg.ondigitalocean.app/${clientId}/posttechnician`,
      {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(formData),
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Something went wrong!");
        }
        return response.json();
      })
      .then((data) => {
        setAlertContent("Technician created successfully!");
        setAlert(true);
        handleOpenSubmitModal();
      })
      .catch((error) => {
        console.error(error);
        setAlertContent("Something went wrong!");
        setAlert(true);
        handleOpenSubmitModal();
      });
  };
  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <>
      <Box>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            {/* Header Section */}
            <Header
              clientId={clientId}
              clientName={clientName}
              handleLogout={handleLogout}
              istoggleMenu={toggleMenus}
              clientNameHeader={clientNameHeader}
            />
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Grid container spacing={0}>
          <Grid item xs={12} md={2}>
            {/* Footer Section */}
            <PhoneMenu slug="/technicians" />
            <SidebarMenu toggleMenu={setToggleMenus} />
          </Grid>
          <Grid
            item
            xs={12}
            md={10}
            className={
              toggleMenus === true ? "tooggleActive mainContent" : "mainContent"
            }
          >
            <Container maxWidth="xlg" sx={{ mt: 8, mb: 4 }}>
              <Grid container spacing={10} className="topDueSect">
                <Grid item xs={12} sm={12} md={6} className="beforetable">
                  <Typography
                    className="SelDevice"
                    align="left"
                    variant="h4"
                    gutterBottom
                  >
                    ADD A TECHNICIAN
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  sx={{ textAlign: "right" }}
                  className="beforetable"
                ></Grid>

                <Grid item xs={12} className="tabSect" sx={{ mt: 4 }}>
                  {/* <Typography>* Denotes a required field</Typography> */}
                  <Box
                    align="left"
                    sx={{
                      mt: 4,
                      "& .MuiTextField-root": { m: 1, width: "30ch" },
                    }}
                    autoComplete="off"
                  >
                    <h3>Technician Details</h3>
                    <Box component="form" onSubmit={handleSubmit}>
                      <Grid sx={{ mt: 2 }}>
                        <Grid>
                          <TextField
                            error={formData.technicianName == ""}
                            id="outlined-select-currency"
                            style={{ width: 500 }}
                            // label="Technician Name"
                            required
                            helperText="First and last name (eg. John Doe)"
                            value={formData.technicianName}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                technicianName: e.target.value,
                              })
                            }
                          ></TextField>

                          <TextField
                            error={formData.firmName == ""}
                            id="outlined-select-currency"
                            style={{ width: 500 }}
                            required
                            // label="Technician Firm Name"
                            helperText="Certification Firm Name"
                            value={formData.firmName}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                firmName: e.target.value,
                              })
                            }
                          ></TextField>

                          <TextField
                            error={formData.certTesterNumber == ""}
                            id="outlined-select-currency"
                            style={{ width: 270 }}
                            required
                            // label="Certification Tester Number"
                            helperText="Certification Tester Number"
                            value={formData.certTesterNumber}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                certTesterNumber: e.target.value,
                              })
                            }
                          ></TextField>

                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={["DatePicker"]}>
                              <DatePicker
                                style={{ width: 300 }}
                                label="Certification Expiration Date"
                                onChange={(date) => {
                                  setFormData({
                                    ...formData,
                                    certExpirationDate:
                                      date.format("MM/DD/YYYY"),
                                  });
                                }}
                                slotProps={{
                                  textField: {
                                    required: true,
                                    error: formData.certExpirationDate == "",
                                  },
                                }}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </Grid>
                        <h3>Certification Firm Details</h3>
                        <Grid>
                          <TextField
                            required
                            error={formData.streetAddress == ""}
                            id="outlined-select-currency"
                            style={{ width: 500 }}
                            // label="Firm Street Address"
                            helperText="Address (e.g. 123 Main St)"
                            value={formData.streetAddress}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                streetAddress: e.target.value,
                              })
                            }
                          ></TextField>

                          <TextField
                            id="outlined-select-currency"
                            error={formData.city == ""}
                            style={{ width: "242px" }}
                            required
                            // label="City"
                            helperText="City"
                            value={formData.city}
                            onChange={(e) =>
                              setFormData({ ...formData, city: e.target.value })
                            }
                          ></TextField>

                          {/* 
            <InputMask
              mask="aa"
              value={formData.state}
              disabled={false}
              maskChar=" "
              onChange={e => setFormData({...formData, state:e.target.value})}
              >
              {() => <TextField
                style = {{width: 275}}
                required
                label="State"
                helperText="Type state"
                value={formData.state}
            >
            </TextField>}
          </InputMask> */}

                          <TextField
                            error={formData.state == ""}
                            id="outlined-select-currency"
                            select
                            required
                            //style = {{width: 260}}
                            // label="State"
                            helperText="State (eg. TX)"
                            value={formData.state}
                            style={{ width: "242px" }}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                state: e.target.value,
                              })
                            }
                          >
                            {states.map((option) => (
                              <MenuItem key={option.id} value={option.state}>
                                {option.state}
                              </MenuItem>
                            ))}
                          </TextField>

                          <InputMask
                            mask="99999-9999"
                            value={formData.postalCode}
                            disabled={false}
                            maskChar=""
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                postalCode: e.target.value,
                              })
                            }
                          >
                            {() => (
                              <TextField
                                error={
                                  formData.postalCode
                                    .toString()
                                    .replace(/[^0-9]/g, "").length < 5 ||
                                  (formData.postalCode
                                    .toString()
                                    .replace(/[^0-9]/g, "").length > 5 &&
                                    formData.postalCode
                                      .toString()
                                      .replace(/[^0-9]/g, "").length < 9)
                                }
                                helperText={
                                  formData.postalCode
                                    .toString()
                                    .replace(/[^0-9]/g, "").length < 5 ||
                                  (formData.postalCode
                                    .toString()
                                    .replace(/[^0-9]/g, "").length > 5 &&
                                    formData.postalCode
                                      .toString()
                                      .replace(/[^0-9]/g, "").length < 9)
                                    ? "Zipcode (must be either 5 or 9 digits)"
                                    : " "
                                }
                                id="outlined-select-currency"
                                style={{ width: 275 }}
                                required
                                // label="Postal Code"
                                //helperText="Type 5-digit postal code e.g. 12345"
                                value={formData.postalCode}
                              ></TextField>
                            )}
                          </InputMask>

                          {/* <TextField
                id="outlined-select-currency"
                style = {{width: 275}}
                required
                label="State"
                helperText="Type state"
                value={formData.state}
                onChange={e => setFormData({...formData, state:e.target.value})}
              >
            </TextField> */}
                          {/* <TextField
                id="outlined-select-currency"
                style = {{width: 275}}
                required
                label="Postal Code"
                helperText="Type 5-digit postal code e.g. 12345"
                value={formData.postalCode}
                onChange={e => setFormData({...formData, postalCode:e.target.value})}
              >
            </TextField> */}
                        </Grid>
                        <h3>Technician Contact Details</h3>

                        <Grid>
                          <InputMask
                            mask="(999) 999-9999"
                            value={formData.workPhoneOne}
                            disabled={false}
                            maskChar=" "
                            //onChange={e => setFormData({...formData, workPhoneOne:e.target.value})}
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                workPhoneOne: e.target.value,
                              });

                              let digitLength = e.target.value
                                .toString()
                                .replace(/[^0-9]/g, "").length;
                              if (digitLength < 10) {
                                setWorkPhoneOneIsValid(false);
                              } else {
                                setWorkPhoneOneIsValid(true);
                              }
                            }}
                          >
                            {() => (
                              <TextField
                                error={
                                  formData.workPhoneOne
                                    .toString()
                                    .replace(/[^0-9]/g, "").length < 10
                                }
                                helperText={
                                  formData.workPhoneOne
                                    .toString()
                                    .replace(/[^0-9]/g, "").length < 10
                                    ? "Work Phone 1 phone (must be 10 digits)"
                                    : " "
                                }
                                required
                                // label="Work Phone 1"
                                //helperText="Type primary work phone number"
                                value={formData.workPhoneOne}
                              ></TextField>
                            )}
                          </InputMask>

                          {/* <TextField
                id="outlined-select-currency"
                style = {{width: 275}}
                required
                label="Work Phone 1"
                helperText="Type primary work phone number"
                value={formData.workPhoneOne}
                onChange={e => setFormData({...formData, workPhoneOne:e.target.value})}
              >
            </TextField> */}

                          <InputMask
                            mask="(999) 999-9999"
                            value={formData.workPhoneTwo}
                            disabled={false}
                            maskChar=" "
                            //onChange={e => setFormData({...formData, workPhoneTwo:e.target.value})}
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                workPhoneTwo: e.target.value,
                              });

                              let digitLength = e.target.value
                                .toString()
                                .replace(/[^0-9]/g, "").length;
                              if (digitLength < 10) {
                                setWorkPhoneTwoIsValid(false);
                              } else {
                                setWorkPhoneTwoIsValid(true);
                              }
                            }}
                          >
                            {() => (
                              <TextField
                                //error={formData.workPhoneTwo.toString().replace(/[^0-9]/g,"").length <10}
                                helperText={
                                  formData.workPhoneTwo
                                    .toString()
                                    .replace(/[^0-9]/g, "").length < 10
                                    ? "Work phone 2 (must be 10 digits)"
                                    : " "
                                }
                                id="outlined-select-currency"
                                style={{ width: 275 }}
                                // label="Work Phone 2"
                                // helperText="Type secondary work phone number"
                                value={formData.workPhoneTwo}
                              ></TextField>
                            )}
                          </InputMask>

                          <InputMask
                            mask="(999) 999-9999"
                            value={formData.mobilePhone}
                            disabled={false}
                            maskChar=" "
                            //onChange={e => setFormData({...formData, mobilePhone:e.target.value})}
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                mobilePhone: e.target.value,
                              });

                              let digitLength = e.target.value
                                .toString()
                                .replace(/[^0-9]/g, "").length;
                              if (digitLength < 10) {
                                setMobilePhoneIsValid(false);
                              } else {
                                setMobilePhoneIsValid(true);
                              }
                            }}
                          >
                            {() => (
                              <TextField
                                //error={formData.mobilePhone.toString().replace(/[^0-9]/g,"").length <10}
                                helperText={
                                  formData.mobilePhone
                                    .toString()
                                    .replace(/[^0-9]/g, "").length < 10
                                    ? "Mobile phone (must be 10 digits)"
                                    : " "
                                }
                                id="outlined-select-currency"
                                style={{ width: 275 }}
                                // label="Mobile Phone"
                                value={formData.mobilePhone}
                              ></TextField>
                            )}
                          </InputMask>

                          {/* <TextField
                id="outlined-select-currency"
                style = {{width: 275}}
                label="Work Phone 2"
                helperText="Type secondary work phone number"
                value={formData.workPhoneTwo}
                onChange={e => setFormData({...formData, workPhoneTwo:e.target.value})}
              >
            </TextField> */}
                          {/* <TextField
                id="outlined-select-currency"
                style = {{width: 275}}
                label="Mobile Phone"
                helperText="Type mobile phone number"
                value={formData.mobilePhone}
                onChange={e => setFormData({...formData, mobilePhone:e.target.value})}
              >
            </TextField> */}
                        </Grid>
                      </Grid>

                      <Grid>
                        <Box sx={{ display: "inline-flex" }}>
                          <AddTestModal
                            submitTestFileAndData={handleSubmit}
                            openStatus={openSubmitModal}
                            handleModalClose={handleCloseSubmitModal}
                            alertContentText={alertContent}
                          />
                          <Button
                            disabled={formIsValid ? false : true}
                            type="submit"
                            variant="contained"
                            size="large"
                            color="success"
                            sx={{
                              ml: 1,
                              mr: 6,
                              mt: 4,
                              borderRadius: 8,
                              width: 225,
                            }}
                          >
                            Submit
                          </Button>
                          <Button
                            size="large"
                            href="/technicians"
                            variant="contained"
                            sx={{
                              mr: 6,
                              mt: 4,
                              borderRadius: 8,
                              borderRadius: 8,
                              width: 225,
                            }}
                            color="error"
                          >
                            Cancel
                          </Button>
                        </Box>
                      </Grid>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </>
  );
};

export default AddTechnician;
