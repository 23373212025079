import "./App.css";
import "./assets/fonts/stylesheet.css";
import {
  createBrowserRouter,
  RouterProvider,
  useNavigate,
} from "react-router-dom";
import Login from "./components/Login";
import AddDevice from "./components/AddNewForms/AddDevice";
import AddTest from "./components/AddNewForms/AddTest";
import AddContact from "./components/AddNewForms/AddContact";
import AddCompany from "./components/AddNewForms/AddCompany";
import AddTechnician from "./components/AddNewForms/AddTechnician";
import useAuthState from "./components/useAuthState";
import Dashboard from "./components/Dashboard";
import Map from "./components/Map";
import TechniciansTable from "./components/TechniciansTable";
import ContactsTable from "./components/ContactsTable";
import AddUser from "./components/AddNewForms/AddUser";
import Users from "./components/Users";
import { useEffect, useState } from "react";
import { Provider } from "react-redux";
import { store } from "./components/store/Store";
import { isTokenExpired } from "./components/Common/CheckJwtExpires";
import ForgetPassword from "./components/ForgetPassword";
import ChangePassword from "./components/ChangePassword";
import OnBoardNewClient from "./components/OnBoardNewClient";
import UploadData from "./components/UploadData";

function App() {
  const {
    token,
    email,
    clientId,
    clientName,
    clientNameHeader,
    clientRoleName,
    getUserItem,
    removeItems,
    saveClientId,
    saveClientName,
    saveClientNameHeader,
    setClientDisplayName,
    saveClientRoleName,
    saveEmail,
    saveToken,
  } = useAuthState();

  const [isClientSelected, setIsclientSelected] = useState(false);

  useEffect(() => {
    const clientID = localStorage.getItem("clientid");
  }, [isClientSelected, clientId]);
  useEffect(() => {
    console.log(clientName, "Hello");
  }, [clientName]);

  const router = createBrowserRouter([
    {
      path: "/",
      element: isTokenExpired(token) ? (
        <Login
          saveClientId={saveClientId}
          saveClientName={saveClientName}
          saveEmail={saveEmail}
          saveToken={saveToken}
          saveClientNameHeader={saveClientNameHeader}
          saveClientRoleName={saveClientRoleName}
          setClientDisplayName={setClientDisplayName}
        />
      ) : (
        <Dashboard
          clientName={clientName}
          removeItems={removeItems}
          handleLogout={removeItems}
          clientNameHeader={clientNameHeader}
          isClientSelected={isClientSelected}
          onisClientSelected={setIsclientSelected}
        />
      ),
    },
    {
      path: "/forget-password",
      element: (
        <ForgetPassword
          saveClientId={saveClientId}
          saveClientName={saveClientName}
          saveEmail={saveEmail}
          saveToken={saveToken}
          saveClientNameHeader={saveClientNameHeader}
          saveClientRoleName={saveClientRoleName}
          setClientDisplayName={setClientDisplayName}
        />
      ),
    },
    {
      path: "/onboard-client",
      element: (
        <OnBoardNewClient
          saveClientId={saveClientId}
          saveClientName={saveClientName}
          saveEmail={saveEmail}
          saveToken={saveToken}
          handleLogout={removeItems}
          saveClientNameHeader={saveClientNameHeader}
          saveClientRoleName={saveClientRoleName}
          setClientDisplayName={setClientDisplayName}
        />
      ),
    },
    {
      path: "/upload-data",
      element: (
        <UploadData
          saveClientId={saveClientId}
          saveClientName={saveClientName}
          saveEmail={saveEmail}
          saveToken={saveToken}
          handleLogout={removeItems}
          saveClientNameHeader={saveClientNameHeader}
          saveClientRoleName={saveClientRoleName}
          setClientDisplayName={setClientDisplayName}
        />
      ),
    },
    {
      path: "/change-password",
      element: (
        <ChangePassword
          saveClientId={saveClientId}
          saveClientName={saveClientName}
          saveEmail={saveEmail}
          saveToken={saveToken}
          saveClientNameHeader={saveClientNameHeader}
          saveClientRoleName={saveClientRoleName}
          setClientDisplayName={setClientDisplayName}
        />
      ),
    },
    {
      path: "/contacts",
      element: isTokenExpired(token) ? (
        <Login
          saveClientId={saveClientId}
          saveClientName={saveClientName}
          saveEmail={saveEmail}
          saveToken={saveToken}
          saveClientNameHeader={saveClientNameHeader}
        />
      ) : (
        <ContactsTable
          // clientId={clientId}
          removeItems={removeItems}
          clientName={clientName}
          handleLogout={removeItems}
          clientNameHeader={clientNameHeader}
        />
      ),
    },
    {
      path: "/technicians",
      element: isTokenExpired(token) ? (
        <Login
          saveClientId={saveClientId}
          saveClientName={saveClientName}
          saveEmail={saveEmail}
          saveToken={saveToken}
          saveClientNameHeader={saveClientNameHeader}
        />
      ) : (
        <TechniciansTable
          // clientId={clientId}
          removeItems={removeItems}
          clientName={clientName}
          handleLogout={removeItems}
          clientNameHeader={clientNameHeader}
        />
      ),
    },
    {
      path: "/adddevice",
      element: isTokenExpired(token) ? (
        <Login
          saveClientId={saveClientId}
          saveClientName={saveClientName}
          saveEmail={saveEmail}
          saveToken={saveToken}
          saveClientNameHeader={saveClientNameHeader}
        />
      ) : (
        <AddDevice
          // clientId={clientId}
          removeItems={removeItems}
          clientName={clientName}
          handleLogout={removeItems}
          clientNameHeader={clientNameHeader}
        />
      ),
    },
    {
      path: "/addtest",
      element: isTokenExpired(token) ? (
        <Login
          saveClientId={saveClientId}
          saveClientName={saveClientName}
          saveEmail={saveEmail}
          saveToken={saveToken}
          saveClientNameHeader={saveClientNameHeader}
        />
      ) : (
        <AddTest
          // clientId={clientId}
          removeItems={removeItems}
          clientName={clientName}
          handleLogout={removeItems}
          clientNameHeader={clientNameHeader}
        />
      ),
    },
    {
      path: "/addcontact",
      element: isTokenExpired(token) ? (
        <Login
          saveClientId={saveClientId}
          saveClientName={saveClientName}
          saveEmail={saveEmail}
          saveToken={saveToken}
          saveClientNameHeader={saveClientNameHeader}
        />
      ) : (
        <AddContact
          // clientId={clientId}
          removeItems={removeItems}
          clientName={clientName}
          handleLogout={removeItems}
          clientNameHeader={clientNameHeader}
        />
      ),
    },
    {
      path: "/addcompany",
      element: isTokenExpired(token) ? (
        <Login
          saveClientId={saveClientId}
          saveClientName={saveClientName}
          saveEmail={saveEmail}
          saveToken={saveToken}
          saveClientNameHeader={saveClientNameHeader}
        />
      ) : (
        <AddCompany
          // clientId={clientId}
          removeItems={removeItems}
          clientName={clientName}
          handleLogout={removeItems}
          clientNameHeader={clientNameHeader}
        />
      ),
    },
    {
      path: "/addtechnician",
      element: isTokenExpired(token) ? (
        <Login
          saveClientId={saveClientId}
          saveClientName={saveClientName}
          saveEmail={saveEmail}
          saveToken={saveToken}
          saveClientNameHeader={saveClientNameHeader}
        />
      ) : (
        <AddTechnician
          // clientId={clientId}
          removeItems={removeItems}
          clientName={clientName}
          handleLogout={removeItems}
          clientNameHeader={clientNameHeader}
        />
      ),
    },
    {
      path: "/map",
      element: isTokenExpired(token) ? (
        <Login
          saveClientId={saveClientId}
          saveClientName={saveClientName}
          saveEmail={saveEmail}
          saveToken={saveToken}
          saveClientNameHeader={saveClientNameHeader}
        />
      ) : (
        <Map
          // clientId={clientId}
          removeItems={removeItems}
          clientName={clientName}
          handleLogout={removeItems}
          clientNameHeader={clientNameHeader}
        />
      ),
    },
    {
      path: "/adduser",
      element: isTokenExpired(token) ? (
        <Login
          saveClientId={saveClientId}
          saveClientName={saveClientName}
          saveEmail={saveEmail}
          saveToken={saveToken}
          saveClientNameHeader={saveClientNameHeader}
        />
      ) : (
        <AddUser
          // clientId={clientId}
          removeItems={removeItems}
          clientName={clientName}
          handleLogout={removeItems}
          clientNameHeader={clientNameHeader}
        />
      ),
    },
    {
      path: "/users",
      element: isTokenExpired(token) ? (
        <Login
          saveClientId={saveClientId}
          saveClientName={saveClientName}
          saveEmail={saveEmail}
          saveToken={saveToken}
          saveClientNameHeader={saveClientNameHeader}
        />
      ) : (
        <Users
          // clientId={clientId}
          removeItems={removeItems}
          clientName={clientName}
          handleLogout={removeItems}
          clientNameHeader={clientNameHeader}
        />
      ),
    },
  ]);

  //   const [authContext, setAuthContext] = useState({
  //     clientId:clientId,
  //     clientName:clientName,
  //     token:token,
  //     email:email,
  //     }
  // )

  //   const authCtxHandler = (loggedIn, token, email, clientId, clientName) => {
  //     setAuthContext((prevState) => {
  //       return {
  //       loggedIn:loggedIn,
  //       clientId:clientId,
  //       clientName:clientName,
  //       token:token,
  //       email:email
  //       }
  //     })
  //   }

  // useEffect(() => {
  //   console.log('Auth State', authContext)},
  //   [authContext])

  // const ctxValue = {
  //   ...authContext,
  //   updateAuthContext:authCtxHandler
  // }
  //loginHandler={authCtxHandler}
  return (
    <Provider store={store}>
      <div className="App">
        <RouterProvider router={router} />
      </div>
    </Provider>
  );

  // return (
  //   // <LoginContext.Provider value={authContext}>
  //     <BrowserRouter>
  //       <div className="App">
  //         <Routes>
  //           <Route path='/' exact element={!token && token!=="" && token!==undefined? <Login saveClientId={saveClientId} saveClientName={saveClientName} saveEmail={saveEmail} saveToken={saveToken} />:<Dashboard clientId={clientId} clientName={clientName} handleLogout = {removeItems} />} />
  //           <Route path='/contacts' exact element={!token && token!=="" && token!==undefined? <Login saveClientId={saveClientId} saveClientName={saveClientName} saveEmail={saveEmail} saveToken={saveToken} />:<ContactsTable2 clientId={clientId} clientName={clientName} handleLogout = {removeItems} />} />
  //           <Route path='/technicians' exact element={!token && token!=="" && token!==undefined? <Login saveClientId={saveClientId} saveClientName={saveClientName} saveEmail={saveEmail} saveToken={saveToken} />:<TechniciansTable clientId={clientId} clientName={clientName} handleLogout = {removeItems} />} />
  //           <Route path='/adddevice' exact element={!token && token!=="" && token!==undefined? <Login saveClientId={saveClientId} saveClientName={saveClientName} saveEmail={saveEmail} saveToken={saveToken} />:<AddDevice clientId={clientId} clientName={clientName} handleLogout = {removeItems} />} />
  //           <Route path='/addtest' exact element={!token && token!=="" && token!==undefined? <Login saveClientId={saveClientId} saveClientName={saveClientName} saveEmail={saveEmail} saveToken={saveToken} />:<AddTest clientId={clientId} clientName={clientName} handleLogout = {removeItems} />} />
  //           <Route path='/addcontact' exact element={!token && token!=="" && token!==undefined? <Login saveClientId={saveClientId} saveClientName={saveClientName} saveEmail={saveEmail} saveToken={saveToken} />:<AddContact clientId={clientId} clientName={clientName} handleLogout = {removeItems} />} />
  //         </Routes>
  //       </div>
  //     </BrowserRouter>
  //   // </LoginContext.Provider>
  // )
}

export default App;
