import React, { useState, useEffect, useMemo } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Button, Stack, Paper, ListItemButton, TextField } from "@mui/material";
import axios from "axios";
import { styled } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import AppBar from "../AppBar";
import FloTraceLogoHeader from "../FloTraceLogoHeader";
import BackGroundImg from "./assets/imgs/header-bgrnd.png";
import { Link, useNavigate } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import ModalPopUp from "./Modal/ModalPopUp";
import EditUser from "../EditFroms/EditUser";
import NotificationSettings from "../NotificationSettings";
import useAuthState from "../../components/useAuthState";
import { useDispatch } from "react-redux";
import { updateUserID } from "../store/UserReducer";
import { clientsList } from "../store/ClientsReducer";
import { useSelector } from "react-redux";
import { useDataGridPremiumProps } from "@mui/x-data-grid-premium/DataGridPremium/useDataGridPremiumProps";

export default function Header({
  handleLogout,
  clientId,
  clientName,
  istoggleMenu,
  clientNameHeader,
  updateNotification,
  isUpdateNotification,
  isClientSelected,
  onisClientSelected,
}) {
  const dispatch = useDispatch();
  const [clientDisplayName, setClientDisplayName] = useState("");
  const [isUpdate, setIsUpdate] = useState(false);
  const navigate = useNavigate();
  // const userNameFL = clientNameHeader.slice(0, 1);
  const baseURL = process.env.REACT_APP_API_BASE_URL;
  const [ismodelOpen, setIsmodelOpen] = useState(false);
  const ClientDisplayName = localStorage.getItem("clientname");
  const userRole = localStorage.getItem("userRoleName");
  // const [clientsData, setClientsData] = useState({});
  const clientData = useSelector((state) => state.clientList.value);
  const token = localStorage.getItem("token");
  const [isEditUser, setEditUser] = useState(false);
  const [selectedClient, setSelectedClient] = useState(clientDisplayName);
  const [userData, setUserData] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  const [formData, setFormData] = useState(null);

  const StyledToolbar = styled(Toolbar)(({ theme }) => ({
    alignItems: "flex-start",
    paddingTop: theme.spacing(1),
    //paddingBottom: theme.spacing(2),
    // Override media queries injected by theme.mixins.toolbar
    // '@media all': {
    //   minHeight: 128,
    // },
  }));

  const logOutHandleFunct = () => {
    handleLogout();
    navigate("/");
  };
  useEffect(() => {
    const token = localStorage.getItem("token");
    axios({
      method: "GET",
      url: `${baseURL}/get_client_display_name/${clientId}`,
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        const res = response.data;
        setClientDisplayName(res.client_display_name);
        setSelectedClient(res.client_display_name);
        localStorage.setItem("client_display_name", res.client_display_name);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      });
  }, []);

  useEffect(() => {
    if (isEditUser) {
      const fetchData = async () => {
        const data = await editUserModalData();
        console.log(data, "data");
        setFormData(data);
      };
      fetchData();
    } else {
      setFormData(null);
    }
  }, [isEditUser]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    axios({
      method: "GET",
      url: `${baseURL}/get/all/clients`,
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        const res = response.data;
        console.log({ res });
        dispatch(clientsList(res.clients));
        // setClientsData(res.clients);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      });
  }, []);

  const sectionStyle = {
    backgroundImage: `url(${BackGroundImg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    boxShadow: "none",
  };

  const currentDate = new Date();
  const current_date = currentDate.getDate();
  // Get the current month (0-indexed, so add 1)
  const currentMonth = currentDate.toLocaleString("en-US", { month: "long" });

  // Get the current year
  const currentYear = currentDate.getFullYear();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNotifiPopUpClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setIsmodelOpen(false);
    }
  };

  const editModalData = () => {
    return (
      <NotificationSettings
        clientId={clientId}
        handleClose={() => handleNotifiPopUpClose()}
        updateNotification={updateNotification}
        isUpdateNotification={isUpdateNotification}
      />
    );
  };
  const handleEditUserCloseModal = (event, reason) => {
    if (reason !== "backdropClick") {
      setEditUser(false);
    }
  };

  const handleGetUserProfile = () => {
    setisLoading(true);
    fetch(`${baseURL}/user/profile`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setUserData(data);
        localStorage.setItem("firstName", data?.UserFirstName);
        localStorage.setItem("lastName", data?.UserLastName);
        setisLoading(false);
        return data;
      });
  };
  useEffect(() => {
    handleGetUserProfile();
  }, []);

  const editUserModalData = () => {
    return (
      <EditUser
        isProfile={true}
        clientID={clientId}
        data={userData}
        handleClose={handleEditUserCloseModal}
        updateStatus={isUpdate}
        updateCurrentState={setIsUpdate}
      />
    );
  };
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    ...theme.applyStyles("dark", {
      backgroundColor: "#1A2027",
    }),
  }));

  const switchClient = (client) => {
    handleClose();
    setSelectedClient(client?.ClientName);
    dispatch(updateUserID(client.ClientID));
  };

  return (
    <AppBar className="MainHeader" style={sectionStyle}>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            md={2}
            className={
              istoggleMenu ? "togggledSidebar logoSection" : "logoSection"
            }
          >
            <FloTraceLogoHeader />
          </Grid>
          <Grid className="descSect" item xs={12} md={6}>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={5}>
                  <h1>
                    Flo-Trace<sup>®</sup>
                  </h1>
                  <p>by LSPS Solutions</p>
                  <div className="welcome">
                    <h4>Welcome {clientNameHeader}</h4>
                  </div>
                </Grid>
                <Grid className="centerSect" item xs={12} md={7}>
                  <h2 style={{ fontSize: "35px" }}>
                    {clientData?.length > 0
                      ? clientData.map((item) => {
                          if (item.ClientID === parseInt(clientId)) {
                            return item.ClientDisplayName;
                          }
                        })
                      : ClientDisplayName}
                  </h2>
                </Grid>
              </Grid>
            </Box>
            {/* <h3></h3> */}
          </Grid>

          <Grid item xs={12} md={3} className="logOutSect">
            <Grid container spacing={3} mt={2} justifyContent="end">
              {userRole === "superadmin" ||
                (userRole === "masteradmin" && (
                  <Grid item xs={12} mt={0} className="clientDropdown">
                    <label>Client</label>
                    <TextField
                      id="outlined-select-currency"
                      select
                      required
                      // style={{ width: 1500 }}
                      //label="Company Name"
                      value={clientId}
                      //onChange={e => setContactFormData({...contactFormData, companyName: e.target.value})}
                    >
                      {clientData?.length > 0 &&
                        clientData.map((item) => {
                          return (
                            <MenuItem
                              value={item.ClientID}
                              onClick={() => switchClient(item)}
                            >
                              {item.ClientName}
                            </MenuItem>
                          );
                        })}
                    </TextField>
                  </Grid>
                ))}
              <Grid mr={2}>
                {userRole !== "user" && userRole !== "admin" && (
                  <ListItemButton
                    p={5}
                    component={Link}
                    style={{
                      color: "black",
                      background: "rgba(209, 220, 229, 0.4)",
                      fontWeight: "40",
                      fontSize: "18px",
                      marginTop: "40px",
                      boxShadow: "0 0 5px rgba(0, 0, 0, 0.2)",
                      fontFamily: "Century Gothic",
                      lineHeight: "20px",
                      padding: "10px",
                      textTransform: "capitalize",
                    }}
                    className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium css-sn1eoh-MuiButtonBase-root-MuiButton-root"
                    to="/users"
                  >
                    Users
                  </ListItemButton>
                )}
              </Grid>
              {(userRole == "admin" ||
                userRole == "superadmin" ||
                userRole == "masteradmin") && (
                <Grid mr={2}>
                  <Button
                    sx={{ color: "white" }}
                    onClick={(e) => {
                      setIsmodelOpen(true);
                      setAnchorEl(null);
                    }}
                    variant="contained"
                  >
                    Notification Settings
                  </Button>
                </Grid>
              )}

              {(userRole === "user" || userRole === "admin") && (
                <Grid xs={2} mr={"10px"}>
                  <ListItemButton
                    p={5}
                    component={Button}
                    onClick={() => setEditUser(true)}
                    // className={slug === "/adduser" ? "activeItem" : ""}
                  >
                    Profile
                  </ListItemButton>
                </Grid>
              )}
              <Grid>
                <Button
                  sx={{ color: "white" }}
                  onClick={logOutHandleFunct}
                  variant="contained"
                >
                  Logout
                </Button>
              </Grid>
            </Grid>
            <p>
              {currentMonth} {current_date}, {currentYear}
            </p>
          </Grid>
        </Grid>
        {ismodelOpen && (
          <ModalPopUp
            open={true}
            handleClose={handleNotifiPopUpClose}
            title="Notification Settings"
            content=""
            formData={editModalData()}
            modalClass="notificationModal"
          />
        )}
        {isEditUser && (
          <ModalPopUp
            isLoading={isLoading}
            open={true}
            disableEscapeKeyDown={true}
            handleClose={handleEditUserCloseModal}
            title="Edit User Details"
            content=""
            formData={editUserModalData()}
            modalClass="notificationModal"
          />
        )}
      </Box>
    </AppBar>
  );
}
