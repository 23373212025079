import React, { useState, useEffect, useRef } from "react";
import { TextField, Button } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import AddDeviceModal from "../SubmitAddDeviceModal";
import MuiAlert from "@mui/material/Alert";
import { Link } from "react-router-dom";
import axios from "axios";
import InputMask from "react-input-mask";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { states } from "../States";
import Container from "@mui/material/Container";
import PhoneMenu from "../Common/PhoneMenu";
import SidebarMenu from "../Common/SidebarMenu";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import { useSelector } from "react-redux";
import { circle } from "leaflet";
import { Tooltip, IconButton, Popover } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import tooltipImage from "../../assets/images/tooltip.jpeg";

import {
  isTokenExpired,
  handleExpiredToken,
  handleTokenExpiryWarning,
} from "../Common/CheckJwtExpires";

const AddDevice = ({ clientName, handleLogout, clientNameHeader }) => {
  const clientId = useSelector((state) => state.userID.value);

  const [open, setOpen] = useState(true);
  const [companiesData, setCompaniesData] = useState([]);
  const [deviceTypesData, setDeviceTypesData] = useState([]);
  const [sizesData, setSizesData] = useState([]);
  const [manufacturersData, setManufacturersData] = useState([]);
  const [companyQueryName, setCompanyQueryName] = useState("");
  const [openSubmitModal, setOpenSubmitModal] = useState(false);
  const handleOpenSubmitModal = () => setOpenSubmitModal(true);
  const handleCloseSubmitModal = () => setOpenSubmitModal(false);
  const [alertContent, setAlertContent] = useState("");
  const [alert, setAlert] = useState(false);
  const [anchorEls, setAnchorEls] = useState({
    CompanyName: false,
  });
  const tooltipRefs = {
    CompanyName: useRef(null),
  };
  const [contactFirstName, setContactFirstName] = useState("");
  const [contactLastName, setContactLastName] = useState("");
  const [contactEmailAddress, setContactEmailAddress] = useState("");
  const [contactJobTitle, setContactJobTitle] = useState("");
  const [contactStreetAddress, setContactStreetAddress] = useState("");
  const [contactCity, setContactCity] = useState("");
  const [contactState, setContactState] = useState("");
  const [contactZip, setContactZip] = useState("");
  const [loginCount, setLoginCount] = useState(0);
  const companyNameRef = useRef(null);

  const [formData, setFormData] = useState({
    deviceType: "",
    manufacturer: "",
    modelNumber: "",
    serialNumber: "",
    size: "",
    condition: "",
    installedDate: "",
    companyName: "",
    location: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    zipCode: "",
    comments: "",
    contactFirstName: "",
    contactLastName: "",
    contactJobTitle: "",
    contactEmailAddress: "",
    latitude: "",
    longitude: "",
    isMandatory: "",
    companyId: "",
  });

  useEffect(() => {
    setAnchorEls((prev) => ({
      ...prev,
      CompanyName: companyNameRef.current,
    }));
  }, []);

  const handleClick = (event, field) => {
    setAnchorEls({ ...anchorEls, [field]: !anchorEls[field] });
  };
  const handleClickOutside = (e) => {
    if (!tooltipRefs["CompanyName"].current.contains(e.target)) {
      setAnchorEls({
        CompanyName: false,
      });
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [newManufacturerTypeModalIsOpen, setNewManufacturerTypeModalIsOpen] =
    useState(false);
  const token = localStorage.getItem("token");
  const handleOpenSubmitModalManufacturer = () =>
    setNewManufacturerTypeModalIsOpen(true);
  const handleCloseSubmitModalManufacturer = () =>
    setNewManufacturerTypeModalIsOpen(false);

  const [newSizeModalIsOpen, setNewSizeModalIsOpen] = useState(false);
  const handleOpenSubmitModalSize = () => setNewSizeModalIsOpen(true);
  const handleCloseSubmitModalSize = () => setNewSizeModalIsOpen(false);

  const [newDeviceTypeModalIsOpen, setNewDeviceTypeModalIsOpen] =
    useState(false);
  const handleOpenSubmitModalDevice = () => setNewDeviceTypeModalIsOpen(true);
  const handleCloseSubmitModalDevice = () => setNewDeviceTypeModalIsOpen(false);

  const [noContactFoundModal, setNoContactFoundModal] = useState(false);
  const handleNoContactFoundModalOpen = () => setNoContactFoundModal(true);
  const handleNoContactFoundModalClose = () => setNoContactFoundModal(false);
  const [toggleMenus, setToggleMenus] = useState(false);

  // const [zipIsValid, setZipIsValid] = useState(false)

  const [companyNameIsValid, setCompanyNameIsValid] = useState(false);

  const [deviceTypeIsValid, setDeviceTypeIsValid] = useState(false);
  const [deviceTMandatory, setdeviceTMandatory] = useState(false);
  const [manufacturerIsValid, setManufacturerIsValid] = useState(false);
  const [modelNumberIsValid, setModelNumberIsValid] = useState(false);
  const [serialNumberIsValid, setSerialNumberIsValid] = useState(false);
  const [sizeIsValid, setSizeIsValid] = useState(false);
  const [conditionIsValid, setConditionIsValid] = useState(false);
  const [installedDateIsValid, setInstalledDateIsValid] = useState(false);
  const [locationIsValid, setLocationIsValid] = useState(false);
  const [addressLineOneIsValid, setAddressLineOneIsValid] = useState(false);
  const [addressLineTwoIsValid, setAddressLineTwoIsValid] = useState(false);
  const [cityIsValid, setCityIsValid] = useState(false);
  const [stateIsValid, setStateIsValid] = useState(false);
  const [postalCodeIsValid, setPostalCodeIsValid] = useState(false);
  const [latitudeIsValid, setLatitudeIsValid] = useState(false);
  const [longitudeIsValid, setLongitudeIsValid] = useState(false);
  const [formIsValid, setFormIsValid] = useState(false);
  const authToken = localStorage.getItem("token");

  useEffect(() => {
    const checkTokenExpiry = () => {
      if (isTokenExpired(token)) {
        handleExpiredToken();
      } else {
        handleTokenExpiryWarning(token);
      }
    };

    checkTokenExpiry();
    const interval = setInterval(checkTokenExpiry, 60000);

    return () => clearInterval(interval);
  }, [token]);

  useEffect(() => {
    if (
      companyNameIsValid &&
      deviceTypeIsValid &&
      manufacturerIsValid &&
      modelNumberIsValid &&
      serialNumberIsValid &&
      sizeIsValid &&
      conditionIsValid &&
      locationIsValid &&
      addressLineOneIsValid &&
      cityIsValid &&
      stateIsValid &&
      postalCodeIsValid
    ) {
      setFormIsValid(true);
    } else {
      setFormIsValid(false);
    }
  }, [
    companyNameIsValid,
    deviceTypeIsValid,
    manufacturerIsValid,
    modelNumberIsValid,
    serialNumberIsValid,
    sizeIsValid,
    conditionIsValid,
    locationIsValid,
    addressLineOneIsValid,
    addressLineTwoIsValid,
    cityIsValid,
    stateIsValid,
    postalCodeIsValid,
  ]);

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  function dynamicSort(property) {
    return function (a, b) {
      return a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    };
  }

  const handleNewManufacturerValue = (value) => {
    setManufacturersData([
      ...manufacturersData,
      { ID: manufacturersData.length + 1, Manufacturer: value },
    ]);
  };

  const handleNewSizeValue = (value) => {
    setSizesData([...sizesData, { ID: sizesData.length + 1, Size: value }]);
  };

  const handleNewDeviceTypeValue = (value) => {
    setDeviceTypesData([
      ...deviceTypesData,
      { ID: deviceTypesData.length + 1, DeviceType: value },
    ]);
  };

  const handleCompanyChange = (key, value) => {
    setFormData({ ...formData, companyName: value });
    setFormData({
      ...formData,
      companyName: value,
      companyId: key,
    });
  };

  //DYNAMICALLY FETCH COMPANY CONTACT INFO WHEN FORMDATA CHANGES
  const baseURL = process.env.REACT_APP_API_BASE_URL;
  useEffect(() => {
    fetch(
      `${baseURL}/${clientId}/${formData.companyName.replace(
        / /g,
        "%20"
      )}/getcompanycontactinfo`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.data == "No contact found") {
          handleNoContactFoundModalOpen();
        } else {
          setContactFirstName(data.data.FirstName);
          setContactLastName(data.data.LastName);
          setContactEmailAddress(data.data.EmailAddress);
          setContactJobTitle(data.data.JobTitle);
          setContactStreetAddress(data.data.StreetAddress);
          setContactCity(data.data.City);
          setContactState(data.data.State);
          setContactZip(data.data.PostalCode);
        }
      });
  }, [formData.companyName, clientId]);

  // FETCH COMPANY NAMES BY CLIENT ID
  useEffect(() => {
    fetch(`${baseURL}/${clientId}/getcompanynames`)
      .then((response) => response.json())
      .then((data) => {
        setCompaniesData(data.data.sort(dynamicSort("CompanyName")));
      });
  }, [clientId]);

  // FETCH DEVICE TYPES BY CLIENT ID
  useEffect(() => {
    fetch(`${baseURL}/${clientId}/getdevicetypes`)
      .then((response) => response.json())
      .then((data) => {
        setDeviceTypesData(data.data.sort(dynamicSort("DeviceType")));
      });
  }, [clientId]);

  // FETCH SIZES BY CLIENT ID
  useEffect(() => {
    fetch(`${baseURL}/${clientId}/getdevicesizes`)
      .then((response) => response.json())
      .then((data) => {
        setSizesData(data.data.sort(dynamicSort("Size")));
      });
  }, [clientId]);

  // FETCH MANUFACTURERS BY CLIENT ID
  useEffect(() => {
    fetch(`${baseURL}/${clientId}/getmanufacturers`)
      .then((response) => response.json())
      .then((data) => {
        setManufacturersData(data.data.sort(dynamicSort("Manufacturer")));
      });
  }, [clientId]);

  // Add New Device Type Modal
  useEffect(() => {
    if (formData.manufacturer === "*Add New") {
      setNewManufacturerTypeModalIsOpen(true);
    }
  }, [formData.manufacturer, clientId]);

  useEffect(() => {
    if (formData.size === "*Add New") {
      setNewSizeModalIsOpen(true);
    }
  }, [formData.size]);

  useEffect(() => {
    if (formData.deviceType === "*Add New") {
      setNewDeviceTypeModalIsOpen(true);
    }
  }, [formData.deviceType, clientId]);

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post(`${baseURL}/${clientId}/postdevice`, JSON.stringify(formData), {
        headers: {
          "Content-type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setAlertContent("Device created successfully!");
          setAlert(true);
          handleOpenSubmitModal();
        } else {
          setAlertContent("Error creating device");
          setAlert(true);
          handleOpenSubmitModal();
        }
      })
      .catch((error) => {
        //alert(error)
      })
      .then(console.log(JSON.stringify(formData)));
  };

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const deviceTypes = [
    {
      value: 1,
      label: "RP",
    },
    {
      value: 2,
      label: "DCV",
    },
    {
      value: 3,
      label: "PVB",
    },
    {
      value: 4,
      label: "DCD",
    },
    {
      value: 5,
      label: "Add New",
    },
  ];

  const conditionTypes = [
    {
      value: 1,
      label: "New",
    },
    {
      value: 2,
      label: "Moved",
    },
    {
      value: 3,
      label: "Decomissioned",
    },
    {
      value: 4,
      label: "Defective",
    },
    {
      value: 5,
      label: "Normal",
    },
  ];

  return (
    <>
      <Box>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            {/* Header Section */}
            <Header
              clientId={clientId}
              clientName={clientName}
              handleLogout={handleLogout}
              istoggleMenu={toggleMenus}
              clientNameHeader={clientNameHeader}
            />
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Grid container spacing={0}>
          <Grid item xs={12} md={2}>
            {/* Footer Section */}
            <PhoneMenu slug="/technicians" />
            <SidebarMenu toggleMenu={setToggleMenus} />
          </Grid>
          <Grid
            item
            xs={12}
            md={10}
            className={
              toggleMenus === true ? "tooggleActive mainContent" : "mainContent"
            }
          >
            <Container maxWidth="xlg" sx={{ mt: 8, mb: 4 }}>
              <Grid container spacing={10} className="topDueSect">
                <Grid item xs={12} sm={12} md={6} className="beforetable">
                  <Typography
                    className="SelDevice"
                    align="left"
                    variant="h4"
                    gutterBottom
                  >
                    ADD A DEVICE
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  sx={{ textAlign: "right" }}
                  className="beforetable"
                ></Grid>
                <Grid item xs={12} className="tabSect">
                  {/* <Typography>* Denotes a required field</Typography> */}
                  <Box
                    align="left"
                    sx={{
                      "& .MuiTextField-root": { m: 1, width: "30ch" },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <h3>Company and Contact Details</h3>
                    <p>
                      Select company below or{" "}
                      <Link to="/addcompany">add a new one</Link>
                    </p>
                    <Grid>
                      <TextField
                        error={formData.companyName == ""}
                        id="outlined-select-currency"
                        select
                        required
                        style={{ width: 500 }}
                        // label="Company Name"
                        helperText={
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            Select company name in order to view contact info
                            <img
                              src={tooltipImage}
                              width={"15px"}
                              style={{
                                cursor: "pointer",
                                marginLeft: 5,
                                marginTop: "1.5px",
                              }}
                              onMouseOver={(e) => handleClick(e, "CompanyName")}
                              onMouseOut={(e) => handleClick(e, "CompanyName")}
                              ref={tooltipRefs["CompanyName"]}
                            />
                            <Tooltip
                              id="simple-popover test"
                              open={anchorEls["CompanyName"]}
                              arrow
                              placement="right"
                              title={
                                <div>
                                  <Typography
                                    variant="body2"
                                    style={{
                                      textAlign: "center",
                                      fontSize: "15px",
                                      fontWeight: "600",
                                      padding: "15px",
                                      lineHeight: "1rem",
                                    }}
                                  >
                                    Add company and contact before they are
                                    visible here
                                  </Typography>
                                </div>
                              }
                              PopperProps={{
                                modifiers: [
                                  {
                                    name: "maxWidth",
                                    enabled: true,
                                    phase: "beforeWrite",
                                    fn({ state }) {
                                      state.styles.popper.maxWidth = "205px";
                                    },
                                  },
                                ],
                              }}
                            ></Tooltip>
                          </div>
                        }
                        value={formData.companyName}
                        onChange={(e) => {
                          if (e.target.value == "") {
                            setCompanyNameIsValid(false);
                          } else {
                            setCompanyNameIsValid(true);
                          }
                        }}
                      >
                        {companiesData.map((option) => (
                          <MenuItem
                            key={option.CompanyID}
                            value={option.CompanyName}
                            onClick={() =>
                              handleCompanyChange(
                                option.CompanyID,
                                option.CompanyName
                              )
                            }
                          >
                            {option.CompanyName}
                          </MenuItem>
                        ))}
                      </TextField>

                      <Typography>
                        Company contact info: {formData.companyName}
                      </Typography>
                      {/* <p><Link href="/addcontact">Add a new contact</Link></p> */}

                      <Grid>
                        <TextField
                          id="outlined-select-currency"
                          disabled
                          helperText="Contact name"
                          style={{ width: 375 }}
                          value={contactFirstName + " " + contactLastName}
                        ></TextField>

                        <TextField
                          id="outlined-select-currency"
                          disabled
                          helperText="Contact Job Title"
                          style={{ width: 375 }}
                          value={contactJobTitle}
                        ></TextField>
                        <TextField
                          id="outlined-select-currency"
                          disabled
                          style={{ width: 375 }}
                          helperText="Contact Email"
                          value={contactEmailAddress}
                        ></TextField>
                      </Grid>
                      <Grid>
                        <TextField
                          id="outlined-select-currency"
                          disabled
                          helperText="Contact Job Title"
                          style={{ width: 375 }}
                          value={contactStreetAddress}
                        ></TextField>
                        <TextField
                          id="outlined-select-currency"
                          disabled
                          style={{ width: 375 }}
                          helperText="Contact City"
                          value={contactCity}
                        ></TextField>
                        <TextField
                          id="outlined-select-currency"
                          disabled
                          helperText="Contact State"
                          value={contactState}
                          style={{ width: 375 }}
                        ></TextField>
                        <TextField
                          id="outlined-select-currency"
                          disabled
                          helperText="Contact Zipcode"
                          value={contactZip}
                          style={{ width: 375 }}
                        ></TextField>
                      </Grid>
                    </Grid>

                    <h3>Device Details</h3>

                    <Box component="form" onSubmit={handleSubmit}>
                      <Grid container sx={{ mt: 4 }}>
                        <TextField
                          style={{ width: 375 }}
                          error={formData.deviceType == ""}
                          id="outlined-select-currency"
                          select
                          // label="Device Type"
                          helperText="Select device type"
                          required
                          value={formData.deviceType}
                          //onChange={e => setFormData({...formData, deviceType: e.target.value})}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              deviceType: e.target.value,
                            });
                            if (e.target.value == "") {
                              setDeviceTypeIsValid(false);
                            } else {
                              setDeviceTypeIsValid(true);
                            }
                          }}
                        >
                          {deviceTypesData.map((option) => (
                            <MenuItem key={option.ID} value={option.DeviceType}>
                              {option.DeviceType}
                            </MenuItem>
                          ))}
                        </TextField>
                        <TextField
                          style={{ width: 375 }}
                          error={formData.manufacturer == ""}
                          id="outlined-select-currency"
                          select
                          // label="Manufacturer"
                          required
                          helperText="Select manufacturer"
                          //value={newEnteredManValue ? newEnteredManValue !== "" : formData.manufacturer}
                          value={formData.manufacturer}
                          //onChange={e => setFormData({...formData, manufacturer:e.target.value})}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              manufacturer: e.target.value,
                            });
                            if (e.target.value == "") {
                              setManufacturerIsValid(false);
                            } else {
                              setManufacturerIsValid(true);
                            }
                          }}
                        >
                          {manufacturersData.map((option) => (
                            <MenuItem
                              key={option.ID}
                              value={option.Manufacturer}
                            >
                              {option.Manufacturer}
                            </MenuItem>
                          ))}
                        </TextField>
                        <TextField
                          style={{ width: 375 }}
                          error={formData.modelNumber == ""}
                          id="outlined-select-currency"
                          // label="Model Number"
                          required
                          helperText="Model number"
                          value={formData.modelNumber}
                          //onChange={e => setFormData({...formData, modelNumber:e.target.value})}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              modelNumber: e.target.value,
                            });
                            if (e.target.value == "") {
                              setModelNumberIsValid(false);
                            } else {
                              setModelNumberIsValid(true);
                            }
                          }}
                        >
                          {deviceTypes.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>

                        <TextField
                          style={{ width: 375 }}
                          error={formData.serialNumber == ""}
                          id="outlined-select-currency"
                          // label="Serial Number"
                          required
                          helperText="Serial number"
                          value={formData.serialNumber}
                          //onChange={e => setFormData({...formData, serialNumber:e.target.value})}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              serialNumber: e.target.value,
                            });
                            if (e.target.value == "") {
                              setSerialNumberIsValid(false);
                            } else {
                              setSerialNumberIsValid(true);
                            }
                          }}
                        >
                          {deviceTypes.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                        <TextField
                          style={{ width: 375 }}
                          error={formData.size == ""}
                          id="outlined-select-currency"
                          select
                          // label="Size"
                          required
                          helperText="Select device size"
                          value={formData.size}
                          //onChange={e => setFormData({...formData, size: e.target.value})}
                          onChange={(e) => {
                            setFormData({ ...formData, size: e.target.value });
                            if (e.target.value == "") {
                              setSizeIsValid(false);
                            } else {
                              setSizeIsValid(true);
                            }
                          }}
                        >
                          {sizesData.map((option) => (
                            <MenuItem key={option.SizeID} value={option.Size}>
                              {option.Size}
                            </MenuItem>
                          ))}
                        </TextField>
                        <TextField
                          style={{ width: 375 }}
                          error={formData.condition == ""}
                          id="outlined-select-currency"
                          select
                          required
                          // label="Condition"
                          helperText="Select condition"
                          value={formData.condition}
                          //onChange={e => setFormData({...formData, condition: e.target.value})}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              condition: e.target.value,
                            });
                            if (e.target.value == "") {
                              setConditionIsValid(false);
                            } else {
                              setConditionIsValid(true);
                            }
                          }}
                        >
                          {conditionTypes.map((option) => (
                            <MenuItem key={option.value} value={option.label}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid container>
                        <TextField
                          style={{ width: 375, marginTop: "15px" }}
                          error={formData.isMandatory == ""}
                          id="outlined-select-currency"
                          helperText="Device Mandatory"
                          required
                          select
                          value={formData.isMandatory}
                          //onChange={e => setFormData({...formData, deviceType: e.target.value})}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              isMandatory: e.target.value,
                            });
                            if (e.target.value == "") {
                              setdeviceTMandatory(false);
                            } else {
                              setdeviceTMandatory(true);
                            }
                          }}
                        >
                          <MenuItem selected key="Yes" value="Yes">
                            Yes
                          </MenuItem>
                          <MenuItem key="No" value="No">
                            No
                          </MenuItem>
                        </TextField>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={["DatePicker"]}>
                            <DatePicker
                              label="Installed Date"
                              onChange={(date) => {
                                setFormData({
                                  ...formData,
                                  installedDate: date.format("MM/DD/YYYY"),
                                });
                              }}
                              slotProps={{
                                textField: {
                                  required: true,
                                  error: formData.installedDate == "",
                                  style: { width: "375px" }, // Using inline style for width
                                },
                              }}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      </Grid>

                      <h3>Device Location</h3>
                      <Grid>
                        <TextField
                          style={{ width: 375 }}
                          error={formData.location == ""}
                          id="outlined-select-currency"
                          required
                          // label="Location Notes"
                          helperText="Describe location (eg. 'Front lawn')"
                          value={formData.location}
                          //onChange={e => setFormData({...formData, location:e.target.value})}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              location: e.target.value,
                            });
                            if (e.target.value == "") {
                              setLocationIsValid(false);
                            } else {
                              setLocationIsValid(true);
                            }
                          }}
                        >
                          {deviceTypes.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                        <TextField
                          style={{ width: 375 }}
                          error={formData.addressLine1 == ""}
                          id="outlined-select-currency"
                          // label="Address Line 1"
                          required
                          helperText="Address (eg. 123 Main St)"
                          value={formData.addressLine1}
                          //onChange={e => setFormData({...formData, addressLine1:e.target.value})}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              addressLine1: e.target.value,
                            });
                            if (e.target.value == "") {
                              setAddressLineOneIsValid(false);
                            } else {
                              setAddressLineOneIsValid(true);
                            }
                          }}
                        >
                          {deviceTypes.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                        <TextField
                          style={{ width: 375 }}
                          id="outlined-select-currency"
                          // label="Address Line 2"
                          helperText="Address line 2 (Apt 5 or Unit 2)"
                          value={formData.addressLine2}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              addressLine2: e.target.value,
                            })
                          }
                          // onChange={e => {
                          //   setFormData({...formData, addressLine2:e.target.value})
                          //   if (e.target.value == "") {
                          //       setAddressLineTwoIsValid(false)
                          //   } else {
                          //     setAddressLineTwoIsValid(true)
                          // }}}
                        >
                          {deviceTypes.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>

                        <TextField
                          style={{ width: 375 }}
                          error={formData.city == ""}
                          id="outlined-select-currency"
                          // label="City"
                          required
                          helperText="City (eg. Houston)"
                          value={formData.city}
                          //onChange={e => setFormData({...formData, city:e.target.value})}
                          onChange={(e) => {
                            setFormData({ ...formData, city: e.target.value });
                            if (e.target.value == "") {
                              setCityIsValid(false);
                            } else {
                              setCityIsValid(true);
                            }
                          }}
                        >
                          {deviceTypes.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                        {/* <TextField
              id="outlined-select-currency"
              label="State"
              required
              helperText="ex. TX"
              value={formData.state}
              onChange={e => setFormData({...formData, state:e.target.value})}
            >
              {deviceTypes.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField> */}

                        <TextField
                          style={{ width: 375 }}
                          error={formData.state == ""}
                          id="outlined-select-currency"
                          select
                          required
                          //style = {{width: 260}}
                          // label="State"
                          helperText="State (eg. TX)"
                          value={formData.state}
                          //onChange={e => setFormData({...formData, state: e.target.value})}
                          onChange={(e) => {
                            setFormData({ ...formData, state: e.target.value });
                            if (e.target.value == "") {
                              setStateIsValid(false);
                            } else {
                              setStateIsValid(true);
                            }
                          }}
                        >
                          {states.map((option) => (
                            <MenuItem key={option.id} value={option.state}>
                              {option.state}
                            </MenuItem>
                          ))}
                        </TextField>

                        <InputMask
                          style={{ width: 375 }}
                          mask="99999-9999"
                          value={formData.zipCode}
                          disabled={false}
                          maskChar=" "
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              zipCode: e.target.value,
                            });

                            let digitLength = e.target.value
                              .toString()
                              .replace(/[^0-9]/g, "").length;
                            if (digitLength < 5) {
                              setPostalCodeIsValid(false);
                            } else if (digitLength > 5 && digitLength < 9) {
                              setPostalCodeIsValid(false);
                            } else {
                              setPostalCodeIsValid(true);
                            }
                          }}
                        >
                          {() => (
                            <TextField
                              style={{ width: 375 }}
                              //error={formData.zipCode.toString().replace(/[^0-9]/g,"").length <5}
                              //helperText={formData.zipCode.toString().replace(/[^0-9]/g,"").length < 5 ? 'Postal code must be either 5 or 9 digits' : ' '}

                              error={
                                formData.zipCode
                                  .toString()
                                  .replace(/[^0-9]/g, "").length < 5 ||
                                (formData.zipCode
                                  .toString()
                                  .replace(/[^0-9]/g, "").length > 5 &&
                                  formData.zipCode
                                    .toString()
                                    .replace(/[^0-9]/g, "").length < 9)
                              }
                              helperText={
                                formData.zipCode
                                  .toString()
                                  .replace(/[^0-9]/g, "").length < 5 ||
                                (formData.zipCode
                                  .toString()
                                  .replace(/[^0-9]/g, "").length > 5 &&
                                  formData.zipCode
                                    .toString()
                                    .replace(/[^0-9]/g, "").length < 9)
                                  ? "Zipcode (must be either 5 or 9 digits)"
                                  : " "
                              }
                              id="outlined-select-currency"
                              //style = {{width: 275}}
                              required
                              // label="Zipcode (must be either 5 or 9 digits)"
                              //helperText="Type 5-digit postal code e.g. 12345"
                              value={formData.zipCode}
                            ></TextField>
                          )}
                        </InputMask>
                      </Grid>
                      <Grid>
                        <h3></h3>

                        <InputMask
                          mask="99.999999"
                          value={formData.latitude}
                          disabled={false}
                          maskChar=" "
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              latitude: e.target.value,
                            });

                            let digitLength = e.target.value
                              .toString()
                              .replace(/[^0-9]/g, "").length;
                            if (digitLength < 5) {
                              setLatitudeIsValid(false);
                            } else if (digitLength > 5 && digitLength < 9) {
                              setLatitudeIsValid(false);
                            } else {
                              setLatitudeIsValid(true);
                            }
                          }}
                        >
                          {() => (
                            <TextField
                              style={{ width: 375 }}
                              //error={formData.zipCode.toString().replace(/[^0-9]/g,"").length <5}
                              //helperText={formData.zipCode.toString().replace(/[^0-9]/g,"").length < 5 ? 'Postal code must be either 5 or 9 digits' : ' '}

                              //error={formData.zipCode.toString().replace(/[^0-9]/g,"").length < 5 || formData.zipCode.toString().replace(/[^0-9]/g,"").length > 5 && formData.zipCode.toString().replace(/[^0-9]/g,"").length < 9}
                              //helperText={formData.zipCode.toString().replace(/[^0-9]/g,"").length < 5 || formData.zipCode.toString().replace(/[^0-9]/g,"").length > 5 && formData.zipCode.toString().replace(/[^0-9]/g,"").length < 9 ? 'Postal code must be either 5 or 9 digits' : ' '}

                              id="outlined-select-currency"
                              //style = {{width: 275}}
                              //required
                              // label="Latitude"
                              helperText="Latitude (at least 2 decimal places)"
                              value={formData.latitude}
                            ></TextField>
                          )}
                        </InputMask>

                        <InputMask
                          mask="-99.999999"
                          value={formData.longitude}
                          disabled={false}
                          maskChar=" "
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              longitude: e.target.value,
                            });

                            let digitLength = e.target.value
                              .toString()
                              .replace(/[^0-9]/g, "").length;
                            if (digitLength < 5) {
                              setLongitudeIsValid(false);
                            } else if (digitLength > 5 && digitLength < 9) {
                              setLongitudeIsValid(false);
                            } else {
                              setLongitudeIsValid(true);
                            }
                          }}
                        >
                          {() => (
                            <TextField
                              style={{ width: 375 }}
                              //error={formData.zipCode.toString().replace(/[^0-9]/g,"").length <5}
                              //helperText={formData.zipCode.toString().replace(/[^0-9]/g,"").length < 5 ? 'Postal code must be either 5 or 9 digits' : ' '}

                              //error={formData.zipCode.toString().replace(/[^0-9]/g,"").length < 5 || formData.zipCode.toString().replace(/[^0-9]/g,"").length > 5 && formData.zipCode.toString().replace(/[^0-9]/g,"").length < 9}
                              //helperText={formData.zipCode.toString().replace(/[^0-9]/g,"").length < 5 || formData.zipCode.toString().replace(/[^0-9]/g,"").length > 5 && formData.zipCode.toString().replace(/[^0-9]/g,"").length < 9 ? 'Postal code must be either 5 or 9 digits' : ' '}

                              id="outlined-select-currency"
                              //style = {{width: 275}}
                              //required
                              // label="Longitude"
                              helperText="Longitude (at least 2 decimal places)"
                              value={formData.longitude}
                            ></TextField>
                          )}
                        </InputMask>

                        {/* <InputMask
              mask="99999"
              value={formData.zipCode}
              disabled={false}
              maskChar=" "
              onChange={e => setFormData({...formData, zipCode:e.target.value})}
              >
              {() => <TextField
                id="outlined-select-currency"
                style = {{width: 275}}
                required
                label="Postal Code"
                helperText="Type 5-digit postal code e.g. 12345"
                value={formData.zipCode}
            >
            </TextField>}
          </InputMask> */}

                        {/* <TextField
              id="outlined-select-currency"
              label="Zip Code"
              required
              helperText="ex. 54321"
              value={formData.zipCode}
              onChange={e => setFormData({...formData, zipCode:e.target.value})}
            >
              {deviceTypes.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField> */}
                      </Grid>
                      <h3>Additional Comments</h3>
                      <TextField
                        id="outlined-select-currency"
                        label="Comments"
                        style={{ width: 900 }}
                        value={formData.comments}
                        onChange={(e) =>
                          setFormData({ ...formData, comments: e.target.value })
                        }
                      >
                        {deviceTypes.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                      <Box>
                        {/* <SubmitAddDeviceModal /> */}
                        {/* sx = {{ mr: 6, mt: 4, mb:4}} */}
                        {/* <AddTestModal submitTestFileAndData={handleSubmit} openStatus={openSubmitModal} handleModalClose={handleCloseSubmitModal} alertContentText={alertContent}/> */}

                        <AddDeviceModal
                          submitTestFileAndData={handleSubmit}
                          openStatus={openSubmitModal}
                          handleModalClose={handleCloseSubmitModal}
                          alertContentText={alertContent}
                        />
                        <Button
                          disabled={formIsValid ? false : true}
                          type="submit"
                          size="large"
                          sx={{
                            ml: 1,
                            mr: 6,
                            mt: 4,
                            borderRadius: 8,
                            width: 225,
                          }}
                          variant="contained"
                          color="success"
                        >
                          Submit
                        </Button>
                        <Button
                          size="large"
                          href="/"
                          variant="contained"
                          sx={{
                            ml: 1,
                            mr: 6,
                            mt: 4,
                            borderRadius: 8,
                            width: 225,
                          }}
                          color="error"
                        >
                          Cancel
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </>
  );
};

export default AddDevice;
