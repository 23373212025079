import { useEffect, useState } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import iconMarker from "leaflet/dist/images/marker-icon.png";
import iconRetina from "leaflet/dist/images/marker-icon-2x.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import L from "leaflet";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Header from "./Common/Header";
import Footer from "./Common/Footer";
import PhoneMenu from "./Common/PhoneMenu";
import SidebarMenu from "./Common/SidebarMenu";
import { useSelector } from "react-redux";
import {
  isTokenExpired,
  handleExpiredToken,
  handleTokenExpiryWarning,
} from "./Common/CheckJwtExpires";

const icon = L.icon({
  iconRetinaUrl: iconRetina,
  iconUrl: iconMarker,
  shadowUrl: iconShadow,
});

export default function Map({
  handleLogout,
  onSelectRows,
  currentFilterModel,
  // clientId,
  clientName,
  clientNameHeader,
}) {
  const clientId = useSelector((state) => state.userID.value);

  const [mapData, setMapData] = useState([]);
  const [open, setOpen] = useState(true);
  const [toggleMenus, setToggleMenus] = useState(false);
  const token = localStorage.getItem("token");

  useEffect(() => {
    const checkTokenExpiry = () => {
      if (isTokenExpired(token)) {
        handleExpiredToken();
      } else {
        handleTokenExpiryWarning(token);
      }
    };

    checkTokenExpiry();
    const interval = setInterval(checkTokenExpiry, 60000);

    return () => clearInterval(interval);
  }, [token]);

  useEffect(() => {
    fetch(
      `https://flo-trace-api-prod-ugyxg.ondigitalocean.app/${clientId}/devicemapdata`
    )
      .then((response) => response.json())
      .then((result) => setMapData(result.data));
  }, [clientId]);

  useEffect(() => {
    console.log("mapdata", mapData);
  }, [mapData]);

  const [formIsValid, setFormIsValid] = useState(false);

  useEffect(() => {
    console.log("mapdata", mapData);
  }, [mapData]);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <>
      <Box>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            {/* Header Section */}
            <Header
              clientId={clientId}
              clientName={clientName}
              handleLogout={handleLogout}
              istoggleMenu={toggleMenus}
              clientNameHeader={clientNameHeader}
            />
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Grid container spacing={0}>
          <Grid item xs={12} md={2}>
            {/* Footer Section */}
            <PhoneMenu slug="/technicians" />
            <SidebarMenu toggleMenu={setToggleMenus} />
          </Grid>
          <Grid
            item
            xs={12}
            md={10}
            className={
              toggleMenus === true ? "tooggleActive mainContent" : "mainContent"
            }
          >
            <Container maxWidth="xlg" sx={{ mt: 8, mb: 8 }}>
              <Grid container spacing={10} className="topDueSect">
                <Grid item xs={12} sm={12} md={6} className="beforetable">
                  <Typography
                    className="SelDevice"
                    align="left"
                    variant="h4"
                    gutterBottom
                  >
                    MAP
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  sx={{ textAlign: "right" }}
                  className="beforetable"
                ></Grid>

                <Grid item xs={12} className="tabSect">
                  <div style={{ height: "800px" }}>
                    {/* center={[29.0939, -97.2892]} */}
                    <MapContainer
                      style={{ height: "100%", minHeight: "100%" }}
                      center={[33.0939, -97.2892]}
                      zoom={6}
                      scrollWheelZoom={true}
                    >
                      <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      />

                      {mapData.map((val) => (
                        <Marker
                          icon={icon}
                          position={[val.Latitude, val.Longitude]}
                        >
                          <Popup>
                            Location: {val.Location} <br />
                            Company: {val.CompanyName} <br />
                            Street Address: {val.StreetAddress}
                            <br />
                            City: {val.City}
                            <br />
                            State: {val.State}
                            <br />
                            Serial Number: {val.SerialNumber}
                            <br />
                            Condition: {val.Condition}
                            <br />
                            Device Type: {val.DeviceType}
                            <br />
                            Installed Date: {val.InstalledDate}
                            <br />
                            Manufacturer: {val.Manufacturer}
                            <br />
                            Size: {val.Size}
                            <br />
                            Latitude: {val.Latitude}
                            <br />
                            Longitude: {val.Longitude}
                            <br />
                          </Popup>
                        </Marker>
                      ))}
                    </MapContainer>
                  </div>
                </Grid>
              </Grid>
            </Container>
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </>
  );
}
